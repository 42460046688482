section {
  padding-bottom: 9vw;

  h1 {
    .section-link {
      position: absolute;
      right: 10px;
      .icon-link();
      color: @text-muted;
      opacity: 0;
      transition: opacity 0.2s ease-in-out;

      &:hover {
        text-decoration: none;
        opacity: 1;
      }
    }

    &:hover .section-link {
      opacity: 0.5;
      transition-delay: 1s;

      &:hover {
        opacity: 1;
        transition-delay: 0s;
      }
    }
  }

  nav + & {
    padding-top: 4vw;
  }

  + section {
    margin-top: -6vw;
  }

  &.hero + section {
    margin-top: 0;
  }

  &.hero + section.section-squish {
    margin-top: -2vw;
    @media (min-width: @screen-sm-min) {
      margin-top: -3vw;
    }
    @media (min-width: @screen-lg-min) {
      margin-top: -5vw;
    }
  }

  &.section-bg1,
  &.section-bg2,
  &.section-bg3,
  &.section-simple {
    padding-top: @screen-sm-min * 0.1;
    background: transparent data-uri('../../landing/images/section-bg/1.svg')
      no-repeat center top;
    background-size: @screen-sm-min auto;

    @media (min-width: @screen-sm-min) {
      padding-top: 10vw;
      background-size: 100% auto;
    }

    @media (min-width: @screen-xl-min) {
      padding-top: 140px;
    }
  }

  &.section-bg2 {
    background-image: data-uri('../../landing/images/section-bg/2.svg');
  }

  &.section-bg3 {
    background-image: data-uri('../../landing/images/section-bg/3.svg');
  }

  &.section-simple {
    padding-top: @screen-sm-min * 0.06;
    @media (min-width: @screen-sm-min) {
      padding-top: 4vw;
      @media (min-height: @screen-v-lg-min) {
        padding-top: 6vw;
      }
    }
    @media (min-width: @screen-xl-min) {
      padding-top: 60px;
      @media (min-height: @screen-v-lg-min) {
        padding-top: 80px;
      }
    }
    background-image: data-uri('../../landing/images/section-bg/simple.svg');
  }

  &.section-basic {
    > div {
      max-width: @content-max-width;
      margin: 0 auto;
      padding: 0 10px;
      text-align: center;
      overflow: hidden;
    }

    h1,
    h2,
    h3 {
      color: @brand-complement-dark;
      line-height: 1.5;
      max-width: @text-max-width;
      margin: 0 auto 20px;
    }

    p {
      color: @brand-complement-light;
      line-height: 1.6;
      max-width: @text-max-width;
      margin: 0 auto;

      + p {
        margin-top: 20px;
      }
    }

    h2 {
      font-size: 20px;
    }

    h3 {
      font-size: 18px;
    }

    @media (min-width: @screen-sm-min) {
      > div {
        padding: 0 20px;
      }
    }

    @media (min-width: @screen-md-min) {
      h1,
      h2,
      h3 {
        margin-bottom: 24px;
      }

      h3 {
        font-size: 24px;
      }

      p + p {
        margin-top: 24px;
      }
    }
    @media (min-width: @screen-xl-min) {
      > div {
        padding: 0 40px;
      }

      h1,
      h2,
      h3 {
        margin-bottom: 34px;
      }

      p + p {
        margin-top: 34px;
      }
    }
  }

  &.section-two-columns > div {
    .wrapping-columns();
    justify-content: space-between;

    > *:not(:first-child) {
      margin-top: 80px;
      @media (min-width: @screen-md-min) {
        margin-top: 0;
      }
    }

    > * {
      flex: 1 1 50%;
      max-width: 540px;
      margin: 0 auto;
      .mobile-column-flexbox(@break: @screen-md-min);
    }
  }
}
