ul.logos-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: 20px 0 0 30px;
  padding: 0;

  li {
    display: inline-block;
    list-style: none;
    margin: 0 30px 30px 0;
    flex: 0 0 auto;

    > a {
      display: inline-block;
    }
  }

  img {
    display: inline-block;
    height: 3em;
  }

  // visually center items

  /// trusted-by

  .apple img {
    height: 4em;
    transform: translateY(-8%);
  }
  .klarna img {
    height: 2.2em;
  }

  .thinkful img {
    transform: translateY(26%);
  }

  .facebook img {
    height: 2.2em;
  }

  .zoom img {
    height: 2em;
    transform: translateY(10%);
  }

  .dominos img {
    transform: translateY(-10%);
  }

  //.ge img {
  //	height: 4em;
  //}

  //.accenture img {
  //	transform: translateY(-12%);
  //}

  //.andela img {
  //	transform: translateY(8%);
  //}

  /// integrations

  .bamboohr img {
    height: 2.4em;
  }

  .zapier img {
    height: 4em;
  }

  .workable img {
    height: 2.4em;
  }
  .greenhouse img {
    transform: translateY(16%);
  }

  @media (max-width: @screen-xs) {
    margin-left: 0;
    li {
      flex: 1 1 100%;
      margin-right: 0;
    }
  }

  @media (min-width: @screen-md-min) {
    justify-content: space-between;
    margin: 30px 0 0 0;

    li {
      margin: 0;
      order: 0;

      + li {
        margin-left: 20px;
      }
    }

    li.apple {
      order: 1;
    }
    li:nth-child(n + 4) {
      order: 3;
    }
  }

  &.g2-badges {
    img {
      height: 100px;
      &.g2-badge-market {
        transform: scale(1.15);
      }
    }
    // 5 badges media: (max-width: 600px)
    // 6 badges media: (max-width: 750px)
    // leave the max-width property at 460px for both
    @media (max-width: 750px) {
      margin-left: auto;
      margin-right: auto;
      max-width: 460px;
      li {
        flex: 0 0 auto;
        margin: 10px 20px;
      }
    }

    @media (min-width: @screen-md-min) {
      margin-top: 60px;
      justify-content: center;

      li {
        order: 0;
        + li {
          margin-left: 80px;
        }
      }

      img {
        height: 122px;
      }
    }
  }
}
