.wrapping-columns() {
  display: flex;
  flex-wrap: wrap;
}

.mobile-column-flexbox(@break: @screen-sm-min) {
  @media (max-width: (@break - 1)) {
    flex: 0 0 100%;
  }

  @media (min-width: @break) {
    &:first-child:not(:last-child) {
      padding-right: 0.6rem;
    }
    &:last-child:not(:first-child) {
      padding-left: 0.6rem;
    }
  }
}

.layout-gutters {
  > * {
    padding-right: 2rem;

    &:last-child {
      padding-right: 0;
    }
  }
}

.layout-two-columns {
  .wrapping-columns();

  > * {
    flex: 0 0 50%;
    .mobile-column-flexbox();
  }

  &.layout-elastic {
    > * {
      flex: 1;
      min-width: 50%;
      max-width: 100%;
    }
  }
}

.layout-three-columns {
  .wrapping-columns();

  > * {
    flex: 0 0 33.3%;
    .mobile-column-flexbox();
  }

  &.layout-elastic {
    > * {
      flex: 1;
      min-width: 33.3%;
      max-width: 100%;
    }
  }
}

.layout-four-columns {
  .wrapping-columns();

  > * {
    flex: 0 0 25%;
    .mobile-column-flexbox();
  }
}

.layout-five-columns {
  .wrapping-columns();

  > * {
    flex: 0 0 20%;
    .mobile-column-flexbox();
  }
}
