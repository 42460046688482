#mobile-menu-toggle-checkbox {
  display: none;
}

body.simple-content {
  padding-top: 60px;
  background-image: data-uri(
    '../../landing/images/header-bg/simple-content.svg'
  );
  background-size: 100% 60px;
  background-position: center top;
  background-repeat: no-repeat;

  @media (min-width: @screen-sm-min) {
    padding-top: 110px;
    background-size: 100% 110px;
  }
}

nav.nav-main {
  @nav-small-menu-width: 220px;
  @nav-toggle-size: 40px;
  @nav-show-inline-width: 1000px;
  @nav-show-menu-width: (@nav-show-inline-width - 1);

  position: absolute;
  top: 16px;
  left: 0;
  right: 0;
  transition:
    background-color 0.15s ease-in-out,
    height 0.15s ease-in-out,
    padding-top 0.15s ease-in-out;

  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    height: 16px;
    opacity: 0;
    pointer-events: none;
    background: transparent
      linear-gradient(
        to bottom,
        fade(@brand-complement-dark, 9%),
        fade(@brand-complement-dark, 0)
      );
    transition: opacity 0.15s ease-in-out;
  }

  > div {
    max-width: @content-max-width;
    min-height: 20px;
    margin: 0 auto;
    padding: 0 10px;
  }

  ul,
  li {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .mobile-menu-toggle {
    cursor: pointer;
    display: flex;
    margin: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 10px;
    top: -10px;

    width: @nav-toggle-size;
    height: @nav-toggle-size;
    z-index: @zindex-root-popover + 1;
    opacity: 0.6;
    transition: opacity 0.15s ease-in-out;

    &:hover {
      opacity: 1;
    }

    > span {
      display: block;
      position: relative;
      height: 2px;
      width: 18px;
      background-color: @brand-complement-dark;

      .simple-content & {
        background-color: @white;
      }

      transition: all 0.2s ease-in-out;

      + span {
        margin-top: 5px;
      }
    }
  }

  .nav-content {
    display: flex;
    align-items: baseline;
  }

  .logo {
    z-index: @zindex-root-popover + 1;
    span {
      height: 3em;
      width: 6em;
      top: -10px;
    }
  }

  .nav-links-wrapper {
    z-index: @zindex-root-popover;

    @media (max-width: @screen-xs-max) {
      position: absolute;
      top: -50px;
      left: 0;
      width: 100%;
      background-color: @white;
      padding: 110px 20px 40px;
      transform: translate(0, -110%);
      transition: all 0.2s cubic-bezier(0.5, -0.19, 0.14, 0.83);
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      text-align: center;
    }

    .nav-links > li {
      + li {
        margin-top: 20px;
      }

      a {
        color: @text-color;
        .fancy-underline-link(@offset: 12px, @bottom: -4px);
        display: inline-block;
        padding: 0 14px;
        white-space: nowrap;
      }
    }

    @media (max-width: @screen-xs-max) {
      .login-links {
        margin-top: 40px;
        display: flex;
        justify-content: space-around;

        > * {
          flex: 0 1 40%;

          a {
            display: block;
            .btn-basic.light-bg();
          }
        }
      }
    }
  }

  .mobile-menu-bg {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: fade(@brand-complement-dark, 60%);
    opacity: 0;
    pointer-events: none;
    z-index: @zindex-root-popover - 1;
    transition: opacity 0.25s ease-out;
    margin: 0;
  }

  @media (min-width: @screen-sm-min) {
    top: 34px;

    > div {
      padding: 0 20px;
      min-height: 30px;
    }

    .logo {
      font-size: 18px;
    }

    > div,
    .nav-links-wrapper,
    ul.login-links {
      display: flex;
      align-items: flex-end;

      > * {
        flex: 0 1 auto;
      }
    }

    .nav-links-wrapper {
      flex: 1 1 auto;

      .login-links {
        margin-left: auto;
        margin-top: 0;

        > li + li {
          margin-top: 0;
          margin-left: 32px;
        }
      }
    }
  }

  @media (min-width: @screen-sm-min) and (max-width: @nav-show-menu-width) {
    // switch to always showing the login links
    .mobile-menu-toggle {
      top: -2px;

      left: 140px; // puts it to the left of the main log
      right: auto;

      .not-simple-content & > span {
        background-color: @brand-complement-dark;
      }
    }

    .nav-links-wrapper {
      pointer-events: none;
    }

    .links {
      pointer-events: auto;
    }

    .links.nav-links {
      position: fixed;
      top: 0;
      bottom: 0;
      left: -50px;
      width: @nav-small-menu-width + 50;
      background-color: @white;
      padding: 100px 40px 40px 90px;
      transform: translate(-110%, 0);
      transition: all 0.15s cubic-bezier(0.5, -0.19, 0.14, 0.83);
      box-shadow: 2px 0 8px rgba(0, 0, 0, 0.1);
      z-index: @zindex-root-popover;
      overflow: auto;
    }
  }

  @media (max-width: @nav-show-menu-width) {
    #mobile-menu-toggle-checkbox:checked ~ & {
      .logo {
        position: absolute;
        .qualified-logo-white {
          background-image: url('/shared/images/qualified.svg');
        }
      }

      .mobile-menu-toggle {
        > span {
          background-color: @brand-dark;
          transform-origin: 50% 50%;
        }

        > span:nth-child(1) {
          transform: translateY(7px) rotate(-45deg);
        }

        > span:nth-child(2) {
          transform: rotate(45deg);
        }

        > span:nth-child(3) {
          transform: translateY(-7px) rotate(-45deg);
        }
      }

      @media (max-width: @screen-xs-max) {
        .nav-links-wrapper {
          transform: translate(0, 0);
          transition-timing-function: cubic-bezier(0.17, 0.38, 0.8, 1.2);
        }
      }
      @media (min-width: @screen-sm-min) {
        .nav-links-wrapper .links.nav-links {
          transform: translate(0, 0);
          transition-timing-function: cubic-bezier(0.17, 0.38, 0.8, 1.2);
        }
      }

      .mobile-menu-bg {
        opacity: 1;
        pointer-events: auto;
        z-index: @zindex-root-popover - 1;
      }
    }
  }

  @media (min-width: @nav-show-inline-width) {
    .mobile-menu-bg,
    .mobile-menu-toggle {
      display: none !important;
    }

    ul.links {
      display: flex;

      > * {
        flex: 0 1 auto;
      }

      .simple-content &:not(.login-links) a {
        color: @white;
        opacity: 0.8;

        &:after {
          background-color: @white;
        }

        &:hover,
        &:active,
        &:focus,
        &.active {
          opacity: 1;
        }
      }
    }

    .nav-links-wrapper {
      .links {
        margin-top: 0;

        > li {
          margin-left: 32px;

          + li {
            margin-top: 0;
          }
        }
      }
    }
  }

  &.sticky-header-active {
    top: 0;
    height: 30px !important;
    background-color: @body-bg;
    padding-top: 5px;

    .mobile-menu-bg {
      top: 30px;
    }

    .simple-content & {
      background: @brand-complement-dark;
    }

    .nav-content {
      align-items: center;
      .logo {
        span {
          height: 2em;
          width: 5em;
          top: 2px;
          @media (max-width: @screen-xs-max) {
            width: 5.5em;
            top: -2px;
          }
        }
      }
    }

    .mobile-menu-toggle {
      top: -5px;
    }

    @media (min-width: @screen-sm-min) {
      height: 50px !important;
      .mobile-menu-bg {
        top: 50px;
      }

      .mobile-menu-toggle {
        top: 3px;
      }
    }

    .not-simple-content & .login-links > li > a {
      .btn-basic.light-bg();
    }

    &:after {
      opacity: 1;
    }
  }

  .login-links {
    > li > a {
      .btn-basic();
      margin: -0.6em 0;

      &.signup {
        .btn-basic.plain();
      }
    }

    // Controls the visibility of the various login-links
    &.popup {
      display: none;
    }

    &.logged-in-user {
      display: none;

      > * {
        display: none;
      }
    }

    .qualified-logged-in-employer & {
      &.employers {
        display: none;
      }

      &.logged-in-user {
        display: block;
      }
    }

    .qualified-logged-in-employer &.logged-in-user {
      .employer {
        display: inline-block;
      }

      .candidate {
        display: none;
      }
    }

    .popup-window & {
      &.employers,
      &.logged-in-user {
        display: none;
      }

      &.popup {
        display: block;
      }
    }
  }
}
