.qualified-logo {
  position: relative;
  overflow: visible;
  color: @brand-dark;
  .icon-qualified-logo();
  span {
    .text-hide();
  }
}

.svg-logo-base {
  position: relative;
  overflow: visible;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  display: inline-block;
  min-height: 1em;
  span {
    .text-hide();
  }
}

.qualified-logo-full-color {
  &:extend(.svg-logo-base all);
  height: 1.674em;
  width: 5.674em;
  background-image: url('/shared/images/qualified.svg');
}

.qualified-logo-full-color-white {
  &:extend(.svg-logo-base all);
  width: 5.674em;
  background-image: url('/shared/images/qualified-full-color-white.svg');
}

.qualified-logo-full-color-dynamic {
  .qualified-logo-full-color();
  .dark-bg & {
    background-image: url('/shared/images/qualified-full-color-white.svg');
  }
}

.qualified-icon-full-color {
  &:extend(.svg-logo-base all);
  width: 1.471em;
  &.square {
    width: 1em;
  }
  background-image: url('/shared/images/qualified-icon-color-black.svg');
}

.qualified-icon-full-color-white {
  &:extend(.svg-logo-base all);
  width: 1.471em;
  background-image: url('/shared/images/qualified-icon-color-white.svg');
}

.qualified-icon-full-color-dynamic {
  .qualified-icon-full-color();
  .dark-bg & {
    background-image: url('/shared/images/qualified-icon-color-white.svg');
  }
}

.qualified-logo-white {
  &:extend(.svg-logo-base all);
  height: 1.674em;
  width: 5.674em;
  background-image: url('/shared/images/qualified-white.svg');
}

.qualified-logo-black {
  &:extend(.svg-logo-base all);
  width: 5.674em;
  background-image: url('/shared/images/qualified-black.svg');
}

.qualified-logo-dynamic {
  .qualified-logo-black();
  .dark-bg & {
    background-image: url('/shared/images/qualified-white.svg');
  }
}

.qualified-label {
  position: relative;
  display: inline-block;
  font-size: 9px;
  color: @white;
  background-color: @brand-primary-dark;
  border-radius: 1em;
  padding: 0.12em 0.56em;

  .icon-qualified-logo-text();

  > span {
    .text-hide();
  }

  &.qualified-label-small {
    .icon-qualified-logo-icon();
    &:before {
      display: inline-block;
      transform: scale(1.2);
    }
  }
}

// special "status" icon used to indicate qualified-products via the status column in small lists
.state-qualified {
  .icon-qualified-logo-icon();
  &:before {
    color: @white;
    background-color: @brand-primary-dark;
    border-radius: 8px;
    padding: 2px 4px;
    line-height: 1;
    margin-left: -4px;
  }
}
