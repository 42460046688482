.sticky-header-placeholder-inactive {
  display: none !important;
}

.sticky-header-placeholder-active {
  opacity: 0 !important;
  z-index: auto !important;
}

.sticky-header-active {
  position: fixed !important;
  z-index: @zindex-navbar;
}
