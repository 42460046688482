.input-group.input-group-inlined {
  transition: box-shadow ease-in-out 0.15s;

  .has-focus & {
    @color: @input-border-focus;
    @color-rgba: rgba(red(@color), green(@color), blue(@color), 0.6);
    box-shadow:
      inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 8px @color-rgba;
  }

  .has-focus:not(.has-success):not(.has-warning):not(.has-error) & {
    .input-group-addon-inlined {
      border-color: @input-border-focus;
    }
  }

  .has-success & {
    @shadow:
      inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 6px lighten(@state-success-text, 20%);
    .box-shadow(@shadow);
  }

  .has-warning & {
    @shadow:
      inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 6px lighten(@state-warning-text, 20%);
    .box-shadow(@shadow);
  }

  .has-error & {
    @shadow:
      inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 6px lighten(@state-danger-text, 20%);
    .box-shadow(@shadow);
  }

  .form-control {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075) !important;

    &:not(:first-child) {
      border-left-color: transparent !important;
    }

    &:not(:last-child) {
      border-right-color: transparent !important;
    }
  }
}

.input-group-addon-inlined {
  position: relative;
  color: @input-color;
  background-color: @input-bg;
  border: 1px solid @input-border;
  border-radius: @input-border-radius;
  transition:
    border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  z-index: 3;

  .has-error & {
    background-color: @input-bg;
  }

  &:first-child {
    .box-shadow(inset 1px 1px 1px rgba(0, 0, 0, 0.075));

    & + .form-control {
      border-left: none;

      &:not(:focus) {
        .box-shadow(inset -1px 1px 1px rgba(0, 0, 0, 0.075));
      }
    }
  }

  &:last-child {
    .box-shadow(inset -1px 1px 1px rgba(0, 0, 0, 0.075));
  }

  &.no-gap {
    &:first-child {
      padding-right: 0;

      + .form-control {
        padding-left: 0;
      }
    }

    &:last-child {
      padding-left: 0;
    }
  }
}

.input-group.input-group-inlined .form-control:not(:last-child) {
  border-right: none;
}

.input-group.input-group-inlined.no-gap .form-control:not(:last-child) {
  border-right: none;
  padding-right: 0;
}
