@import './qualified-input';

// Usage:
// <div class="qualified-radio">
//     <input type="radio" id="my-radio-a" name="my-radio" value="a" checked>
//     <label for="my-radio-a">Value A</label>
// </div>
.qualified-radio {
  .qualified-input(radio, dot, 50%);
}
