@font-face {
	font-family: "common-icons";
	src: url('/shared/icons/common-icons.eot');
	src: url('/shared/icons/common-icons.eot?#iefix') format('eot'),
		url('/shared/icons/common-icons.woff2') format('woff2'),
		url('/shared/icons/common-icons.woff') format('woff'),
		url('/shared/icons/common-icons.ttf') format('truetype'),
		url('/shared/icons/common-icons.svg#common-icons') format('svg');
}

.icon-common-icons-base-pseudo {
	font-family: "common-icons";
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	text-decoration: none;
	text-transform: none;
	line-height: 1;
}

.icon-common-icons-char(@filename) {
	@actions-menu: "\EA01";
	@advanced-code: "\EA02";
	@android-app: "\EA03";
	@android: "\EA04";
	@api: "\EA05";
	@archive: "\EA06";
	@arrow-down-right: "\EA07";
	@article: "\EA08";
	@articles: "\EA09";
	@audio-mute: "\EA0A";
	@audio-unmute: "\EA0B";
	@automate: "\EA0C";
	@blank-space: "\EA0D";
	@blank: "\EA0E";
	@broadcast: "\EA0F";
	@broom: "\EA10";
	@bucket: "\EA11";
	@buckets: "\EA12";
	@calendar: "\EA13";
	@candidate: "\EA14";
	@checkmark-empty: "\EA15";
	@checkmark: "\EA16";
	@chevron-down: "\EA17";
	@chevron-left: "\EA18";
	@chevron-right: "\EA19";
	@chevron-up: "\EA1A";
	@choice: "\EA1B";
	@clock: "\EA1C";
	@code: "\EA1D";
	@coffeepot: "\EA1E";
	@cog: "\EA1F";
	@cogs: "\EA20";
	@compare: "\EA21";
	@contract-all: "\EA22";
	@contract: "\EA23";
	@crane: "\EA24";
	@decline: "\EA25";
	@description: "\EA26";
	@difficulty-advanced: "\EA27";
	@difficulty-basic: "\EA28";
	@document-inv-lines: "\EA29";
	@document-inv: "\EA2A";
	@document: "\EA2B";
	@dot: "\EA2C";
	@drag-sort: "\EA2D";
	@duplicate: "\EA2E";
	@ellipsis: "\EA2F";
	@email-interviewer: "\EA30";
	@email-inverted: "\EA31";
	@email: "\EA32";
	@embed: "\EA33";
	@equal: "\EA34";
	@evidence: "\EA35";
	@excite: "\EA36";
	@exclamation-mark: "\EA37";
	@expand-all: "\EA38";
	@expand: "\EA39";
	@export: "\EA3A";
	@express-interest: "\EA3B";
	@eye: "\EA3C";
	@face-base: "\EA3D";
	@face-frown: "\EA3E";
	@face-neutral: "\EA3F";
	@face-smile: "\EA40";
	@filter: "\EA41";
	@flipflops: "\EA42";
	@folder-add: "\EA43";
	@folder-closed: "\EA44";
	@folder-open: "\EA45";
	@gauge: "\EA46";
	@globe-europe: "\EA47";
	@globe: "\EA48";
	@hdtv: "\EA49";
	@hidden: "\EA4A";
	@history: "\EA4B";
	@home: "\EA4C";
	@hourglass: "\EA4D";
	@ide-tests: "\EA4E";
	@identify: "\EA4F";
	@inbox: "\EA50";
	@initially-open: "\EA51";
	@inspect-solution: "\EA52";
	@integrations: "\EA53";
	@interact-session: "\EA54";
	@ios: "\EA55";
	@key: "\EA56";
	@label: "\EA57";
	@labels: "\EA58";
	@labs-sm: "\EA59";
	@labs: "\EA5A";
	@lang-angular: "\EA5B";
	@lang-c: "\EA5C";
	@lang-clojure: "\EA5D";
	@lang-coffeescript: "\EA5E";
	@lang-cpp: "\EA5F";
	@lang-crystal: "\EA60";
	@lang-csharp: "\EA61";
	@lang-css: "\EA62";
	@lang-dart: "\EA63";
	@lang-django: "\EA64";
	@lang-elasticsearch: "\EA65";
	@lang-elixir: "\EA66";
	@lang-elm: "\EA67";
	@lang-erlang: "\EA68";
	@lang-fsharp: "\EA69";
	@lang-go: "\EA6A";
	@lang-groovy: "\EA6B";
	@lang-haskell: "\EA6C";
	@lang-html: "\EA6D";
	@lang-java: "\EA6E";
	@lang-javascript: "\EA6F";
	@lang-jquery: "\EA70";
	@lang-julia: "\EA71";
	@lang-kotlin: "\EA72";
	@lang-lua: "\EA73";
	@lang-markdown: "\EA74";
	@lang-mongo: "\EA75";
	@lang-mysql: "\EA76";
	@lang-net: "\EA77";
	@lang-nodejs: "\EA78";
	@lang-objc: "\EA79";
	@lang-ocaml: "\EA7A";
	@lang-php: "\EA7B";
	@lang-postgresql: "\EA7C";
	@lang-powershell: "\EA7D";
	@lang-python: "\EA7E";
	@lang-r: "\EA7F";
	@lang-rails: "\EA80";
	@lang-react: "\EA81";
	@lang-reason: "\EA82";
	@lang-redis: "\EA83";
	@lang-ruby: "\EA84";
	@lang-rust: "\EA85";
	@lang-scala: "\EA86";
	@lang-shell: "\EA87";
	@lang-solidity: "\EA88";
	@lang-sql: "\EA89";
	@lang-sqlite: "\EA8A";
	@lang-swift: "\EA8B";
	@lang-typescript: "\EA8C";
	@layout-compact: "\EA8D";
	@layout-full: "\EA8E";
	@lightbulb-disabled: "\EA8F";
	@lightbulb-on: "\EA90";
	@lightbulb: "\EA91";
	@link: "\EA92";
	@live-interview-invited: "\EA93";
	@live-interview: "\EA94";
	@lock: "\EA95";
	@magnifying-glass: "\EA96";
	@map-marker: "\EA97";
	@mask: "\EA98";
	@matches: "\EA99";
	@meeting: "\EA9A";
	@menu: "\EA9B";
	@meters: "\EA9C";
	@minus: "\EA9D";
	@moon: "\EA9E";
	@most-recent: "\EA9F";
	@new-window: "\EAA0";
	@not-equal: "\EAA1";
	@note-empty: "\EAA2";
	@note: "\EAA3";
	@office: "\EAA4";
	@pencil: "\EAA5";
	@phone-hang-up: "\EAA6";
	@phone: "\EAA7";
	@pickaxe: "\EAA8";
	@plans: "\EAA9";
	@play-codecast: "\EAAA";
	@plus: "\EAAB";
	@power: "\EAAC";
	@print: "\EAAD";
	@product-changes-sm: "\EAAE";
	@product-changes: "\EAAF";
	@qualified-logo-icon: "\EAB0";
	@qualified-logo-text: "\EAB1";
	@qualified-logo: "\EAB2";
	@question-mark: "\EAB3";
	@quick-start: "\EAB4";
	@quiz-timed-per-questio-bonus: "\EAB5";
	@quiz-timed-per-question: "\EAB6";
	@quiz-untimed: "\EAB7";
	@quiz: "\EAB8";
	@ran-tests: "\EAB9";
	@readonly: "\EABA";
	@reload: "\EABB";
	@reset: "\EABC";
	@responsive: "\EABD";
	@resume-inv-add: "\EABE";
	@resume-inv: "\EABF";
	@resume: "\EAC0";
	@rotate-display: "\EAC1";
	@rubiks-add: "\EAC2";
	@rubiks: "\EAC3";
	@runtime-config: "\EAC4";
	@save-and-continue: "\EAC5";
	@save: "\EAC6";
	@scroll-down-indicator: "\EAC7";
	@scroll-to-top: "\EAC8";
	@scroll-up-indicator: "\EAC9";
	@send-email: "\EACA";
	@shuffle: "\EACB";
	@similar: "\EACC";
	@smartphone: "\EACD";
	@stack: "\EACE";
	@star-filled: "\EACF";
	@star-outline: "\EAD0";
	@status-access: "\EAD1";
	@status-all: "\EAD2";
	@status-complete: "\EAD3";
	@status-done-timer-ran-out: "\EAD4";
	@status-done: "\EAD5";
	@status-fail: "\EAD6";
	@status-info: "\EAD7";
	@status-invited: "\EAD8";
	@status-new: "\EAD9";
	@status-partial: "\EADA";
	@status-pending: "\EADB";
	@status-reopen: "\EADC";
	@status-retake: "\EADD";
	@status-secure: "\EADE";
	@status-stop: "\EADF";
	@status-submit: "\EAE0";
	@status-tip: "\EAE1";
	@status-unknown: "\EAE2";
	@status-unsubmitted: "\EAE3";
	@status-waiting: "\EAE4";
	@stopwatch-add: "\EAE5";
	@stopwatch: "\EAE6";
	@student: "\EAE7";
	@substitute: "\EAE8";
	@sun: "\EAE9";
	@support: "\EAEA";
	@tablet: "\EAEB";
	@tag: "\EAEC";
	@tags: "\EAED";
	@tasks-add: "\EAEE";
	@tasks: "\EAEF";
	@team: "\EAF0";
	@test-drive: "\EAF1";
	@the-end: "\EAF2";
	@thumbs-down: "\EAF3";
	@thumbs-up: "\EAF4";
	@transport-first: "\EAF5";
	@transport-forward: "\EAF6";
	@transport-last: "\EAF7";
	@transport-next: "\EAF8";
	@transport-pause: "\EAF9";
	@transport-play: "\EAFA";
	@transport-previous: "\EAFB";
	@transport-replay: "\EAFC";
	@transport-rewind: "\EAFD";
	@transport-stop: "\EAFE";
	@trashcan: "\EAFF";
	@tutorial: "\EB00";
	@unbroadcast: "\EB01";
	@undo: "\EB02";
	@unlink: "\EB03";
	@unlock: "\EB04";
	@upload-cloud: "\EB05";
	@upload: "\EB06";
	@user-admin: "\EB07";
	@user: "\EB08";
	@users: "\EB09";
	@validate: "\EB0A";
	@vendor-angellist: "\EB0B";
	@vendor-bamboohr: "\EB0C";
	@vendor-behance: "\EB0D";
	@vendor-codepen: "\EB0E";
	@vendor-codewars: "\EB0F";
	@vendor-crunchbase: "\EB10";
	@vendor-dribbble: "\EB11";
	@vendor-facebook: "\EB12";
	@vendor-github: "\EB13";
	@vendor-glassdoor: "\EB14";
	@vendor-google-plus: "\EB15";
	@vendor-google: "\EB16";
	@vendor-greenhouse: "\EB17";
	@vendor-linkedin: "\EB18";
	@vendor-medium: "\EB19";
	@vendor-qualified: "\EB1A";
	@vendor-recruitee: "\EB1B";
	@vendor-stackoverflow: "\EB1C";
	@vendor-twitter: "\EB1D";
	@vendor-workable: "\EB1E";
	@vendor-zapier: "\EB1F";
	@video-call: "\EB20";
	@video-pause: "\EB21";
	@video-unpause: "\EB22";
	@videochat: "\EB23";
	@waiting-dots: "\EB24";
	@warning: "\EB25";
	@web-app: "\EB26";
	@webhooks: "\EB27";
	@website: "\EB28";
	@whiteboard: "\EB29";
	@x: "\EB2A";
	
	// aliases
	@help: @status-unknown;
	@info: @status-info;
	@true: @checkmark;
	@false: @x;
	
	content: @@filename;
}

.icon-common-icons(@filename, @insert: before) {
	@pseudo-selector: ~":@{insert}";

	&@{pseudo-selector} {
		&:extend(.icon-common-icons-base-pseudo);
		.icon-common-icons-char(@filename);
	}
}
.icon-common-icons-no-extend(@filename, @insert: before) {
	@pseudo-selector: ~":@{insert}";

	&@{pseudo-selector} {
		.icon-common-icons-base-pseudo();
		.icon-common-icons-char(@filename);
	}
}

.icon-actions-menu {
	.icon-common-icons(actions-menu);
}
.icon-actions-menu-after() {
	.icon-common-icons(actions-menu, after);
}
.icon-advanced-code {
	.icon-common-icons(advanced-code);
}
.icon-advanced-code-after() {
	.icon-common-icons(advanced-code, after);
}
.icon-android-app {
	.icon-common-icons(android-app);
}
.icon-android-app-after() {
	.icon-common-icons(android-app, after);
}
.icon-android {
	.icon-common-icons(android);
}
.icon-android-after() {
	.icon-common-icons(android, after);
}
.icon-api {
	.icon-common-icons(api);
}
.icon-api-after() {
	.icon-common-icons(api, after);
}
.icon-archive {
	.icon-common-icons(archive);
}
.icon-archive-after() {
	.icon-common-icons(archive, after);
}
.icon-arrow-down-right {
	.icon-common-icons(arrow-down-right);
}
.icon-arrow-down-right-after() {
	.icon-common-icons(arrow-down-right, after);
}
.icon-article {
	.icon-common-icons(article);
}
.icon-article-after() {
	.icon-common-icons(article, after);
}
.icon-articles {
	.icon-common-icons(articles);
}
.icon-articles-after() {
	.icon-common-icons(articles, after);
}
.icon-audio-mute {
	.icon-common-icons(audio-mute);
}
.icon-audio-mute-after() {
	.icon-common-icons(audio-mute, after);
}
.icon-audio-unmute {
	.icon-common-icons(audio-unmute);
}
.icon-audio-unmute-after() {
	.icon-common-icons(audio-unmute, after);
}
.icon-automate {
	.icon-common-icons(automate);
}
.icon-automate-after() {
	.icon-common-icons(automate, after);
}
.icon-blank-space {
	.icon-common-icons(blank-space);
}
.icon-blank-space-after() {
	.icon-common-icons(blank-space, after);
}
.icon-blank {
	.icon-common-icons(blank);
}
.icon-blank-after() {
	.icon-common-icons(blank, after);
}
.icon-broadcast {
	.icon-common-icons(broadcast);
}
.icon-broadcast-after() {
	.icon-common-icons(broadcast, after);
}
.icon-broom {
	.icon-common-icons(broom);
}
.icon-broom-after() {
	.icon-common-icons(broom, after);
}
.icon-bucket {
	.icon-common-icons(bucket);
}
.icon-bucket-after() {
	.icon-common-icons(bucket, after);
}
.icon-buckets {
	.icon-common-icons(buckets);
}
.icon-buckets-after() {
	.icon-common-icons(buckets, after);
}
.icon-calendar {
	.icon-common-icons(calendar);
}
.icon-calendar-after() {
	.icon-common-icons(calendar, after);
}
.icon-candidate {
	.icon-common-icons(candidate);
}
.icon-candidate-after() {
	.icon-common-icons(candidate, after);
}
.icon-checkmark-empty {
	.icon-common-icons(checkmark-empty);
}
.icon-checkmark-empty-after() {
	.icon-common-icons(checkmark-empty, after);
}
.icon-checkmark,
.icon-true {
	.icon-common-icons(checkmark);
}
.icon-checkmark-after() {
	.icon-common-icons(checkmark, after);
}
.icon-true-after() { .icon-checkmark-after() }
.icon-chevron-down {
	.icon-common-icons(chevron-down);
}
.icon-chevron-down-after() {
	.icon-common-icons(chevron-down, after);
}
.icon-chevron-left {
	.icon-common-icons(chevron-left);
}
.icon-chevron-left-after() {
	.icon-common-icons(chevron-left, after);
}
.icon-chevron-right {
	.icon-common-icons(chevron-right);
}
.icon-chevron-right-after() {
	.icon-common-icons(chevron-right, after);
}
.icon-chevron-up {
	.icon-common-icons(chevron-up);
}
.icon-chevron-up-after() {
	.icon-common-icons(chevron-up, after);
}
.icon-choice {
	.icon-common-icons(choice);
}
.icon-choice-after() {
	.icon-common-icons(choice, after);
}
.icon-clock {
	.icon-common-icons(clock);
}
.icon-clock-after() {
	.icon-common-icons(clock, after);
}
.icon-code {
	.icon-common-icons(code);
}
.icon-code-after() {
	.icon-common-icons(code, after);
}
.icon-coffeepot {
	.icon-common-icons(coffeepot);
}
.icon-coffeepot-after() {
	.icon-common-icons(coffeepot, after);
}
.icon-cog {
	.icon-common-icons(cog);
}
.icon-cog-after() {
	.icon-common-icons(cog, after);
}
.icon-cogs {
	.icon-common-icons(cogs);
}
.icon-cogs-after() {
	.icon-common-icons(cogs, after);
}
.icon-compare {
	.icon-common-icons(compare);
}
.icon-compare-after() {
	.icon-common-icons(compare, after);
}
.icon-contract-all {
	.icon-common-icons(contract-all);
}
.icon-contract-all-after() {
	.icon-common-icons(contract-all, after);
}
.icon-contract {
	.icon-common-icons(contract);
}
.icon-contract-after() {
	.icon-common-icons(contract, after);
}
.icon-crane {
	.icon-common-icons(crane);
}
.icon-crane-after() {
	.icon-common-icons(crane, after);
}
.icon-decline {
	.icon-common-icons(decline);
}
.icon-decline-after() {
	.icon-common-icons(decline, after);
}
.icon-description {
	.icon-common-icons(description);
}
.icon-description-after() {
	.icon-common-icons(description, after);
}
.icon-difficulty-advanced {
	.icon-common-icons(difficulty-advanced);
}
.icon-difficulty-advanced-after() {
	.icon-common-icons(difficulty-advanced, after);
}
.icon-difficulty-basic {
	.icon-common-icons(difficulty-basic);
}
.icon-difficulty-basic-after() {
	.icon-common-icons(difficulty-basic, after);
}
.icon-document-inv-lines {
	.icon-common-icons(document-inv-lines);
}
.icon-document-inv-lines-after() {
	.icon-common-icons(document-inv-lines, after);
}
.icon-document-inv {
	.icon-common-icons(document-inv);
}
.icon-document-inv-after() {
	.icon-common-icons(document-inv, after);
}
.icon-document {
	.icon-common-icons(document);
}
.icon-document-after() {
	.icon-common-icons(document, after);
}
.icon-dot {
	.icon-common-icons(dot);
}
.icon-dot-after() {
	.icon-common-icons(dot, after);
}
.icon-drag-sort {
	.icon-common-icons(drag-sort);
}
.icon-drag-sort-after() {
	.icon-common-icons(drag-sort, after);
}
.icon-duplicate {
	.icon-common-icons(duplicate);
}
.icon-duplicate-after() {
	.icon-common-icons(duplicate, after);
}
.icon-ellipsis {
	.icon-common-icons(ellipsis);
}
.icon-ellipsis-after() {
	.icon-common-icons(ellipsis, after);
}
.icon-email-interviewer {
	.icon-common-icons(email-interviewer);
}
.icon-email-interviewer-after() {
	.icon-common-icons(email-interviewer, after);
}
.icon-email-inverted {
	.icon-common-icons(email-inverted);
}
.icon-email-inverted-after() {
	.icon-common-icons(email-inverted, after);
}
.icon-email {
	.icon-common-icons(email);
}
.icon-email-after() {
	.icon-common-icons(email, after);
}
.icon-embed {
	.icon-common-icons(embed);
}
.icon-embed-after() {
	.icon-common-icons(embed, after);
}
.icon-equal {
	.icon-common-icons(equal);
}
.icon-equal-after() {
	.icon-common-icons(equal, after);
}
.icon-evidence {
	.icon-common-icons(evidence);
}
.icon-evidence-after() {
	.icon-common-icons(evidence, after);
}
.icon-excite {
	.icon-common-icons(excite);
}
.icon-excite-after() {
	.icon-common-icons(excite, after);
}
.icon-exclamation-mark {
	.icon-common-icons(exclamation-mark);
}
.icon-exclamation-mark-after() {
	.icon-common-icons(exclamation-mark, after);
}
.icon-expand-all {
	.icon-common-icons(expand-all);
}
.icon-expand-all-after() {
	.icon-common-icons(expand-all, after);
}
.icon-expand {
	.icon-common-icons(expand);
}
.icon-expand-after() {
	.icon-common-icons(expand, after);
}
.icon-export {
	.icon-common-icons(export);
}
.icon-export-after() {
	.icon-common-icons(export, after);
}
.icon-express-interest {
	.icon-common-icons(express-interest);
}
.icon-express-interest-after() {
	.icon-common-icons(express-interest, after);
}
.icon-eye {
	.icon-common-icons(eye);
}
.icon-eye-after() {
	.icon-common-icons(eye, after);
}
.icon-face-base {
	.icon-common-icons(face-base);
}
.icon-face-base-after() {
	.icon-common-icons(face-base, after);
}
.icon-face-frown {
	.icon-common-icons(face-frown);
}
.icon-face-frown-after() {
	.icon-common-icons(face-frown, after);
}
.icon-face-neutral {
	.icon-common-icons(face-neutral);
}
.icon-face-neutral-after() {
	.icon-common-icons(face-neutral, after);
}
.icon-face-smile {
	.icon-common-icons(face-smile);
}
.icon-face-smile-after() {
	.icon-common-icons(face-smile, after);
}
.icon-filter {
	.icon-common-icons(filter);
}
.icon-filter-after() {
	.icon-common-icons(filter, after);
}
.icon-flipflops {
	.icon-common-icons(flipflops);
}
.icon-flipflops-after() {
	.icon-common-icons(flipflops, after);
}
.icon-folder-add {
	.icon-common-icons(folder-add);
}
.icon-folder-add-after() {
	.icon-common-icons(folder-add, after);
}
.icon-folder-closed {
	.icon-common-icons(folder-closed);
}
.icon-folder-closed-after() {
	.icon-common-icons(folder-closed, after);
}
.icon-folder-open {
	.icon-common-icons(folder-open);
}
.icon-folder-open-after() {
	.icon-common-icons(folder-open, after);
}
.icon-gauge {
	.icon-common-icons(gauge);
}
.icon-gauge-after() {
	.icon-common-icons(gauge, after);
}
.icon-globe-europe {
	.icon-common-icons(globe-europe);
}
.icon-globe-europe-after() {
	.icon-common-icons(globe-europe, after);
}
.icon-globe {
	.icon-common-icons(globe);
}
.icon-globe-after() {
	.icon-common-icons(globe, after);
}
.icon-hdtv {
	.icon-common-icons(hdtv);
}
.icon-hdtv-after() {
	.icon-common-icons(hdtv, after);
}
.icon-hidden {
	.icon-common-icons(hidden);
}
.icon-hidden-after() {
	.icon-common-icons(hidden, after);
}
.icon-history {
	.icon-common-icons(history);
}
.icon-history-after() {
	.icon-common-icons(history, after);
}
.icon-home {
	.icon-common-icons(home);
}
.icon-home-after() {
	.icon-common-icons(home, after);
}
.icon-hourglass {
	.icon-common-icons(hourglass);
}
.icon-hourglass-after() {
	.icon-common-icons(hourglass, after);
}
.icon-ide-tests {
	.icon-common-icons(ide-tests);
}
.icon-ide-tests-after() {
	.icon-common-icons(ide-tests, after);
}
.icon-identify {
	.icon-common-icons(identify);
}
.icon-identify-after() {
	.icon-common-icons(identify, after);
}
.icon-inbox {
	.icon-common-icons(inbox);
}
.icon-inbox-after() {
	.icon-common-icons(inbox, after);
}
.icon-initially-open {
	.icon-common-icons(initially-open);
}
.icon-initially-open-after() {
	.icon-common-icons(initially-open, after);
}
.icon-inspect-solution {
	.icon-common-icons(inspect-solution);
}
.icon-inspect-solution-after() {
	.icon-common-icons(inspect-solution, after);
}
.icon-integrations {
	.icon-common-icons(integrations);
}
.icon-integrations-after() {
	.icon-common-icons(integrations, after);
}
.icon-interact-session {
	.icon-common-icons(interact-session);
}
.icon-interact-session-after() {
	.icon-common-icons(interact-session, after);
}
.icon-ios {
	.icon-common-icons(ios);
}
.icon-ios-after() {
	.icon-common-icons(ios, after);
}
.icon-key {
	.icon-common-icons(key);
}
.icon-key-after() {
	.icon-common-icons(key, after);
}
.icon-label {
	.icon-common-icons(label);
}
.icon-label-after() {
	.icon-common-icons(label, after);
}
.icon-labels {
	.icon-common-icons(labels);
}
.icon-labels-after() {
	.icon-common-icons(labels, after);
}
.icon-labs-sm {
	.icon-common-icons(labs-sm);
}
.icon-labs-sm-after() {
	.icon-common-icons(labs-sm, after);
}
.icon-labs {
	.icon-common-icons(labs);
}
.icon-labs-after() {
	.icon-common-icons(labs, after);
}
.icon-lang-angular {
	.icon-common-icons(lang-angular);
}
.icon-lang-angular-after() {
	.icon-common-icons(lang-angular, after);
}
.icon-lang-c {
	.icon-common-icons(lang-c);
}
.icon-lang-c-after() {
	.icon-common-icons(lang-c, after);
}
.icon-lang-clojure {
	.icon-common-icons(lang-clojure);
}
.icon-lang-clojure-after() {
	.icon-common-icons(lang-clojure, after);
}
.icon-lang-coffeescript {
	.icon-common-icons(lang-coffeescript);
}
.icon-lang-coffeescript-after() {
	.icon-common-icons(lang-coffeescript, after);
}
.icon-lang-cpp {
	.icon-common-icons(lang-cpp);
}
.icon-lang-cpp-after() {
	.icon-common-icons(lang-cpp, after);
}
.icon-lang-crystal {
	.icon-common-icons(lang-crystal);
}
.icon-lang-crystal-after() {
	.icon-common-icons(lang-crystal, after);
}
.icon-lang-csharp {
	.icon-common-icons(lang-csharp);
}
.icon-lang-csharp-after() {
	.icon-common-icons(lang-csharp, after);
}
.icon-lang-css {
	.icon-common-icons(lang-css);
}
.icon-lang-css-after() {
	.icon-common-icons(lang-css, after);
}
.icon-lang-dart {
	.icon-common-icons(lang-dart);
}
.icon-lang-dart-after() {
	.icon-common-icons(lang-dart, after);
}
.icon-lang-django {
	.icon-common-icons(lang-django);
}
.icon-lang-django-after() {
	.icon-common-icons(lang-django, after);
}
.icon-lang-elasticsearch {
	.icon-common-icons(lang-elasticsearch);
}
.icon-lang-elasticsearch-after() {
	.icon-common-icons(lang-elasticsearch, after);
}
.icon-lang-elixir {
	.icon-common-icons(lang-elixir);
}
.icon-lang-elixir-after() {
	.icon-common-icons(lang-elixir, after);
}
.icon-lang-elm {
	.icon-common-icons(lang-elm);
}
.icon-lang-elm-after() {
	.icon-common-icons(lang-elm, after);
}
.icon-lang-erlang {
	.icon-common-icons(lang-erlang);
}
.icon-lang-erlang-after() {
	.icon-common-icons(lang-erlang, after);
}
.icon-lang-fsharp {
	.icon-common-icons(lang-fsharp);
}
.icon-lang-fsharp-after() {
	.icon-common-icons(lang-fsharp, after);
}
.icon-lang-go {
	.icon-common-icons(lang-go);
}
.icon-lang-go-after() {
	.icon-common-icons(lang-go, after);
}
.icon-lang-groovy {
	.icon-common-icons(lang-groovy);
}
.icon-lang-groovy-after() {
	.icon-common-icons(lang-groovy, after);
}
.icon-lang-haskell {
	.icon-common-icons(lang-haskell);
}
.icon-lang-haskell-after() {
	.icon-common-icons(lang-haskell, after);
}
.icon-lang-html {
	.icon-common-icons(lang-html);
}
.icon-lang-html-after() {
	.icon-common-icons(lang-html, after);
}
.icon-lang-java {
	.icon-common-icons(lang-java);
}
.icon-lang-java-after() {
	.icon-common-icons(lang-java, after);
}
.icon-lang-javascript {
	.icon-common-icons(lang-javascript);
}
.icon-lang-javascript-after() {
	.icon-common-icons(lang-javascript, after);
}
.icon-lang-jquery {
	.icon-common-icons(lang-jquery);
}
.icon-lang-jquery-after() {
	.icon-common-icons(lang-jquery, after);
}
.icon-lang-julia {
	.icon-common-icons(lang-julia);
}
.icon-lang-julia-after() {
	.icon-common-icons(lang-julia, after);
}
.icon-lang-kotlin {
	.icon-common-icons(lang-kotlin);
}
.icon-lang-kotlin-after() {
	.icon-common-icons(lang-kotlin, after);
}
.icon-lang-lua {
	.icon-common-icons(lang-lua);
}
.icon-lang-lua-after() {
	.icon-common-icons(lang-lua, after);
}
.icon-lang-markdown {
	.icon-common-icons(lang-markdown);
}
.icon-lang-markdown-after() {
	.icon-common-icons(lang-markdown, after);
}
.icon-lang-mongo {
	.icon-common-icons(lang-mongo);
}
.icon-lang-mongo-after() {
	.icon-common-icons(lang-mongo, after);
}
.icon-lang-mysql {
	.icon-common-icons(lang-mysql);
}
.icon-lang-mysql-after() {
	.icon-common-icons(lang-mysql, after);
}
.icon-lang-net {
	.icon-common-icons(lang-net);
}
.icon-lang-net-after() {
	.icon-common-icons(lang-net, after);
}
.icon-lang-nodejs {
	.icon-common-icons(lang-nodejs);
}
.icon-lang-nodejs-after() {
	.icon-common-icons(lang-nodejs, after);
}
.icon-lang-objc {
	.icon-common-icons(lang-objc);
}
.icon-lang-objc-after() {
	.icon-common-icons(lang-objc, after);
}
.icon-lang-ocaml {
	.icon-common-icons(lang-ocaml);
}
.icon-lang-ocaml-after() {
	.icon-common-icons(lang-ocaml, after);
}
.icon-lang-php {
	.icon-common-icons(lang-php);
}
.icon-lang-php-after() {
	.icon-common-icons(lang-php, after);
}
.icon-lang-postgresql {
	.icon-common-icons(lang-postgresql);
}
.icon-lang-postgresql-after() {
	.icon-common-icons(lang-postgresql, after);
}
.icon-lang-powershell {
	.icon-common-icons(lang-powershell);
}
.icon-lang-powershell-after() {
	.icon-common-icons(lang-powershell, after);
}
.icon-lang-python {
	.icon-common-icons(lang-python);
}
.icon-lang-python-after() {
	.icon-common-icons(lang-python, after);
}
.icon-lang-r {
	.icon-common-icons(lang-r);
}
.icon-lang-r-after() {
	.icon-common-icons(lang-r, after);
}
.icon-lang-rails {
	.icon-common-icons(lang-rails);
}
.icon-lang-rails-after() {
	.icon-common-icons(lang-rails, after);
}
.icon-lang-react {
	.icon-common-icons(lang-react);
}
.icon-lang-react-after() {
	.icon-common-icons(lang-react, after);
}
.icon-lang-reason {
	.icon-common-icons(lang-reason);
}
.icon-lang-reason-after() {
	.icon-common-icons(lang-reason, after);
}
.icon-lang-redis {
	.icon-common-icons(lang-redis);
}
.icon-lang-redis-after() {
	.icon-common-icons(lang-redis, after);
}
.icon-lang-ruby {
	.icon-common-icons(lang-ruby);
}
.icon-lang-ruby-after() {
	.icon-common-icons(lang-ruby, after);
}
.icon-lang-rust {
	.icon-common-icons(lang-rust);
}
.icon-lang-rust-after() {
	.icon-common-icons(lang-rust, after);
}
.icon-lang-scala {
	.icon-common-icons(lang-scala);
}
.icon-lang-scala-after() {
	.icon-common-icons(lang-scala, after);
}
.icon-lang-shell {
	.icon-common-icons(lang-shell);
}
.icon-lang-shell-after() {
	.icon-common-icons(lang-shell, after);
}
.icon-lang-solidity {
	.icon-common-icons(lang-solidity);
}
.icon-lang-solidity-after() {
	.icon-common-icons(lang-solidity, after);
}
.icon-lang-sql {
	.icon-common-icons(lang-sql);
}
.icon-lang-sql-after() {
	.icon-common-icons(lang-sql, after);
}
.icon-lang-sqlite {
	.icon-common-icons(lang-sqlite);
}
.icon-lang-sqlite-after() {
	.icon-common-icons(lang-sqlite, after);
}
.icon-lang-swift {
	.icon-common-icons(lang-swift);
}
.icon-lang-swift-after() {
	.icon-common-icons(lang-swift, after);
}
.icon-lang-typescript {
	.icon-common-icons(lang-typescript);
}
.icon-lang-typescript-after() {
	.icon-common-icons(lang-typescript, after);
}
.icon-layout-compact {
	.icon-common-icons(layout-compact);
}
.icon-layout-compact-after() {
	.icon-common-icons(layout-compact, after);
}
.icon-layout-full {
	.icon-common-icons(layout-full);
}
.icon-layout-full-after() {
	.icon-common-icons(layout-full, after);
}
.icon-lightbulb-disabled {
	.icon-common-icons(lightbulb-disabled);
}
.icon-lightbulb-disabled-after() {
	.icon-common-icons(lightbulb-disabled, after);
}
.icon-lightbulb-on {
	.icon-common-icons(lightbulb-on);
}
.icon-lightbulb-on-after() {
	.icon-common-icons(lightbulb-on, after);
}
.icon-lightbulb {
	.icon-common-icons(lightbulb);
}
.icon-lightbulb-after() {
	.icon-common-icons(lightbulb, after);
}
.icon-link {
	.icon-common-icons(link);
}
.icon-link-after() {
	.icon-common-icons(link, after);
}
.icon-live-interview-invited {
	.icon-common-icons(live-interview-invited);
}
.icon-live-interview-invited-after() {
	.icon-common-icons(live-interview-invited, after);
}
.icon-live-interview {
	.icon-common-icons(live-interview);
}
.icon-live-interview-after() {
	.icon-common-icons(live-interview, after);
}
.icon-lock {
	.icon-common-icons(lock);
}
.icon-lock-after() {
	.icon-common-icons(lock, after);
}
.icon-magnifying-glass {
	.icon-common-icons(magnifying-glass);
}
.icon-magnifying-glass-after() {
	.icon-common-icons(magnifying-glass, after);
}
.icon-map-marker {
	.icon-common-icons(map-marker);
}
.icon-map-marker-after() {
	.icon-common-icons(map-marker, after);
}
.icon-mask {
	.icon-common-icons(mask);
}
.icon-mask-after() {
	.icon-common-icons(mask, after);
}
.icon-matches {
	.icon-common-icons(matches);
}
.icon-matches-after() {
	.icon-common-icons(matches, after);
}
.icon-meeting {
	.icon-common-icons(meeting);
}
.icon-meeting-after() {
	.icon-common-icons(meeting, after);
}
.icon-menu {
	.icon-common-icons(menu);
}
.icon-menu-after() {
	.icon-common-icons(menu, after);
}
.icon-meters {
	.icon-common-icons(meters);
}
.icon-meters-after() {
	.icon-common-icons(meters, after);
}
.icon-minus {
	.icon-common-icons(minus);
}
.icon-minus-after() {
	.icon-common-icons(minus, after);
}
.icon-moon {
	.icon-common-icons(moon);
}
.icon-moon-after() {
	.icon-common-icons(moon, after);
}
.icon-most-recent {
	.icon-common-icons(most-recent);
}
.icon-most-recent-after() {
	.icon-common-icons(most-recent, after);
}
.icon-new-window {
	.icon-common-icons(new-window);
}
.icon-new-window-after() {
	.icon-common-icons(new-window, after);
}
.icon-not-equal {
	.icon-common-icons(not-equal);
}
.icon-not-equal-after() {
	.icon-common-icons(not-equal, after);
}
.icon-note-empty {
	.icon-common-icons(note-empty);
}
.icon-note-empty-after() {
	.icon-common-icons(note-empty, after);
}
.icon-note {
	.icon-common-icons(note);
}
.icon-note-after() {
	.icon-common-icons(note, after);
}
.icon-office {
	.icon-common-icons(office);
}
.icon-office-after() {
	.icon-common-icons(office, after);
}
.icon-pencil {
	.icon-common-icons(pencil);
}
.icon-pencil-after() {
	.icon-common-icons(pencil, after);
}
.icon-phone-hang-up {
	.icon-common-icons(phone-hang-up);
}
.icon-phone-hang-up-after() {
	.icon-common-icons(phone-hang-up, after);
}
.icon-phone {
	.icon-common-icons(phone);
}
.icon-phone-after() {
	.icon-common-icons(phone, after);
}
.icon-pickaxe {
	.icon-common-icons(pickaxe);
}
.icon-pickaxe-after() {
	.icon-common-icons(pickaxe, after);
}
.icon-plans {
	.icon-common-icons(plans);
}
.icon-plans-after() {
	.icon-common-icons(plans, after);
}
.icon-play-codecast {
	.icon-common-icons(play-codecast);
}
.icon-play-codecast-after() {
	.icon-common-icons(play-codecast, after);
}
.icon-plus {
	.icon-common-icons(plus);
}
.icon-plus-after() {
	.icon-common-icons(plus, after);
}
.icon-power {
	.icon-common-icons(power);
}
.icon-power-after() {
	.icon-common-icons(power, after);
}
.icon-print {
	.icon-common-icons(print);
}
.icon-print-after() {
	.icon-common-icons(print, after);
}
.icon-product-changes-sm {
	.icon-common-icons(product-changes-sm);
}
.icon-product-changes-sm-after() {
	.icon-common-icons(product-changes-sm, after);
}
.icon-product-changes {
	.icon-common-icons(product-changes);
}
.icon-product-changes-after() {
	.icon-common-icons(product-changes, after);
}
.icon-qualified-logo-icon {
	.icon-common-icons(qualified-logo-icon);
}
.icon-qualified-logo-icon-after() {
	.icon-common-icons(qualified-logo-icon, after);
}
.icon-qualified-logo-text {
	.icon-common-icons(qualified-logo-text);
}
.icon-qualified-logo-text-after() {
	.icon-common-icons(qualified-logo-text, after);
}
.icon-qualified-logo {
	.icon-common-icons(qualified-logo);
}
.icon-qualified-logo-after() {
	.icon-common-icons(qualified-logo, after);
}
.icon-question-mark {
	.icon-common-icons(question-mark);
}
.icon-question-mark-after() {
	.icon-common-icons(question-mark, after);
}
.icon-quick-start {
	.icon-common-icons(quick-start);
}
.icon-quick-start-after() {
	.icon-common-icons(quick-start, after);
}
.icon-quiz-timed-per-questio-bonus {
	.icon-common-icons(quiz-timed-per-questio-bonus);
}
.icon-quiz-timed-per-questio-bonus-after() {
	.icon-common-icons(quiz-timed-per-questio-bonus, after);
}
.icon-quiz-timed-per-question {
	.icon-common-icons(quiz-timed-per-question);
}
.icon-quiz-timed-per-question-after() {
	.icon-common-icons(quiz-timed-per-question, after);
}
.icon-quiz-untimed {
	.icon-common-icons(quiz-untimed);
}
.icon-quiz-untimed-after() {
	.icon-common-icons(quiz-untimed, after);
}
.icon-quiz {
	.icon-common-icons(quiz);
}
.icon-quiz-after() {
	.icon-common-icons(quiz, after);
}
.icon-ran-tests {
	.icon-common-icons(ran-tests);
}
.icon-ran-tests-after() {
	.icon-common-icons(ran-tests, after);
}
.icon-readonly {
	.icon-common-icons(readonly);
}
.icon-readonly-after() {
	.icon-common-icons(readonly, after);
}
.icon-reload {
	.icon-common-icons(reload);
}
.icon-reload-after() {
	.icon-common-icons(reload, after);
}
.icon-reset {
	.icon-common-icons(reset);
}
.icon-reset-after() {
	.icon-common-icons(reset, after);
}
.icon-responsive {
	.icon-common-icons(responsive);
}
.icon-responsive-after() {
	.icon-common-icons(responsive, after);
}
.icon-resume-inv-add {
	.icon-common-icons(resume-inv-add);
}
.icon-resume-inv-add-after() {
	.icon-common-icons(resume-inv-add, after);
}
.icon-resume-inv {
	.icon-common-icons(resume-inv);
}
.icon-resume-inv-after() {
	.icon-common-icons(resume-inv, after);
}
.icon-resume {
	.icon-common-icons(resume);
}
.icon-resume-after() {
	.icon-common-icons(resume, after);
}
.icon-rotate-display {
	.icon-common-icons(rotate-display);
}
.icon-rotate-display-after() {
	.icon-common-icons(rotate-display, after);
}
.icon-rubiks-add {
	.icon-common-icons(rubiks-add);
}
.icon-rubiks-add-after() {
	.icon-common-icons(rubiks-add, after);
}
.icon-rubiks {
	.icon-common-icons(rubiks);
}
.icon-rubiks-after() {
	.icon-common-icons(rubiks, after);
}
.icon-runtime-config {
	.icon-common-icons(runtime-config);
}
.icon-runtime-config-after() {
	.icon-common-icons(runtime-config, after);
}
.icon-save-and-continue {
	.icon-common-icons(save-and-continue);
}
.icon-save-and-continue-after() {
	.icon-common-icons(save-and-continue, after);
}
.icon-save {
	.icon-common-icons(save);
}
.icon-save-after() {
	.icon-common-icons(save, after);
}
.icon-scroll-down-indicator {
	.icon-common-icons(scroll-down-indicator);
}
.icon-scroll-down-indicator-after() {
	.icon-common-icons(scroll-down-indicator, after);
}
.icon-scroll-to-top {
	.icon-common-icons(scroll-to-top);
}
.icon-scroll-to-top-after() {
	.icon-common-icons(scroll-to-top, after);
}
.icon-scroll-up-indicator {
	.icon-common-icons(scroll-up-indicator);
}
.icon-scroll-up-indicator-after() {
	.icon-common-icons(scroll-up-indicator, after);
}
.icon-send-email {
	.icon-common-icons(send-email);
}
.icon-send-email-after() {
	.icon-common-icons(send-email, after);
}
.icon-shuffle {
	.icon-common-icons(shuffle);
}
.icon-shuffle-after() {
	.icon-common-icons(shuffle, after);
}
.icon-similar {
	.icon-common-icons(similar);
}
.icon-similar-after() {
	.icon-common-icons(similar, after);
}
.icon-smartphone {
	.icon-common-icons(smartphone);
}
.icon-smartphone-after() {
	.icon-common-icons(smartphone, after);
}
.icon-stack {
	.icon-common-icons(stack);
}
.icon-stack-after() {
	.icon-common-icons(stack, after);
}
.icon-star-filled {
	.icon-common-icons(star-filled);
}
.icon-star-filled-after() {
	.icon-common-icons(star-filled, after);
}
.icon-star-outline {
	.icon-common-icons(star-outline);
}
.icon-star-outline-after() {
	.icon-common-icons(star-outline, after);
}
.icon-status-access {
	.icon-common-icons(status-access);
}
.icon-status-access-after() {
	.icon-common-icons(status-access, after);
}
.icon-status-all {
	.icon-common-icons(status-all);
}
.icon-status-all-after() {
	.icon-common-icons(status-all, after);
}
.icon-status-complete {
	.icon-common-icons(status-complete);
}
.icon-status-complete-after() {
	.icon-common-icons(status-complete, after);
}
.icon-status-done-timer-ran-out {
	.icon-common-icons(status-done-timer-ran-out);
}
.icon-status-done-timer-ran-out-after() {
	.icon-common-icons(status-done-timer-ran-out, after);
}
.icon-status-done {
	.icon-common-icons(status-done);
}
.icon-status-done-after() {
	.icon-common-icons(status-done, after);
}
.icon-status-fail {
	.icon-common-icons(status-fail);
}
.icon-status-fail-after() {
	.icon-common-icons(status-fail, after);
}
.icon-status-info,
.icon-info {
	.icon-common-icons(status-info);
}
.icon-status-info-after() {
	.icon-common-icons(status-info, after);
}
.icon-info-after() { .icon-status-info-after() }
.icon-status-invited {
	.icon-common-icons(status-invited);
}
.icon-status-invited-after() {
	.icon-common-icons(status-invited, after);
}
.icon-status-new {
	.icon-common-icons(status-new);
}
.icon-status-new-after() {
	.icon-common-icons(status-new, after);
}
.icon-status-partial {
	.icon-common-icons(status-partial);
}
.icon-status-partial-after() {
	.icon-common-icons(status-partial, after);
}
.icon-status-pending {
	.icon-common-icons(status-pending);
}
.icon-status-pending-after() {
	.icon-common-icons(status-pending, after);
}
.icon-status-reopen {
	.icon-common-icons(status-reopen);
}
.icon-status-reopen-after() {
	.icon-common-icons(status-reopen, after);
}
.icon-status-retake {
	.icon-common-icons(status-retake);
}
.icon-status-retake-after() {
	.icon-common-icons(status-retake, after);
}
.icon-status-secure {
	.icon-common-icons(status-secure);
}
.icon-status-secure-after() {
	.icon-common-icons(status-secure, after);
}
.icon-status-stop {
	.icon-common-icons(status-stop);
}
.icon-status-stop-after() {
	.icon-common-icons(status-stop, after);
}
.icon-status-submit {
	.icon-common-icons(status-submit);
}
.icon-status-submit-after() {
	.icon-common-icons(status-submit, after);
}
.icon-status-tip {
	.icon-common-icons(status-tip);
}
.icon-status-tip-after() {
	.icon-common-icons(status-tip, after);
}
.icon-status-unknown,
.icon-help {
	.icon-common-icons(status-unknown);
}
.icon-status-unknown-after() {
	.icon-common-icons(status-unknown, after);
}
.icon-help-after() { .icon-status-unknown-after() }
.icon-status-unsubmitted {
	.icon-common-icons(status-unsubmitted);
}
.icon-status-unsubmitted-after() {
	.icon-common-icons(status-unsubmitted, after);
}
.icon-status-waiting {
	.icon-common-icons(status-waiting);
}
.icon-status-waiting-after() {
	.icon-common-icons(status-waiting, after);
}
.icon-stopwatch-add {
	.icon-common-icons(stopwatch-add);
}
.icon-stopwatch-add-after() {
	.icon-common-icons(stopwatch-add, after);
}
.icon-stopwatch {
	.icon-common-icons(stopwatch);
}
.icon-stopwatch-after() {
	.icon-common-icons(stopwatch, after);
}
.icon-student {
	.icon-common-icons(student);
}
.icon-student-after() {
	.icon-common-icons(student, after);
}
.icon-substitute {
	.icon-common-icons(substitute);
}
.icon-substitute-after() {
	.icon-common-icons(substitute, after);
}
.icon-sun {
	.icon-common-icons(sun);
}
.icon-sun-after() {
	.icon-common-icons(sun, after);
}
.icon-support {
	.icon-common-icons(support);
}
.icon-support-after() {
	.icon-common-icons(support, after);
}
.icon-tablet {
	.icon-common-icons(tablet);
}
.icon-tablet-after() {
	.icon-common-icons(tablet, after);
}
.icon-tag {
	.icon-common-icons(tag);
}
.icon-tag-after() {
	.icon-common-icons(tag, after);
}
.icon-tags {
	.icon-common-icons(tags);
}
.icon-tags-after() {
	.icon-common-icons(tags, after);
}
.icon-tasks-add {
	.icon-common-icons(tasks-add);
}
.icon-tasks-add-after() {
	.icon-common-icons(tasks-add, after);
}
.icon-tasks {
	.icon-common-icons(tasks);
}
.icon-tasks-after() {
	.icon-common-icons(tasks, after);
}
.icon-team {
	.icon-common-icons(team);
}
.icon-team-after() {
	.icon-common-icons(team, after);
}
.icon-test-drive {
	.icon-common-icons(test-drive);
}
.icon-test-drive-after() {
	.icon-common-icons(test-drive, after);
}
.icon-the-end {
	.icon-common-icons(the-end);
}
.icon-the-end-after() {
	.icon-common-icons(the-end, after);
}
.icon-thumbs-down {
	.icon-common-icons(thumbs-down);
}
.icon-thumbs-down-after() {
	.icon-common-icons(thumbs-down, after);
}
.icon-thumbs-up {
	.icon-common-icons(thumbs-up);
}
.icon-thumbs-up-after() {
	.icon-common-icons(thumbs-up, after);
}
.icon-transport-first {
	.icon-common-icons(transport-first);
}
.icon-transport-first-after() {
	.icon-common-icons(transport-first, after);
}
.icon-transport-forward {
	.icon-common-icons(transport-forward);
}
.icon-transport-forward-after() {
	.icon-common-icons(transport-forward, after);
}
.icon-transport-last {
	.icon-common-icons(transport-last);
}
.icon-transport-last-after() {
	.icon-common-icons(transport-last, after);
}
.icon-transport-next {
	.icon-common-icons(transport-next);
}
.icon-transport-next-after() {
	.icon-common-icons(transport-next, after);
}
.icon-transport-pause {
	.icon-common-icons(transport-pause);
}
.icon-transport-pause-after() {
	.icon-common-icons(transport-pause, after);
}
.icon-transport-play {
	.icon-common-icons(transport-play);
}
.icon-transport-play-after() {
	.icon-common-icons(transport-play, after);
}
.icon-transport-previous {
	.icon-common-icons(transport-previous);
}
.icon-transport-previous-after() {
	.icon-common-icons(transport-previous, after);
}
.icon-transport-replay {
	.icon-common-icons(transport-replay);
}
.icon-transport-replay-after() {
	.icon-common-icons(transport-replay, after);
}
.icon-transport-rewind {
	.icon-common-icons(transport-rewind);
}
.icon-transport-rewind-after() {
	.icon-common-icons(transport-rewind, after);
}
.icon-transport-stop {
	.icon-common-icons(transport-stop);
}
.icon-transport-stop-after() {
	.icon-common-icons(transport-stop, after);
}
.icon-trashcan {
	.icon-common-icons(trashcan);
}
.icon-trashcan-after() {
	.icon-common-icons(trashcan, after);
}
.icon-tutorial {
	.icon-common-icons(tutorial);
}
.icon-tutorial-after() {
	.icon-common-icons(tutorial, after);
}
.icon-unbroadcast {
	.icon-common-icons(unbroadcast);
}
.icon-unbroadcast-after() {
	.icon-common-icons(unbroadcast, after);
}
.icon-undo {
	.icon-common-icons(undo);
}
.icon-undo-after() {
	.icon-common-icons(undo, after);
}
.icon-unlink {
	.icon-common-icons(unlink);
}
.icon-unlink-after() {
	.icon-common-icons(unlink, after);
}
.icon-unlock {
	.icon-common-icons(unlock);
}
.icon-unlock-after() {
	.icon-common-icons(unlock, after);
}
.icon-upload-cloud {
	.icon-common-icons(upload-cloud);
}
.icon-upload-cloud-after() {
	.icon-common-icons(upload-cloud, after);
}
.icon-upload {
	.icon-common-icons(upload);
}
.icon-upload-after() {
	.icon-common-icons(upload, after);
}
.icon-user-admin {
	.icon-common-icons(user-admin);
}
.icon-user-admin-after() {
	.icon-common-icons(user-admin, after);
}
.icon-user {
	.icon-common-icons(user);
}
.icon-user-after() {
	.icon-common-icons(user, after);
}
.icon-users {
	.icon-common-icons(users);
}
.icon-users-after() {
	.icon-common-icons(users, after);
}
.icon-validate {
	.icon-common-icons(validate);
}
.icon-validate-after() {
	.icon-common-icons(validate, after);
}
.icon-vendor-angellist {
	.icon-common-icons(vendor-angellist);
}
.icon-vendor-angellist-after() {
	.icon-common-icons(vendor-angellist, after);
}
.icon-vendor-bamboohr {
	.icon-common-icons(vendor-bamboohr);
}
.icon-vendor-bamboohr-after() {
	.icon-common-icons(vendor-bamboohr, after);
}
.icon-vendor-behance {
	.icon-common-icons(vendor-behance);
}
.icon-vendor-behance-after() {
	.icon-common-icons(vendor-behance, after);
}
.icon-vendor-codepen {
	.icon-common-icons(vendor-codepen);
}
.icon-vendor-codepen-after() {
	.icon-common-icons(vendor-codepen, after);
}
.icon-vendor-codewars {
	.icon-common-icons(vendor-codewars);
}
.icon-vendor-codewars-after() {
	.icon-common-icons(vendor-codewars, after);
}
.icon-vendor-crunchbase {
	.icon-common-icons(vendor-crunchbase);
}
.icon-vendor-crunchbase-after() {
	.icon-common-icons(vendor-crunchbase, after);
}
.icon-vendor-dribbble {
	.icon-common-icons(vendor-dribbble);
}
.icon-vendor-dribbble-after() {
	.icon-common-icons(vendor-dribbble, after);
}
.icon-vendor-facebook {
	.icon-common-icons(vendor-facebook);
}
.icon-vendor-facebook-after() {
	.icon-common-icons(vendor-facebook, after);
}
.icon-vendor-github {
	.icon-common-icons(vendor-github);
}
.icon-vendor-github-after() {
	.icon-common-icons(vendor-github, after);
}
.icon-vendor-glassdoor {
	.icon-common-icons(vendor-glassdoor);
}
.icon-vendor-glassdoor-after() {
	.icon-common-icons(vendor-glassdoor, after);
}
.icon-vendor-google-plus {
	.icon-common-icons(vendor-google-plus);
}
.icon-vendor-google-plus-after() {
	.icon-common-icons(vendor-google-plus, after);
}
.icon-vendor-google {
	.icon-common-icons(vendor-google);
}
.icon-vendor-google-after() {
	.icon-common-icons(vendor-google, after);
}
.icon-vendor-greenhouse {
	.icon-common-icons(vendor-greenhouse);
}
.icon-vendor-greenhouse-after() {
	.icon-common-icons(vendor-greenhouse, after);
}
.icon-vendor-linkedin {
	.icon-common-icons(vendor-linkedin);
}
.icon-vendor-linkedin-after() {
	.icon-common-icons(vendor-linkedin, after);
}
.icon-vendor-medium {
	.icon-common-icons(vendor-medium);
}
.icon-vendor-medium-after() {
	.icon-common-icons(vendor-medium, after);
}
.icon-vendor-qualified {
	.icon-common-icons(vendor-qualified);
}
.icon-vendor-qualified-after() {
	.icon-common-icons(vendor-qualified, after);
}
.icon-vendor-recruitee {
	.icon-common-icons(vendor-recruitee);
}
.icon-vendor-recruitee-after() {
	.icon-common-icons(vendor-recruitee, after);
}
.icon-vendor-stackoverflow {
	.icon-common-icons(vendor-stackoverflow);
}
.icon-vendor-stackoverflow-after() {
	.icon-common-icons(vendor-stackoverflow, after);
}
.icon-vendor-twitter {
	.icon-common-icons(vendor-twitter);
}
.icon-vendor-twitter-after() {
	.icon-common-icons(vendor-twitter, after);
}
.icon-vendor-workable {
	.icon-common-icons(vendor-workable);
}
.icon-vendor-workable-after() {
	.icon-common-icons(vendor-workable, after);
}
.icon-vendor-zapier {
	.icon-common-icons(vendor-zapier);
}
.icon-vendor-zapier-after() {
	.icon-common-icons(vendor-zapier, after);
}
.icon-video-call {
	.icon-common-icons(video-call);
}
.icon-video-call-after() {
	.icon-common-icons(video-call, after);
}
.icon-video-pause {
	.icon-common-icons(video-pause);
}
.icon-video-pause-after() {
	.icon-common-icons(video-pause, after);
}
.icon-video-unpause {
	.icon-common-icons(video-unpause);
}
.icon-video-unpause-after() {
	.icon-common-icons(video-unpause, after);
}
.icon-videochat {
	.icon-common-icons(videochat);
}
.icon-videochat-after() {
	.icon-common-icons(videochat, after);
}
.icon-waiting-dots {
	.icon-common-icons(waiting-dots);
}
.icon-waiting-dots-after() {
	.icon-common-icons(waiting-dots, after);
}
.icon-warning {
	.icon-common-icons(warning);
}
.icon-warning-after() {
	.icon-common-icons(warning, after);
}
.icon-web-app {
	.icon-common-icons(web-app);
}
.icon-web-app-after() {
	.icon-common-icons(web-app, after);
}
.icon-webhooks {
	.icon-common-icons(webhooks);
}
.icon-webhooks-after() {
	.icon-common-icons(webhooks, after);
}
.icon-website {
	.icon-common-icons(website);
}
.icon-website-after() {
	.icon-common-icons(website, after);
}
.icon-whiteboard {
	.icon-common-icons(whiteboard);
}
.icon-whiteboard-after() {
	.icon-common-icons(whiteboard, after);
}
.icon-x,
.icon-false {
	.icon-common-icons(x);
}
.icon-x-after() {
	.icon-common-icons(x, after);
}
.icon-false-after() { .icon-x-after() }


.icon-none {
	visibility: hidden !important;
	display: none !important;
	width: 0 !important;
	height: 0 !important;
	margin: 0 !important;
	padding: 0 !important;
	border: 0 !important;
}
