.partnership {
  .small-caps-title();
  ul {
    display: inline-flex;
    align-items: center;
    list-style: none;
    margin: 0 0 0 8px;
    padding: 0;

    li {
      display: inline-block;
      margin: 0;
      flex: 0 0 auto;

      + li {
        margin-left: 10px;
      }

      a {
        color: @brand-complement-light;
        display: inline-block;
        transition: all 0.15s ease-in-out;
        opacity: 0.8;

        &:hover,
        &:focus {
          opacity: 1;
          color: @brand-complement-dark;
        }
      }
    }

    a,
    img {
      display: inline-block;
      height: 1.5em;
      transform: translateY(-1px);
      filter: grayscale(1);
      opacity: 0.6;
      transition: all 0.15s ease-in-out;

      &:hover,
      &:focus {
        opacity: 1;
        filter: grayscale(0);
      }
    }
  }

  .logos-codewars {
    img {
      max-height: 1.5em;
      transform: translateY(-1px);
    }
  }

  @media (min-width: @screen-sm-min) {
    ul {
      margin-left: 20px;

      li + li {
        margin-left: 20px;
      }
    }

    img {
      height: 2.5em;
      transform: translateY(-2px);
    }
  }
}
