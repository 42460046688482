.loading-spinner,
.icon-loading-spinner {
  display: inline-block;
  position: relative;
  .icon-status-done();
  .icon-status-done-after();
  width: 1em;
  height: 1em;
  &:before,
  &:after {
    display: inline-block;
    opacity: 0;
    position: absolute;
    top: -0.1em;
    left: 0;
    line-height: inherit !important;
    font-size: inherit !important;
  }
  &:before {
    animation: loading-spinner-animation 2s linear 0s infinite;
  }
  &:after {
    animation: loading-spinner-animation 2s linear 1s infinite;
  }

  &.loading-spinner-slow {
    &:before {
      animation-duration: 3s;
    }
    &:after {
      animation-duration: 3s;
      animation-delay: 1.5s;
    }
  }

  &.loading-spinner-large {
    font-size: 40px;
    padding: 10px;
  }
}

@keyframes loading-spinner-animation {
  0% {
    opacity: 1;
    transform: scale(0.01);
  }
  80% {
    opacity: 0;
    transform: scale(1.5);
  }
  100% {
    opacity: 0;
  }
}
