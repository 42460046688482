// runtime: 3.2
.live-interview-details.is-visible svg {
  #instructions {
    opacity: 0;
    transition: opacity 0.1s 1.5s ease-in-out;
  }
  #ref-solution {
    animation: live-interview-details-is-visible-code-animation 1.6s 1.5s 1
      linear forwards;
  }
  #test-cases {
    opacity: 1;
    transition: opacity 0.1s 3s ease-in-out;
  }
}

@keyframes live-interview-details-is-visible-code-animation {
  0%,
  100% {
    opacity: 0;
  }
  4.8%,
  95.2% {
    opacity: 1;
  }
}
