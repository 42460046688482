[code-highlight] {
  .carry-forward-notification {
    font-style: normal;
    font-family: @font-family-sans-serif;
    background-color: lighten(@btn-warning-bg, 25%);
    color: @gray-dark;
    padding: 1px 5px;
    font-size: @font-size-small;
    user-select: none;
    cursor: default;
    a {
      color: #448844;
    }
  }
}
