[on-scroll-add-class-clickable] {
  cursor: pointer;
  position: relative;
  .icon-transport-replay-after();

  &:after {
    color: @btn-primary-color;
    opacity: 0;
    display: block;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.15s ease-in-out;
    background-color: @btn-primary-bg;
    border-radius: 50%;
    font-size: 22px;
    line-height: 32px;
    width: 32px;
    height: 32px;
    padding: 0;
    text-align: center;
    z-index: 2;
  }

  .is-visible.is-finished &:after,
  &.is-visible.is-finished:after {
    opacity: 1;
    transition-delay: 0.5s;
  }
}

//@import "assessment-results"; unused
@import 'code-playback';
@import 'compare-candidates';
@import 'custom-assessments';
@import 'custom-challenges';
@import 'detailed-results';
@import 'live-interview-details';
@import 'live-interview-real-time';
@import 'live-interview-results';
@import 'live-interview-start';
//@import "send-email"; unused
@import 'take-assessment';
@import 'unit-tests';
