// runtime: 25
.code-playback:not(.is-visible) svg {
  #code-playback-animated {
    display: none;
  }
}
.code-playback.is-visible svg {
  #code-playback-static {
    animation: code-playback-is-visible-static 25s 1 linear;
  }
  #playback-thumb {
    animation: code-playback-is-visible-thumb 25s 1 linear;
  }
}

@keyframes code-playback-is-visible-static {
  0% {
    opacity: 1;
  }
  1% {
    opacity: 0;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes code-playback-is-visible-thumb {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(360px);
  }
}
