.hero {
  padding: 60px 10px 20px;
  text-align: center;
  z-index: @zindex-navbar - 50;
  @media (min-width: @screen-sm-min) {
    padding: 80px 0 40px 20px;
    text-align: left;
    overflow: hidden;

    background-image: data-uri('../../landing/images/header-bg/1.svg');
    background-size: auto 90%;
    background-position: calc(50vw + 40px) 0;
    background-repeat: no-repeat;

    &.bg2 {
      background-image: data-uri('../../landing/images/header-bg/2.svg');
    }

    &.bg3 {
      background-image: data-uri('../../landing/images/header-bg/3.svg');
    }

    > div {
      display: flex;

      > * {
        flex: 0 1 50%;
      }
    }
  }

  @media (min-width: @screen-lg-min) {
    padding: 80px 0 40px 40px;

    @media (min-height: @screen-v-lg-min) {
      padding: 100px 0 60px 40px;
    }
  }

  > div {
    max-width: @content-max-width;
    margin: 0 auto;

    > .hero-actions {
      @media (min-width: @screen-sm-min) {
        padding-top: 40px;
        margin-right: 40px;
      }
      @media (min-width: @screen-lg-min) {
        padding-top: 50px;
        padding-bottom: 50px;
        margin-right: 40px;
      }
    }

    > figure {
      position: relative;
      overflow: hidden;

      background: data-uri('../../landing/images/bg-boxes/hero.svg') no-repeat
        50% 50%;
      background-size: cover;
      padding: 30px 10px 10px;
      margin: 20px -10px;

      @media (min-width: @screen-sm-min) {
        margin: 0;
        background-image: none;
        padding: 30px 0 0;
      }

      img {
        width: 100%;
        max-width: 650px;
        @media (min-width: @screen-md-min) {
          width: 100%;
        }
      }

      @media (min-width: @screen-xl-min) {
        overflow: visible;
        position: relative;
        img {
          position: absolute;
          width: 110%;
          max-width: none;
          top: 30px;
          left: 0;
        }
      }
    }
  }

  .subbrand {
    margin: 0 0 20px 0;
    img {
      display: inline-block;
      height: 15px;
    }
    @media (min-width: @screen-sm-min) {
      margin: 0 0 24px 0;
      img {
        height: 25px;
      }
    }
    @media (min-width: @screen-lg-min) {
      margin: 0 0 30px 0;
    }
    @media (min-width: @screen-xl-min) {
      margin: 0 0 40px 0;
    }
  }

  .hero-note,
  .hero-cta,
  .partnership {
    margin: 20px 0 0;
    @media (min-width: @screen-sm-min) {
      margin: 24px 0 0;
    }
    @media (min-width: @screen-lg-min) {
      margin: 30px 0 0;
    }
    @media (min-width: @screen-xl-min) {
      margin: 40px 0 0;
    }
  }

  .partnership {
    padding-top: 0.7em; // makes up for optical spacing in letters
  }

  h1 {
    font-size: 32px;
    line-height: 1.1;
    color: @brand-complement-dark;
    margin: 10px 0 0;
    font-weight: @font-weight-serif-heavy;

    > i {
      font-style: normal;
      display: inline-block;
    }

    b {
      font-style: italic;
      font-weight: @font-weight-serif-heavy;
    }

    @media (min-width: @screen-sm-min) {
      font-size: 36px;
      margin: 0;
    }
    @media (min-width: @screen-lg-min) {
      font-size: 40px;
    }
    @media (min-width: @screen-xl-min) {
      font-size: 56px;
    }
  }

  .hero-note {
    font-size: 14px;
    color: @brand-complement-light;
    line-height: 1.777;

    i {
      font-style: normal;
    }

    @media (min-width: @screen-sm-min) {
      font-size: 16px;
    }

    @media (min-width: @screen-md-min) {
      i {
        display: inline-block;
      }
    }
  }

  .hero-cta {
    padding: 0 20px;
    @media (min-width: @screen-sm-min) {
      padding: 0;
    }
  }
}
