zoomable-image {
  position: relative;
  display: block;
  .zoomable-image-controls {
    position: absolute;
    display: flex;
    padding: 2px;
    top: 0;
    left: 0;
    opacity: 0;
    transition: all 0.15s ease-in-out;

    button {
      padding: 0;
      width: 30px;
      line-height: 28px;
      text-align: center;
      border-radius: @border-radius-base;

      &:before {
        margin: 0;
      }

      + button {
        margin-left: -1px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
      &:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
  &:hover {
    .zoomable-image-controls {
      opacity: 1;
    }
  }

  &.enabled {
    transition: all 0.15s ease-in-out;
    .zoomable-image-controls {
      z-index: 2;
    }
    .zoomable-image-scrollbox {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      overflow: hidden;
      transition: all 0.15s ease-in-out;
      text-align: center;
      border: 1px solid transparent;

      img {
        position: relative;
        transition: all 0.15s ease-in-out;
      }
    }
  }

  &.zoomed {
    .zoomable-image-scrollbox {
      .overflow-auto();
    }
  }

  &.expanded {
    .zoomable-image-scrollbox {
      border-color: @border-color;
      border-radius: 2px;
      background: @body-bg;
      box-shadow: @dropdown-box-shadow;
    }
  }
}
