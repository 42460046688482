consent-cookie-popup {
  display: block;
  position: fixed;
  bottom: 0;
  left: 20px;
  right: 20px;
  padding: 20px;
  background: @brand-complement-dark;
  border-radius: @border-radius-large @border-radius-large 0 0;
  box-shadow: 0 0 15px fade(@brand-complement-dark, 13%);
  text-align: center;
  z-index: @zindex-modal + 1000;

  .ngif-drop-in(@distance: -100%, @time: 0.2s, @fade-opacity: 1);

  .help-block {
    color: @white;
    max-width: 500px;
    margin: 0 auto;
  }

  .buttons > button {
    min-width: 100px;
  }

  @media (min-width: @screen-md-min) {
    right: auto;
    width: 350px;

    .buttons > button {
      min-width: 200px;
    }
  }
}
