article.simple {
  margin: 0 auto;
  padding: 20px 20px 60px;
  max-width: 45em;

  font-size: @font-size-med;

  section {
    position: relative;
    margin-bottom: 2em;

    &:target {
      @section-target-color: fade(@brand-primary-darker, 5%);
      background-color: @section-target-color;
      box-shadow: 0 0 0 10px @section-target-color;
      border-radius: @border-radius-base;
      .icon-chevron-right();
      &:before {
        position: absolute;
        font-size: 2em;
        left: -1.25em;
        top: 50%;
        transform: translateY(-50%);
        color: fade(@brand-primary-dark, 50%);
      }
    }
  }

  &.notification {
    text-align: center;
  }

  .note {
    color: @text-muted;
  }

  .toc {
    margin: 0;
    padding: 0;
    @media (min-width: @screen-sm-min) {
      padding-left: 5px;
    }
    > .section {
      margin-top: 10px;
      color: @brand-gray-dark;
      list-style: none;
      ul {
        &:extend(ul.bullets all);
        margin-left: 10px;
        a {
          color: @text-color;
          &:hover,
          &:focus {
            color: @link-hover-color;
          }
        }
      }
    }
    a {
      text-decoration: none;
      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }

  h1 {
    margin-bottom: 20px;
  }

  h2 {
    margin-top: 40px;
  }
  h2:first-of-type {
    margin-top: 20px;
  }

  h3 {
    position: relative;
    font-weight: normal;
  }

  h4 {
    font-weight: normal;
    font-style: italic;
  }

  p,
  .bullets li {
    font-weight: @font-weight-light;
  }
}
