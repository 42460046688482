// runtime: 4.25
.custom-assessments.is-visible svg {
  svg {
    overflow: visible;
  }
  #add-button {
    opacity: 1;
    animation: custom-assessments-is-visible-add-button 0.5s 0.4s 1 linear
      forwards;
  }
  #challenge-4 {
    opacity: 0;
    animation: custom-assessments-is-visible-challenge4 2.6s 0.6s 1 linear
      forwards;
  }
  #challenge-2,
  #challenge-3 {
    transform: translateY(0);
    animation: custom-assessments-is-visible-challenge-sort 0.4s 2s 1
      ease-in-out forwards;
  }
  #challenge-2 {
    animation-delay: 2.6s;
  }
  #challenge-4-javascript,
  #challenge-4-ruby,
  #challenge-2-python,
  #challenge-3-ruby {
    animation: custom-assessments-is-visible-challenge-lang 0.05s 3.6s 1
      ease-in-out forwards;
  }
  #challenge-4-ruby {
    animation-delay: 3.8s;
  }
  #challenge-2-python {
    animation-delay: 4s;
  }
  #challenge-3-ruby {
    animation-delay: 4.2s;
  }
}

@keyframes custom-assessments-is-visible-add-button {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@keyframes custom-assessments-is-visible-challenge4 {
  0% {
    opacity: 0;
    transform: translateY(5px);
    animation-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  15%,
  53% {
    opacity: 1;
    transform: translateY(0);
  }
  54% {
    transform: translate(0);
    animation-timing-function: ease-in-out;
  }
  69%,
  77% {
    transform: translate(0, -31px);
    animation-timing-function: ease-in-out;
  }
  92%,
  96% {
    transform: translate(0, -62px);
    animation-timing-function: ease-in-out;
  }
  100% {
    opacity: 1;
    transform: translate(0, -62px);
    animation-timing-function: ease-in-out;
  }
}

@keyframes custom-assessments-is-visible-challenge-sort {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(31px);
  }
}

@keyframes custom-assessments-is-visible-challenge-lang {
  0% {
    fill: #4bb05d;
  }
  100% {
    fill: #cbcbcb;
  }
}
