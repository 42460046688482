.app-table {
  &:extend(.table all);
  &:not(.card-table) {
    &:extend(.table-hover all);
  }

  &:not(.no-animate) tr {
    transition: all 0.2s ease-in-out;
    .ng-repeat-changes();
  }

  thead {
    background-color: @body-bg;

    &.sticky-header {
      transition: box-shadow 0.2s ease-in-out;
    }

    &.sticky-header-active {
      background-color: fade(@body-bg, 95%);
      box-shadow: 0 3px 3px -3px rgba(0, 0, 0, 0.1);
    }
  }

  td:not(.options):not(.buttons) > a {
    display: block;
    margin: -@table-cell-padding;
    padding: @table-cell-padding;
  }

  &.table-condensed {
    td:not(.options):not(.buttons) > a {
      margin: -@table-condensed-cell-padding;
      padding: @table-condensed-cell-padding;
    }
  }

  td:not(.options):not(.buttons) > a,
  td a.table-link {
    color: @text-color;

    &:hover,
    &.hover {
      color: @brand-primary-darker;
      text-decoration: none;
    }
  }

  .small-header() {
    font-size: 0.8em;
  }

  .number() {
    text-align: right;
  }
  .big-number() {
    font-size: 1.4em;
    min-width: 3em;
  }

  > tbody > tr > td {
    vertical-align: middle;
    color: @text-muted;
  }

  tbody .bulk-selector,
  thead .bulk-selector,
  tfoot .bulk-selector,
  tbody .status,
  thead .status {
    color: @text-color;
    padding-right: 0;
    font-size: 1.2rem;
  }

  thead.sticky-header .bulk-selector,
  thead.sticky-header .status {
    transition: border-bottom-color 0.3s ease-in-out;
  }

  tbody .bulk-selector,
  thead:not(.sticky-header-active) .bulk-selector,
  tfoot .bulk-selector,
  tbody .status,
  thead:not(.sticky-header-active) .status {
    border-top-color: transparent;
    border-bottom-color: transparent;
  }

  .bulk-selector {
    width: 18px;
    padding-left: 0;

    .qualified-checkbox > label {
      display: block;
      margin: -14px 0 -8px 0;
      min-width: 18px;
      height: 40px;

      &:before {
        top: 15px !important;
      }

      &:after {
        top: 24px !important;
      }
    }
  }

  thead .bulk-selector {
    .qualified-checkbox > label {
      &:before {
        top: auto !important;
        bottom: 12px;
      }

      &:after {
        top: auto !important;
        bottom: 7px;
      }
    }
  }

  tfoot .bulk-selector {
    .qualified-checkbox > label {
      height: 50px;

      &:before {
        top: 23px !important;
      }

      &:after {
        top: 32px !important;
      }
    }
  }

  tbody .status,
  thead .status {
    > a {
      padding-right: 0;
      margin-right: 0;
    }
  }

  .inline-dropdown(@classname) {
    td.@{classname} {
      white-space: nowrap;
      padding-top: 7px;
      padding-bottom: 7px;

      .dropdown-toggle {
        color: @text-muted;
        cursor: pointer;
        display: block;
        border: 1px solid transparent;
        border-radius: @border-radius-base;
        padding: @padding-base-vertical @padding-base-horizontal + 14px
          @padding-base-vertical @padding-base-horizontal;

        &:hover,
        &:focus {
          text-decoration: none;
        }

        .caret {
          visibility: hidden;
          position: absolute;
          right: @padding-base-horizontal;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
    tr:hover,
    tr.multirow-hover {
      td.@{classname} .dropdown-toggle,
      .@{classname} .dropdown-toggle:focus {
        border-color: @text-muted;
        color: @link-color;

        .caret {
          visibility: visible;
        }
      }
    }
  }

  // lighter styling on inline-selects
  tr .form-control-select {
    transition: none;
  }

  tr:not(:hover):not(.multirow-hover)
    *:not(.has-error):not(.has-warning)
    .form-control-select:not(.focused):not(.always-visible) {
    border-color: transparent;
    background: none;
    box-shadow: none;

    select {
      box-shadow: none;
    }

    &:after {
      opacity: 0.5;
      border-color: transparent;
      background: none;
    }
  }

  .options {
    opacity: 0;
    transition: 0.2s opacity ease-in-out;

    a:before {
      padding-right: 4px;
    }

    @media (max-width: @screen-xs-max) {
      display: none;
    }
  }

  tr:hover,
  tr.multirow-hover {
    .options {
      opacity: 1;
    }
  }

  tr.moved {
    opacity: 0.5;

    &:hover,
    &.multirow-hover {
      opacity: 0.8;
    }
  }

  &:not(.card-table) > tbody > tr.multirow-hover {
    background-color: @table-bg-hover;
  }

  &:not(.card-table) > tbody > tr.active.multirow-hover > td {
    background-color: darken(@table-bg-active, 5%);
  }
}

.app-table-empty-notice {
  max-width: 120rem;
  margin: 0 auto;

  @media only screen and (max-width: @screen-xs-max) {
    padding: 0 1rem;
  }
}

.pagination.table-pagination {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;

  > * {
    flex: 0 0 auto;
  }

  @media only screen and (min-width: @screen-sm-min) {
    justify-content: flex-end;
  }
}
