.legal-links {
  padding: 0.5rem 1rem;
  font-weight: 300;
  text-align: center;
  ul,
  li {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    display: inline-block;

    a {
      .fancy-underline-link();
    }

    a:before {
      margin-right: 0.25em;
      display: inline-block;
      transform: translateY(1px);
    }
  }

  li + li {
    margin-left: 2rem;
  }
}
