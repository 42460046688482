.navbar-default {
  border-bottom: 1px solid @border-color;

  .navbar-brand {
    // remove forced heights and lower padding
    height: auto;
    padding-bottom: 0;
  }

  .navbar-nav > li {
    &:not(.no-underline) {
      .fancy-underline-link-nav();
    }
    > a {
      font-weight: 300;
      margin: 0 7px;
      padding-left: 3px;
      padding-right: 3px;
      padding-bottom: 3px;
      .no-focus-border();
    }
    &.navbar-text {
      margin-bottom: 0;
    }

    &.account {
      > a {
        cursor: pointer;
        display: flex;
        align-items: center;
        height: 35px; // 20px line height + padding
        overflow: visible;
        > * {
          flex: 0 1 auto;
        }
      }
      .caret {
        margin-left: 6px;
      }
      .profile-image {
        box-shadow: 0 0 0 2px @body-bg;
        z-index: 1;
        &:after {
          content: ' ';
        }
      }
    }
  }
}

.navbar-light {
  .navbar-text {
    color: @text-color;
  }
  .navbar-nav > li > a {
    color: @text-color;
    &:hover,
    &:focus {
      background-color: @darken;
      color: @link-color;
    }
  }
}

.subnav {
  z-index: @zindex-navbar-fixed - 1;
  position: fixed;
  top: @navbar-height;
  width: 100%;
  overflow: hidden;
  border: none;
  border-radius: 0;
  height: 0;
  min-height: 0;
  transition: 0.2s all ease-in-out;
  .has-subnav & {
    height: @subnavbar-height;
    min-height: @subnavbar-height;
  }

  &:extend(.navbar all);
  background-color: @subnavbar-bg;
  color: @subnavbar-color;
  margin-bottom: 0;

  .breadcrumb {
    padding: 0;
    margin: 0;
    background: transparent;
    font-size: 12px;
    letter-spacing: 1px;
    a {
      color: @gray;
      margin-right: 5px;
    }
  }

  .btn-sm {
    margin-top: 2px;
  }

  form.navbar-right {
    margin-right: -2.5rem;
  }
}

.has-subnav .navbar {
  margin-bottom: 0;
}

.nav-tabs {
  > li {
    .ng-repeat-changes();
  }
  > li > a {
    .no-focus-border();

    color: @brand-dark;

    &:hover,
    &:focus {
      background-color: @border-color;
    }
  }

  > li > .text {
    &:extend(.nav > li > a);
    &:extend(.nav-tabs > li > a);
  }

  > li > a span:before {
    margin-right: 5px;
  }
  > li > a span.loading-spinner {
    margin-right: 5px;
    &:before {
      margin-right: 0;
    }
  }

  > li.control-tab {
    padding: @nav-link-padding;
    padding-top: 4px;
    padding-bottom: 0;
  }
}

.navbar-toggle {
  > .icon-bar {
    background-color: @brand-light-dark;
    transition: all 0.25s ease;
  }
}

.show-sidebar .navbar-toggle {
  > .icon-bar {
    // there's a hidden text that bumps these to 2, 3, and 4
    &:nth-child(2) {
      transform: rotate(45deg) translate(4px, 4px);
    }
    &:nth-child(3) {
      transform: rotate(-45deg);
    }
    &:nth-child(4) {
      transform: rotate(45deg) translate(-4px, -4px);
      opacity: 0;
    }
  }
}
