.modal {
  // Needed to get ngAnimate to work
  transition: all 0.3s ease-out;

  &.fade .modal-dialog {
    transform-origin: 50% 0;
    transform: scale(0.9);
    opacity: 0;
    transition: all 0.15s ease;
  }

  &.in .modal-dialog {
    transform: scale(1);
    opacity: 1;
  }
}

.modal-header {
  display: flex;
  align-items: center;

  background: @brand-light
    linear-gradient(to top, rgba(0, 0, 0, 0.05) 0, rgba(0, 0, 0, 0) 5px)
    no-repeat;
  border-bottom: none;
  border-top-left-radius: @border-radius-large;
  border-top-right-radius: @border-radius-large;

  > * {
    flex: 0 1 auto;

    + * {
      margin-left: 10px;
    }
  }

  > h3,
  > h4 {
    flex: 1 1 auto;
  }

  > h4 {
    font-weight: @font-weight-light;
    font-size: @font-size-h3;
  }

  h3 {
    margin: 15px 0 5px;
    font-weight: @font-weight-light;
    color: @text-muted;
  }
}

.modal-body {
  &.error,
  &.warning,
  &.success,
  &.modal-body-icon {
    @icon-space: (@modal-inner-padding * 2) + 60;
    padding-left: @icon-space;
    min-height: @icon-space;

    &:before {
      position: absolute;
      left: @modal-inner-padding;
      top: 50%;
      transform: translateY(-50%);
      width: 60px;
      text-align: center;
      font-size: 48px;
      color: @text-muted;
    }
  }

  &.error {
    .icon-status-stop();

    &:before {
      color: fade(@brand-danger, 50%);
    }
  }

  &.warning {
    .icon-warning();

    &:before {
      color: @brand-warning;
    }
  }

  &.success {
    .icon-status-complete();

    &:before {
      color: @brand-success-light;
    }
  }
}

.modal-footer {
  background: @brand-light
    linear-gradient(to bottom, rgba(0, 0, 0, 0.08) 0, rgba(0, 0, 0, 0) 8px)
    no-repeat;
  border-top: none;
  border-bottom-left-radius: @border-radius-large;
  border-bottom-right-radius: @border-radius-large;
}

.modal-footer {
  a,
  button,
  [type='button'],
  [type='submit'],
  [type='reset'] {
    .btn-sm();
    min-width: 8em;
    margin-left: 1em;

    &:first-child {
      margin-left: 0;
    }

    &.wizard-back-button {
      .icon-chevron-left();
      float: left;
    }

    &.dropdown-toggle-caret {
      min-width: 0;
    }
  }
}

// FIX FOR MODAL BACKDROPS
.modal-backdrop {
  bottom: 0;
  transition: opacity 0.15s ease-in-out;
}

.modal-long-form {
  padding: 0;

  section {
    padding: @modal-inner-padding;
    margin: 0;

    &:nth-child(even):not(.alert) {
      background-color: @brand-complement-bg-light;
    }

    h2 {
      margin-top: 0;
    }

    > *:last-child,
    > *:last-child > .form-group,
    > *:last-child > .form-group > *:last-child {
      margin-bottom: 0;
    }

    &.section-optional {
      transition: all 0.2s ease-in-out;

      &:not(.expanded) {
        padding-bottom: 8px;
        padding-top: 8px;
      }
    }
  }
}

modal-wizard {
  display: block;

  &.active {
    position: relative;
    overflow: hidden;
    min-height: 200px;
  }

  > * {
    .ngif-animate-all(@time: 0.2s);

    &.ng-enter {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      min-height: 100%;
      background-color: @modal-content-bg;
    }

    &.ng-enter:not(.ng-enter-active) {
      transform: translateX(100%);
    }

    &.ng-leave.ng-leave-active {
      opacity: 0;
      transform: translateX(-100%);
    }
  }

  &.reverse {
    > * {
      &.ng-enter:not(.ng-enter-active) {
        transform: translateX(-100%);
      }

      &.ng-leave.ng-leave-active {
        transform: translateX(100%);
      }
    }
  }
}

.modal-content {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  border: none;
}

.modal-fs,
.modal-centered,
.modal-left,
.modal-left-wide {
  .modal-dialog {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    pointer-events: none;
  }

  @media only screen and (min-width: @screen-sm-min) {
    .modal-dialog {
      padding: 10px;
    }
  }

  // some dialogs wrap everything in a form
  .modal-content,
  .modal-content > form {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin: 0;
    pointer-events: auto;
  }

  &.modal-fs-form {
    .modal-content,
    .modal-content > form {
      max-width: 1024px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .modal-header {
    .modal-title {
      text-align: center;
    }

    flex-grow: 0;
    flex-shrink: 0;
  }

  .modal-body {
    .overflow-auto();
    flex-grow: 1;
  }

  .modal-footer {
    flex-grow: 0;
    flex-shrink: 0;
  }
}

.modal-centered {
  .modal-dialog {
    max-width: 840px;
    margin: 0 auto;
  }
}

.modal-left,
.modal-left-wide {
  .modal-dialog {
    max-width: 700px;
    margin: 0;
    animation-name: modalLeftSlide;
    animation-duration: 0.5s;
  }
}

.modal-left-wide {
  .modal-dialog {
    max-width: 800px;
  }
}

@keyframes modalLeftSlide {
  from {
    margin-left: -600px;
    opacity: 0;
  }
  to {
    margin-left: 0;
    opacity: 1;
  }
}

.inline-modal {
  min-height: calc(100vh - 30px);
  padding: 10px 0 0;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;

  .modal-content {
    flex: 0 0 auto;
    color: @text-color;
    margin: 0;

    width: calc(100% - 1em);
    max-width: 35em;
    background-color: @brand-light;
    border-color: @gray-lighter;
  }

  .modal-header {
    display: block;
    text-align: center;
    font-size: 1.5em;

    a {
      &:hover,
      &:active,
      &:focus {
        text-decoration: none;
      }
    }

    .qualified-logo {
      font-size: 1.2em;
    }

    border-bottom-color: @gray-lighter;
  }

  .modal-footer {
    border-top-color: @gray-lighter;
    text-align: center;

    a,
    button,
    [type='button'],
    [type='submit'],
    [type='reset'] {
      min-width: 10em;
    }
  }
}

.inline-modal-footer {
  &:extend(.legal-links all);

  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: @zindex-navbar-fixed;
}

.modal-dialog.dark-bg {
  background-color: transparent;
  .modal.modal-fs & {
    background-color: rgba(0, 0, 0, 0.5);
  }
  .modal-content,
  .modal-header,
  .modal-footer {
    background-color: @dark-bg-alt-color;
  }
  .modal-header .close {
    color: @dark-text-color;
    text-shadow: 0 1px 0 #000;
  }
  .modal-body {
    background-color: @dark-bg-color;
  }
}
