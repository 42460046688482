.preview-video-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  z-index: @zindex-modal;

  &.ng-enter-prepare {
    .video {
      opacity: 0;
      transform: scale(0.3);
    }
  }

  &.ng-enter,
  &.ng-leave {
    transition: all 0.25s ease-in-out;
    .background,
    .video {
      transition: all 0.25s ease-in-out;
    }
  }

  &.ng-enter,
  &.ng-leave.ng-leave-active {
    opacity: 0.99;
    .backdrop {
      opacity: 0;
    }
    .video {
      opacity: 0;
      transform: scale(0.3);
    }
  }

  &.ng-leave,
  &.ng-enter.ng-enter-active {
    opacity: 1;
    .backdrop {
      opacity: 1;
    }
    .video {
      opacity: 1;
      transform: scale(1);
    }
  }

  .backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
  }

  .video {
    position: absolute;
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 20px;
    background: #000;
    border-radius: 10px;

    @media only screen and (min-width: 1280px) {
      top: 40px;
      left: 40px;
      right: 40px;
      bottom: 40px;
    }
  }

  .hide-button {
    cursor: default;
    display: block;
    position: absolute;
    top: -10px;
    right: -10px;
    width: 30px;
    height: 30px;
    font-size: 16px;
    line-height: 26px;
    color: @brand-accent;
    background: #000;
    border: 2px solid @brand-accent;
    border-radius: 50%;
    text-align: center;
    z-index: 100;
    .icon-x();
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
}
