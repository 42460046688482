// add a border to inline-code
code {
  border: 1px solid @border-color;
}

pre code {
  border: none;
}

// Code Highlighting
// Attempted to keep this in-sync with codemirror light theme
.hljs {
  display: block;
  overflow-x: auto;
  padding: 0.5em;
  background: @code-bg;
  color: @code-color;
  -webkit-text-size-adjust: none;
}

.hljs,
.hljs-subst {
  color: @code-color;
}

.hljs-string,
.hljs-title,
.hljs-constant,
.hljs-parent,
.hljs-tag .hljs-value,
.hljs-rule .hljs-value,
.hljs-preprocessor,
.hljs-pragma,
.hljs-name,
.haml .hljs-symbol,
.ruby .hljs-symbol,
.ruby .hljs-symbol .hljs-string,
.hljs-template_tag,
.django .hljs-variable,
.smalltalk .hljs-class,
.hljs-addition,
.hljs-flow,
.hljs-stream,
.bash .hljs-variable,
.pf .hljs-variable,
.apache .hljs-tag,
.apache .hljs-cbracket,
.tex .hljs-command,
.tex .hljs-special,
.erlang_repl .hljs-function_or_atom,
.asciidoc .hljs-header,
.markdown .hljs-header,
.coffeescript .hljs-attribute {
  color: #887836;
}

.smartquote,
.hljs-comment,
.hljs-annotation,
.diff .hljs-header,
.hljs-chunk,
.asciidoc .hljs-blockquote,
.markdown .hljs-blockquote {
  color: @text-muted;
  font-style: italic;
}

.hljs-circular {
  color: #46438f;
}

.xml .hljs-tag,
.xml .hljs-tag .hljs-title {
  color: #850921;
}

.hljs-number,
.hljs-date,
.hljs-regexp,
.hljs-literal,
.hljs-hexcolor,
.smalltalk .hljs-symbol,
.smalltalk .hljs-char,
.go .hljs-constant,
.hljs-change,
.lasso .hljs-variable,
.makefile .hljs-variable,
.asciidoc .hljs-bullet,
.markdown .hljs-bullet,
.asciidoc .hljs-link_url,
.markdown .hljs-link_url,
.xml .hljs-attribute {
  color: #7b007b;
}

.hljs-label,
.hljs-javadoc,
.ruby .hljs-string,
.hljs-decorator,
.hljs-filter .hljs-argument,
.hljs-localvars,
.hljs-array,
.hljs-attr_selector,
.hljs-important,
.hljs-pseudo,
.hljs-pi,
.haml .hljs-bullet,
.hljs-doctype,
.hljs-deletion,
.hljs-envvar,
.hljs-shebang,
.apache .hljs-sqbracket,
.nginx .hljs-built_in,
.tex .hljs-formula,
.erlang_repl .hljs-reserved,
.hljs-prompt,
.asciidoc .hljs-link_label,
.markdown .hljs-link_label,
.vhdl .hljs-attribute,
.clojure .hljs-attribute,
.asciidoc .hljs-attribute,
.lasso .hljs-attribute,
.coffeescript .hljs-property,
.hljs-phony {
  color: @text-muted;
  font-style: italic;
}

.hljs-keyword,
.hljs-id,
.hljs-built_in,
.css .hljs-tag,
.hljs-javadoctag,
.hljs-phpdoc,
.hljs-dartdoc,
.hljs-yardoctag,
.smalltalk .hljs-class,
.hljs-winutils,
.bash .hljs-variable,
.pf .hljs-variable,
.apache .hljs-tag,
.hljs-type,
.hljs-typename,
.tex .hljs-command,
.asciidoc .hljs-strong,
.markdown .hljs-strong,
.hljs-request,
.hljs-status,
.nginx .hljs-title {
  color: #850921;
}

.hljs-title {
  color: #aa6c0b;
}

.asciidoc .hljs-emphasis,
.markdown .hljs-emphasis {
  font-style: italic;
}

.nginx .hljs-built_in {
  font-weight: normal;
}

.coffeescript .javascript,
.javascript .xml,
.lasso .markup,
.tex .hljs-formula,
.xml .javascript,
.xml .vbscript,
.xml .css,
.xml .hljs-cdata {
  opacity: 0.5;
}

.dark-bg,
.ide-dark .cmw-tooltip {
  // Code Highlighting
  // Attempted to keep this in-sync with codemirror dark theme
  .hljs {
    background: @code-mirror-dark-bg;
    color: #e0e0e0;
  }

  .hljs,
  .hljs-subst {
    color: #e0e0e0;
  }

  .hljs-string,
  .hljs-title,
  .hljs-constant,
  .hljs-parent,
  .hljs-tag .hljs-value,
  .hljs-rule .hljs-value,
  .hljs-preprocessor,
  .hljs-pragma,
  .hljs-name,
  .haml .hljs-symbol,
  .ruby .hljs-symbol,
  .ruby .hljs-symbol .hljs-string,
  .hljs-template_tag,
  .django .hljs-variable,
  .smalltalk .hljs-class,
  .hljs-addition,
  .hljs-flow,
  .hljs-stream,
  .bash .hljs-variable,
  .pf .hljs-variable,
  .apache .hljs-tag,
  .apache .hljs-cbracket,
  .tex .hljs-command,
  .tex .hljs-special,
  .erlang_repl .hljs-function_or_atom,
  .asciidoc .hljs-header,
  .markdown .hljs-header,
  .coffeescript .hljs-attribute {
    color: #f4bf75;
  }

  .smartquote,
  .hljs-comment,
  .hljs-annotation,
  .diff .hljs-header,
  .hljs-chunk,
  .asciidoc .hljs-blockquote,
  .markdown .hljs-blockquote {
    color: #888;
  }

  .hljs-circular {
    color: #797ade;
  }

  .hljs-number,
  .hljs-date,
  .hljs-regexp,
  .hljs-literal,
  .hljs-hexcolor,
  .smalltalk .hljs-symbol,
  .smalltalk .hljs-char,
  .go .hljs-constant,
  .hljs-change,
  .lasso .hljs-variable,
  .makefile .hljs-variable,
  .asciidoc .hljs-bullet,
  .markdown .hljs-bullet,
  .asciidoc .hljs-link_url,
  .markdown .hljs-link_url,
  .xml .hljs-attribute {
    color: #aa759f;
  }

  .hljs-label,
  .hljs-javadoc,
  .ruby .hljs-string,
  .hljs-decorator,
  .hljs-filter .hljs-argument,
  .hljs-localvars,
  .hljs-array,
  .hljs-attr_selector,
  .hljs-important,
  .hljs-pseudo,
  .hljs-pi,
  .haml .hljs-bullet,
  .hljs-doctype,
  .hljs-deletion,
  .hljs-envvar,
  .hljs-shebang,
  .apache .hljs-sqbracket,
  .nginx .hljs-built_in,
  .tex .hljs-formula,
  .erlang_repl .hljs-reserved,
  .hljs-prompt,
  .asciidoc .hljs-link_label,
  .markdown .hljs-link_label,
  .vhdl .hljs-attribute,
  .clojure .hljs-attribute,
  .asciidoc .hljs-attribute,
  .lasso .hljs-attribute,
  .coffeescript .hljs-property,
  .hljs-phony {
    color: #888;
  }

  .hljs-keyword,
  .hljs-id,
  .hljs-built_in,
  .css .hljs-tag,
  .hljs-javadoctag,
  .hljs-phpdoc,
  .hljs-dartdoc,
  .hljs-yardoctag,
  .smalltalk .hljs-class,
  .hljs-winutils,
  .bash .hljs-variable,
  .pf .hljs-variable,
  .apache .hljs-tag,
  .hljs-type,
  .hljs-typename,
  .tex .hljs-command,
  .asciidoc .hljs-strong,
  .markdown .hljs-strong,
  .hljs-request,
  .hljs-status,
  .nginx .hljs-title {
    color: #ad6260;
  }

  .hljs-title {
    color: #d28445;
  }

  .xml .hljs-tag,
  .xml .hljs-tag .hljs-title {
    color: #ac4142;
  }
}
