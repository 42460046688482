framework-demos {
  display: block;

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
    margin: 10px -10px 60px;

    li {
      display: flex;
      flex: 0 1 auto;
      list-style: none;
      margin: 10px;

      a {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 80px;
        min-height: 80px;
        padding: 10px 6px;
        background: @white;
        border-radius: 3px;
        box-shadow: 0 10px 20px fade(@brand-complement-dark, 13%);
        pointer-events: none;
        cursor: default;
        border: none;

        .language-icon {
          position: relative;
          display: block;
          flex: 0 1 32px;
          min-height: 32px;
          font-size: 32px;
          line-height: 32px;
          background-size: contain;
          background-position: 50% 50%;
          background-repeat: no-repeat;
          color: @brand-primary-dark;
        }

        .title {
          position: relative;
          display: block;
          flex: 1 1 auto;
          font-size: 12px;
          color: @text-color;
          margin-top: 10px;
        }

        &:hover,
        &:focus,
        &:active {
          text-decoration: none;
        }
      }
    }
  }

  @media (min-width: @screen-sm-min) {
    ul {
      margin: 20px auto 90px;
      max-width: 1100px; // try to force a nicer looking wrap

      li {
        margin: 20px;
        width: 128px;
        height: 128px;

        a {
          width: 96px;
          min-height: 96px;
          padding: 16px 8px;
          box-shadow: 0 15px 30px fade(@brand-complement-dark, 13%);
          pointer-events: auto;

          .language-icon {
            flex-basis: 40px;
            min-height: 40px;
            font-size: 40px;
            line-height: 40px;
          }

          .title {
            margin-top: 16px;
          }

          .has-live-demos & {
            // if we've got live demos, make them clickable
            cursor: pointer;

            &:before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              opacity: 0;
              background: @landing-diag-gradient;
              border-radius: 3px;
            }

            &:after {
              position: relative;
              content: '';
              width: 20px;
              margin: 0 auto;
              height: 0;
              opacity: 0;
              background: transparent
                data-uri('../../landing/images/demo-arrow.svg') no-repeat 50%
                50%;
              background-size: 100% auto;
            }

            &,
            .language-icon,
            .title,
            &:after,
            &:before {
              transition: all 0.15s ease-in-out;
            }

            &:hover,
            &:focus,
            &:active {
              background-color: fade(@white, 0);
              margin-bottom: -28px;
              .title {
                color: @white;
              }
              .language-icon {
                filter: grayscale(1) brightness(0%) invert(1);
              }
              &:before {
                opacity: 1;
              }
              &:after {
                opacity: 1;
                height: 12px;
                margin-top: 16px;
              }
            }
          }
        }
      }
    }
  }
  @media (min-width: @screen-md-min) {
    ul {
      margin-bottom: 120px;
      li {
        a {
          width: 128px;
          min-height: 128px;
          padding: 20px 10px;

          .title {
            margin-top: 20px;
          }
        }
      }
    }
  }
}
