ul.icon-list {
  padding: 0;
  margin: 15px auto 0;
  max-width: @content-max-width;

  > li {
    list-style: none;
    padding: 10px;
    margin: 0;
    flex: 1 1 33.33%;
    text-align: center;

    svg {
      width: 70px;
      height: auto;
      margin: 0 auto;
    }

    h2 {
      font-size: 18px;
      margin-top: 30px;
    }

    p {
      margin-top: 30px;
      color: @brand-complement-light;
      font-size: 14px;
    }
  }

  @media only screen and (min-width: @screen-sm-min) {
    display: flex;
    align-items: flex-start;
    > li {
      display: flex;
      flex-direction: column;

      > * {
        flex: 1 1 auto;
      }

      h2 {
        flex: 0 0 auto;
      }

      padding: 10px 20px;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }

      p {
        max-width: 23em;
        margin-left: auto;
        margin-right: auto;

        &.section-link {
          flex-grow: 0;
        }
      }
    }

    &.icon-list-long-titles > li > h2 {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      min-height: 2.2em;
    }
  }
  @media only screen and (min-width: @screen-md-min) {
    p + & {
      margin-top: 30px;
    }
    > li {
      h2 {
        font-size: 20px;
      }

      p {
        font-size: 16px;
      }
    }
  }
  @media only screen and (min-width: @screen-lg-min) {
    p + & {
      margin-top: 40px;
    }
  }
  @media only screen and (min-width: @screen-xl-min) {
    > li {
      svg {
        width: 90px;
      }

      h2 {
        font-size: 24px;
      }
    }
  }
}
