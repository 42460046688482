.checkable-input-set {
  padding: 1rem 0 0.3rem 1rem;
  border: 1px solid transparent;
  border-radius: @border-radius-base;
  transition:
    border 0.25s ease-in-out,
    background 0.25s ease-in-out;

  .horizontal-form-group & {
    padding-left: 0;
  }

  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: flex-start;

  > * {
    flex: 1 1 50%;
    margin: 0;
    padding: 0 1rem 0.7rem 0;

    label {
      display: block;
      margin: 0;
    }
  }

  &.one-col {
    > * {
      flex: 1 0 100%;
      padding-right: 0;
    }
  }

  &.three-col {
    @media only screen and (min-width: @screen-md-min) {
      > * {
        flex: 0 1 33%;
      }
    }
  }

  &.four-col {
    @media only screen and (min-width: @screen-md-min) {
      > * {
        flex: 0 1 25%;
      }
    }
  }

  &.horizontal {
    > * {
      flex: 0 0 auto;
      margin-right: 1rem;
    }
  }

  .horizontal-form-group .has-warning &,
  .horizontal-form-group.has-warning &,
  .horizontal-form-group .has-error &,
  .horizontal-form-group.has-error & {
    margin-left: -1rem;
    padding-left: 1rem;
  }

  &.horizontal-stretch {
    justify-content: flex-start;

    > * {
      flex: 1 0 auto;
      margin-right: 1rem;
    }
  }

  .has-warning & {
    border-color: @state-warning-text;
    background-color: @state-warning-bg;
  }

  .has-error & {
    border-color: @state-danger-text;
    background-color: @state-danger-bg;
  }

  .radio + .radio,
  .checkbox + .checkbox {
    margin-top: 0;
  }

  .all-toggle {
    label {
      font-weight: bold;
    }
  }
}
