@media (max-width: @screen-xs-max) {
  select,
	select:focus,
	textarea,
	input[type="text"],
	input[type="password"],
	input[type="datetime"],
	input[type="datetime-local"],
	input[type="date"],
	input[type="month"],
	input[type="time"],
	input[type="week"],
	input[type="number"],
	input[type="email"],
	input[type="url"],
	input[type="search"],
	input[type="tel"],
	input[type="color"],
		// fix for text width issues in ngTagsInput
	tags-input .input,
	.input-group-addon {
    font-size: 16px !important;
  }
}

textarea,
input[type='text'],
input[type='password'],
input[type='number'],
input[type='email'],
input[type='url'],
input[type='search'],
input[type='tel'] {
  // remove all extra browser-provided styling
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

//------------------------------------------------------------------
// hide or disable browser & last pass form fill icons
input[type='text'],
input[type='password'],
input[type='datetime'],
input[type='datetime-local'],
input[type='date'],
input[type='month'],
input[type='time'],
input[type='week'],
input[type='number'],
input[type='email'],
input[type='url'],
input[type='search'],
input[type='tel'],
input[type='color'],
tags-input .input {
  &:not(.allow-form-fills) {
    background-image: none !important;

    //noinspection CssInvalidPseudoSelector
    &::-webkit-contacts-auto-fill-button,
    &::-webkit-credentials-auto-fill-button {
      visibility: hidden !important;
      position: absolute !important;
      bottom: 0;
      right: 0;
      width: 0 !important;
      height: 0 !important;
    }
  }
}

// disables all floating last pass form & password fill icons
div[id^='__lpform'] {
  display: none !important;
  position: absolute !important;
  bottom: 0;
  right: 0;
  width: 0 !important;
  height: 0 !important;
}

//------------------------------------------------------------------

// Prevent MSIE from adding an "X" to input boxes
input[type]::-ms-clear {
  display: none;
}

.form-control[readonly] {
  color: @input-readonly-color;
  background-color: @input-readonly-bg;
  &:focus {
    border-color: @input-readonly-focus-border;
    box-shadow: @input-inset-shadow, @input-readonly-focus-shadow;
  }
}

.form-control.form-control-code {
  font-family: @font-family-monospace;
}

// used to delay the out transition for a help block
// which is useful in cases where shifting the help block moves the submit button
// (it's counter-intuitive but the has-focus class is on during the in transition)
.form-group:not(.has-focus) {
  .help-block.delay-out-transition {
    transition-delay: 0.5s;
  }
}

.help-block {
  color: @text-muted;
  transition: 0.25s ease-in-out all;
  font-weight: @font-weight-light;

  &.help-block-inline {
    display: inline;
    margin: 0;
  }

  &.msg-shouldhide,
  &.ng-inactive,
  &.focused-help-block,
  &.ng-hide-remove,
  &.ng-hide-add.ng-hide-add-active,
  .has-focus.has-error &.focused-help-block.hide-on-error {
    margin-top: 0;
    margin-bottom: 0;
    overflow: hidden;
    line-height: 0;
    opacity: 0;
  }

  &.msg-shouldshow.ng-active,
  .has-focus &.focused-help-block,
  &.ng-hide-add,
  &.ng-hide-remove.ng-hide-remove-active {
    margin-top: 5px;
    margin-bottom: 10px;
    line-height: @line-height-base;
    opacity: 1;
  }
}

label {
  color: @headings-color;
}

label[disabled] {
  color: fade(@text-color, 50%);
}

// enables [enter] to submit a form without a visible submit button.
.hidden-submit {
  display: block;
  position: absolute;
  top: -99999px;
  left: -99999px;
}

form .form-buttons {
  > * {
    min-width: 10em;
  }
}

.form-group:not(.has-error):not(.has-warning) {
  .form-control.ng-invalid-required,
  .input-group.ng-invalid-required
    .input-group-addon:not(.btn-default):not(.btn-primary):not(.btn-danger) {
    border-color: @input-border-required;
  }
}

.horizontal-form-group {
  &.form-group,
  .form-group {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    > * {
      flex: 0 1 auto;
    }

    > label {
      margin-right: 1em;
      margin-bottom: 0;
    }

    > .help-block {
      flex: 1 0 100%;
    }

    // webkit/chrome won't correctly shrink tables without the flex-basis being 1%.
    > .input-range {
      flex: 1 1 1%;
    }
  }
}

.qualified-form-horizontal {
  .form-group {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    > * {
      flex: 1 1 auto;
      width: auto;
      max-width: calc(100% - 160px);
    }

    > label {
      flex: 0 1 150px;
      text-align: right;
      margin-right: 10px;
    }

    .icon-help {
      flex: 0 1 auto;
      margin-left: 10px;
    }

    .help-block {
      flex: 1 1 100%;
      margin-left: 160px;
    }
  }
}

// override error colors when a form field is actually value
// (handles multiple-field situations)
.has-error .form-control.ng-valid:not(.always-mark-error) {
  border-color: @input-border;
}

form > h3,
form *:not(.markdown) > h3 {
  color: @legend-color;
  padding: 0.75em 0 0.25em;
  border-bottom: 1px solid @legend-border-color;
}

.form-control-feedback > .loading-spinner {
  position: absolute;
  opacity: 0.5;
  line-height: 1;
  top: calc(50% + 2px);
  left: 50%;
  transform: translate(-50%, -50%);
}

@import './forms/input-group-inlined';
@import './forms/form-control-select';
@import './forms/input-range';
@import './forms/qualified-checkbox';
@import './forms/qualified-radio';
@import './forms/checkable-input-set';
