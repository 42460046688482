::selection {
  background-color: @text-select-bg;
  color: @text-select-fg;
  text-shadow: none !important;
}

::-moz-selection {
  background-color: @text-select-bg;
  color: @text-select-fg;
  text-shadow: none !important;
}

.shortcut-hint {
  display: inline-block;
  font-size: 11px;
  color: @white;
  background-color: @text-muted;
  border-radius: @border-radius-small;
  padding: 0 3px;
  line-height: 15px;
  overflow: hidden;
  vertical-align: middle;
  margin-left: 4px;

  .dark-bg & {
    background-color: @brand-gray-dark;
    color: #000;
  }
}
.btn .shortcut-hint {
  margin-top: -1px;
}

[disabled],
.disabled {
  .shortcut-hint {
    color: inherit;
    background: transparent;
    opacity: 0.5;
  }
}

.btn-primary .shortcut-hint {
  background-color: darken(@btn-primary-bg, 20%);
  color: @white;
}

.btn-info .shortcut-hint {
  background-color: @btn-info-border;
}

.btn-warning .shortcut-hint {
  background-color: @btn-warning-border;
}

.btn-danger .shortcut-hint {
  background-color: @btn-default-border;
}
