// runtime: 3.8
.take-assessment.is-visible svg {
  svg:not(#code) {
    overflow: visible;
  }
  #btn {
    opacity: 0.3;
    animation: take-assessment-is-visible-button 2.8s 1s 1 linear normal
      forwards;
  }
  #spinner {
    opacity: 0;
    transform-origin: 323px 116px;
    animation: take-assessment-is-visible-spinner 1.5s 0s 1 linear normal
      forwards;
  }
  #results-0 > g,
  #results-1 > g,
  #results-2 > g,
  #results-3 > g,
  #results-4 > g,
  #results-5 > g {
    opacity: 0;
    animation: take-assessment-is-visible-results 0.4s 0s 1
      cubic-bezier(0.68, -0.55, 0.265, 1.55) normal forwards;
  }
  #results-0 > g {
    animation-delay: 1.5s;
  }
  #results-1 > g {
    animation-delay: 1.8s;
  }
  #results-2 > g {
    animation-delay: 2.1s;
  }
  #results-3 > g {
    animation-delay: 2.4s;
  }
  #results-4 > g {
    animation-delay: 2.7s;
  }
  #results-5 > g {
    animation-delay: 3s;
  }
}

@keyframes take-assessment-is-visible-results {
  0% {
    opacity: 0;
    transform: translateY(-5px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes take-assessment-is-visible-spinner {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
    transform: rotate(0);
  }
  90% {
    opacity: 1;
    transform: rotate(540deg);
  }
  100% {
    opacity: 0;
    transform: rotate(540deg);
  }
}

@keyframes take-assessment-is-visible-button {
  0%,
  95% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
