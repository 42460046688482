.app-reset-page {
  .cleared-content,
  .reset-button.clearing {
    display: none;
  }

  .reset-error-notice {
    max-height: 0;
    overflow: hidden;
    transition: all 0.2s ease-in-out;
  }

  .reset-button {
    margin: 30px 0;
    text-align: center;

    button {
      min-width: 60%;
    }
  }

  &.clearing {
    .reset-button.not-cleared {
      display: none;
    }

    .reset-button.clearing {
      display: block;
    }
  }

  &.reset-error .reset-error-notice {
    display: block;
    max-height: 100px;
  }

  &.cleared {
    .not-cleared-content > * {
      display: none;
    }

    .cleared-content {
      display: block;
    }
  }
}
