article-carousel {
  display: block;
  position: relative;

  .large-screen-articles {
    display: none;
  }

  @media (min-width: @screen-sm-min) {
    .large-screen-articles {
      display: block;
    }

    .small-screen-articles {
      display: none;
    }
  }

  .small-screen-articles {
    svg {
      max-width: 90%;
      height: auto;
    }
  }

  .article-selector {
    padding: 0;
    margin: 0 0 30px;

    > li {
      list-style: none;
      display: inline-block;
      margin: 0;

      > span {
        position: relative;
        display: inline-block;
        padding: 2px 10px;
        border-bottom: 1px solid transparent;
        color: @text-color;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &:hover,
        &:focus {
          color: @link-hover-color;
        }

        &:hover {
          border-color: fade(@link-hover-color, 50%);
        }

        > svg {
          height: 30px;
          width: auto;

          > path {
            fill: currentColor;
          }
        }
      }

      + li {
        margin-left: 40px;
      }

      &.active span {
        border-color: @brand-primary-dark;
      }
    }
  }

  .articles-wrapper {
    display: flex;
    overflow: hidden;

    > * {
      flex: 1 0 100%;
      opacity: 0;
      animation-duration: 0.4s;
      animation-fill-mode: forwards;
    }

    &:not(.ready) {
      > .active {
        order: -1;
        opacity: 1;
        transform: translateX(0);
      }
    }

    > .active {
      z-index: 1;
    }

    &.ready {
      > .active {
        order: -1;
        transform: translateX(0);
        animation-timing-function: ease-out;
        animation-name: article-carousel-enter-forwards;
      }

      > .prev {
        order: -2;
        transform: translateX(0);
        animation-timing-function: ease-in;
        animation-name: article-carousel-exit-forwards;
      }

      &.reverse {
        > .active {
          order: -2;
          transform: translateX(-100%);
          animation-timing-function: ease-out;
          animation-name: article-carousel-enter-reverse;
        }

        > .prev {
          order: -1;
          transform: translateX(-100%);
          animation-timing-function: ease-in;
          animation-name: article-carousel-exit-reverse;
        }
      }
    }
  }

  .article-link > a {
    display: block;
    color: inherit;

    h2 {
      margin-bottom: 30px;
    }

    p {
      margin-bottom: 0;
    }

    &:hover,
    &:active,
    &:focus {
      color: @link-hover-color;
      text-decoration: underline;
    }
  }
}

@keyframes article-carousel-enter-forwards {
  0% {
    transform: translateX(-25%);
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    transform: translateX(-100%);
    opacity: 1;
  }
}

@keyframes article-carousel-exit-forwards {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    transform: translateX(-75%);
    opacity: 0;
  }
}

@keyframes article-carousel-enter-reverse {
  0% {
    transform: translateX(-75%);
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes article-carousel-exit-reverse {
  0% {
    transform: translateX(-100%);
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    transform: translateX(-25%);
    opacity: 0;
  }
}

@keyframes article-carousel-arrow-next {
  0% {
    transform: translate(0, -50%);
  }
  100% {
    transform: translate(10%, -50%);
  }
}

@keyframes article-carousel-arrow-prev {
  0% {
    transform: translate(0, -50%);
  }
  100% {
    transform: translate(-10%, -50%);
  }
}
