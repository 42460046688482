@import './qualified-input';

// Usage:
// <div class="qualified-checkbox">
//     <input type="checkbox" id="my-checkbox" name="my-checkbox" value="a" checked>
//     <label for="my-checkbox">Value A</label>
// </div>
.qualified-checkbox {
  .qualified-input(checkbox, checkmark);

  // if the input gets a class of "indeterminate", we'll change the icon to minus
  input[type='checkbox'].indeterminate + label {
    .icon-minus();
  }

  // Use the same as above, but instead styled as a switch
  &.qualified-checkbox-switch {
    input[type='checkbox'] + label {
      padding-left: 50px;

      &:before {
        display: block;
        position: absolute;
        content: '';
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 40px;
        height: 20px;
        border-radius: 10px;
        background-color: @white;
        border: 1px solid @input-border;
        transition: all 0.1s ease-in-out;
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        left: 3px;
        top: 50%;
        transform: translateY(-50%);
        width: 14px;
        height: 14px;
        border-radius: 10px;
        background-color: @white;
        box-shadow: 0 1.5px 3px rgba(0, 0, 0, 0.5);
        transition: all 0.1s ease-in-out;
      }
    }

    input[type='checkbox']:checked + label:after {
      transform: translateY(-50%) translateX(20px);
    }

    &.qualified-checkbox-switch-sm {
      input[type='checkbox'] + label {
        padding-left: 40px;

        &:before {
          width: 32px;
          height: 16px;
          border-radius: 8px;
        }

        &:after {
          left: 3px;
          width: 10px;
          height: 10px;
          border-radius: 8px;
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
        }
      }

      input[type='checkbox']:checked + label:after {
        transform: translateY(-50%) translateX(16px);
      }
    }

    input[type='checkbox'][disabled] + label {
      &:before {
        opacity: 0.4;
      }

      &:after {
        opacity: 0.7;
        box-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
      }
    }

    &.qualified-checkbox-switch-onoff {
      input[type='checkbox'] + label:before {
        background-color: @white;
      }

      input[type='checkbox']:checked + label:before {
        background-color: @brand-success;
        border-color: @brand-gray;
      }
    }

    &.qualified-checkbox-switch-offon {
      input[type='checkbox'] + label:before {
        background-color: @brand-success;
        border-color: @brand-gray;
      }

      input[type='checkbox']:checked + label:before {
        background-color: @white;
      }
    }

    &.qualified-checkbox-switch-danger {
      &.qualified-checkbox-switch-onoff
        input[type='checkbox']:checked
        + label:before {
        background-color: @brand-danger;
        border-color: darken(@brand-danger, 20%);
      }

      &.qualified-checkbox-switch-offon
        input[type='checkbox']:not(:checked)
        + label:before {
        background-color: @brand-danger;
        border-color: darken(@brand-danger, 20%);
      }
    }

    // nested help blocks are offset to wrap nicely under the switch
    .help-block {
      padding-left: 50px;
    }
    .alert {
      .slide-in-no-fade(@max-height: 100px);
      margin-left: 50px;
    }
  }
}
