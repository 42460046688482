footer {
  position: relative;
  margin-top: 0;
  padding: @screen-sm-min * 0.12 0 40px;
  background: transparent data-uri('../../landing/images/footer-bg.svg')
    no-repeat center top;
  background-size: @screen-sm-min auto;
  color: @white;
  z-index: @zindex-navbar-fixed + 1;

  &:extend(.legal-links all);

  @media (min-width: @screen-sm-min) {
    margin-top: -8vw;
    padding: 12vw 0 40px;
    background-size: 100% auto;
  }

  @media (min-width: @screen-xl-min) {
    padding-top: 170px;
  }

  > div {
    max-width: @content-max-width;
    margin: 0 auto;
    text-align: center;
    padding: 0 10px;

    @media (min-width: @screen-sm-min) {
      padding: 0 40px;
    }
  }

  a:not(.btn-cta) {
    color: @white;
    opacity: 0.7;
    transition: all 0.15s ease-in-out;

    &:hover,
    &:active,
    &:focus {
      color: @white;
      opacity: 1;
    }
  }

  #end-cta {
    margin-bottom: 60px;

    @media (min-width: @screen-lg-min) {
      margin-bottom: 80px;

      .btn-cta {
        min-width: 300px;
      }
    }
    @media (min-width: @screen-xl-min) {
      margin-bottom: 100px;
    }

    > * {
      margin-bottom: 40px;
    }

    h1 {
      color: @white;
      margin-top: 40px;
      @media (min-width: @screen-lg-min) {
        margin-top: 60px;
      }
    }

    .support-link {
      font-weight: @font-weight-heavy;
      @media (min-width: @screen-sm-min) {
        display: block;
      }

      a {
        opacity: 1;
      }
    }
  }

  .footer-site-links {
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    justify-content: center;
    margin: 0 auto;
    max-width: 400px;

    > * {
      flex: 1 1 50%;
      padding: 0 10px 20px 0;
      margin: 0;
    }

    @media (min-width: 700px) {
      max-width: none;
      justify-content: space-between;
      > * {
        flex: 1 0 25%;
      }
    }

    a:after {
      background-color: @white;
    }

    li {
      display: block;
      margin: 0;
      padding: 0.25px 0;

      + li {
        margin-top: 4px;
      }
    }

    li > strong {
      display: block;
    }

    li + li > strong {
      margin-top: 10px;
    }
  }

  .logo-links {
    text-align: left;
    max-width: 400px;
    margin: 40px auto 0;
    .qualified-logo-white {
      height: 3em;
      width: 8em;
    }

    > * {
      margin-top: 20px;
    }

    .partnership {
      color: @white;
      a > img {
        filter: grayscale(1) brightness(1000%);
        transition: all 0.15s ease-in-out;
      }
      a {
        &:hover,
        &:focus,
        &:active {
          > img {
            filter: grayscale(0) brightness(100%);
          }
        }
      }
    }

    @media (min-width: 700px) and (max-width: @screen-md-max) {
      max-width: none;
      margin: 40px 0 0;
      display: flex;
      flex-wrap: wrap;
      > * {
        flex: 1 1 75%;
      }

      .partnership {
        order: 3;
      }

      .copyright {
        flex: 1 1 25%;
      }
    }
  }

  @media (min-width: @screen-lg-min) {
    .footer-links-wrapper {
      display: flex;
      justify-content: space-between;

      .footer-site-links {
        flex: 1 1 80%;
        order: 2;
      }

      .logo-links {
        flex: 1 1 20%;
        order: 1;
        margin-top: 0;

        > *:first-child {
          margin-top: 0;
        }

        .partnership ul {
          display: flex;
          margin: 6px 0 0;
        }

        .copyright {
          margin-top: 40px;
        }
      }
    }
  }
}
