.btn-default,
.btn-primary,
.btn-success,
.btn-info,
.btn-warning,
.btn-danger,
.btn-light {
  &:extend(.btn all);

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &:active {
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.15);
      border-color: transparent;
      color: rgba(0, 0, 0, 0.25);
    }
  }
}

// overriding so we can create pill buttons rather than rounded rects
.button-size(@padding-vertical; @padding-horizontal; @font-size; @line-height; @border-radius) {
  border-radius: ((@line-height * @font-size) / 2 + @padding-vertical + 1);
}

.btn-hover-only:not(:hover):not(:focus):not([disabled]) {
  color: @text-color;
  border-color: transparent;
  background-color: transparent;

  .dark-bg & {
    color: @brand-accent;
  }
}

.btn-plain-text {
  text-transform: none !important;
}

.btn-primary-alt {
  border-color: @brand-primary;
  color: @brand-primary-darker;
  background-color: @brand-light;

  &:focus,
  &:hover {
    color: @brand-primary-darker;
    background-color: @brand-light-dark;
  }
}

.btn-light {
  .button-variant(@text-color, @white, @brand-complement-mlight);
}

.btn-default:hover {
  border-color: transparent;
}

.btn-group .btn-default {
  &:hover,
  &:focus {
    border-color: #b9b9b9;
  }

  &.active {
    &:hover,
    &:focus {
      border-color: #b9b9b9;
    }
  }
  &:focus {
    box-shadow: @input-focus-shadow;
  }

  &.active:focus {
    box-shadow:
      inset 0 3px 5px rgba(0, 0, 0, 0.125),
      @input-focus-shadow;
  }
}

// custom vertical group that works with round buttons
.btn-vertical-group {
  display: flex;
  flex-direction: column;
  > .btn {
    &:first-child:not(:last-child) {
      .border-bottom-radius(0);
    }
    &:last-child:not(:first-child) {
      .border-top-radius(0);
    }

    + .btn {
      margin-top: -1px;
      margin-left: 0;
    }
  }
}

.btn-sm,
.btn,
.action-links > li > a {
  &:before {
    margin-right: 5px;
    margin-left: 1px;
    // hack for alignment issues
    display: inline-block;
    font-size: 0.8em;
    transform: scale(1.25);
  }
  &:empty:before {
    margin: 0;
  }
  &.add {
    .icon-plus();
  }
  &.edit,
  &.update {
    .icon-pencil();
  }
  &.phone {
    .icon-phone();
  }
  &.remove {
    .icon-minus();
  }
  &.publish {
    .icon-broadcast();
  }
  &.manage {
    .icon-cog();
  }
  &.finished {
    .icon-checkmark();
  }
  &.delete {
    .icon-warning();
  }
  &.email {
    .icon-email();
    &:before {
      display: inline-block;
      transform: scale(1.5);
    }
  }
  &.email-inverted {
    .icon-email-inverted();
    &:before {
      display: inline-block;
      transform: scale(1.5);
    }
  }
  &.new-window,
  &.preview-link {
    .icon-new-window();
  }
  &.duplicate {
    .icon-duplicate();
  }
  &.approve,
  &.thumbs-up {
    .icon-thumbs-up();
  }
  &.reject,
  &.thumbs-down {
    .icon-thumbs-down();
  }
  &.archive {
    .icon-archive();
  }
  &.unarchive,
  &.restore {
    .icon-undo();
  }
  &.inbox {
    .icon-inbox();
  }
  &.send-email {
    .icon-send-email-after();
    &:after {
      display: inline-block;
      font-size: 0.8em;
      transform: scale(1.5);
      margin-left: 7px;
      margin-right: 2px;
    }
  }
  &.team-member-review {
    .icon-live-interview();
  }
  &.compare {
    .icon-compare();
  }
  &.embed-this {
    .icon-embed();
  }
  &.btn-more-menu {
    .icon-actions-menu();
  }

  &.download {
    .icon-export();
  }
}

.btn-group {
  .btn,
  .btn-sm {
    text-align: center;
  }
}

.btn-select {
  text-align: left;
  text-transform: none;
  position: relative;
  padding-right: 24px;
  > .caret {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.actions-bar {
  margin-bottom: 1rem;

  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  > * {
    flex: 0 0 auto;
  }

  > a,
  > button,
  > .dropdown,
  > .disabled-btn-wrapper,
  > *:not(.btn-group) > .dropdown,
  > .btn-group {
    display: inline-block;
    margin-right: 0.5rem;
    margin-top: 0.5rem;
  }

  > .btn-group {
    display: inline-flex;
    > * {
      flex: 0 1 auto;
      float: none;
    }
  }

  a:before {
    margin-right: 0.5rem;
  }
}

.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: none;
}

.disabled-btn-wrapper {
  display: inline-block;
}

.form-submit-buttons() {
  margin: 5rem auto;
  padding: 0 0.5em;

  display: flex;
  justify-content: center;
  > * {
    max-width: 25em;
    flex: 1 1 50%;
    margin: 0 1rem;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
