body.qualified-landing-page {
  section h4 {
    .small-caps-title();
    margin: 0 0 30px;
  }

  section#trusted-by {
    @media (min-width: @screen-sm-min) {
      h4 {
        text-align: left;
        max-width: none;
      }
    }

    @media (min-width: @screen-lg-min) {
      padding-left: 40px !important;
      > div {
        padding-left: 0 !important;
      }
    }

    div.testimonials {
      margin: 40px 0 20px;

      > p {
        margin-top: 10px;
      }

      @media (min-width: @screen-sm-min) {
        margin: 60px 0 40px;

        > p {
          margin-top: 16px;
        }
      }
      @media (min-width: @screen-md-min) {
        margin-top: 80px;
      }

      @media (min-width: @screen-lg-min) {
        margin-top: 100px;
      }
    }
  }

  section#features {
    .has-live-demos-note,
    .no-live-demos-note {
      display: none;
    }
    .learn-more {
      position: relative;
      top: -40px;
    }

    .has-live-demos-note {
      margin-top: 1.4em;
      color: @brand-black;
    }

    @media (min-width: @screen-sm-min) {
      .small-screen-note {
        display: none;
      }

      .no-live-demos-note {
        display: block;
      }

      .has-live-demos & {
        .has-live-demos-note {
          display: block;
        }

        .no-live-demos-note {
          display: none;
        }
      }
    }

    @media (min-width: @screen-md-min) {
      .learn-more {
        top: -60px;
      }
    }

    @media (min-width: @screen-lg-min) {
      .learn-more {
        top: -80px;
      }
    }
  }

  section#articles > div {
    @media (min-width: @screen-sm-min) {
      text-align: left;
    }

    .article-button {
      margin: 30px 0;
      > a {
        min-width: 15em;
      }
    }
  }
}
