ul.tags-group,
.test-cases-group {
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  > li {
    cursor: default;
    flex: 0 1 auto;
    font-size: 11px;
    line-height: 15px;
    margin: 0 8px 8px 0;
    list-style: none;
    display: inline-flex;
    letter-spacing: 0.2px;
    .text-overflow();
    max-width: 320px;

    &:empty {
      margin: 0;
    }
  }

  .tag-topic {
    color: @brand-dark;
    background-color: @brand-lighter;
    border: 1px solid @brand-light-dark;
  }

  .tag-subject {
    background-color: @brand-complement-bg-dark;
    border: 1px solid @brand-complement-xlight;
    color: @brand-complement-dark;
  }

  .tag-quality {
    background-color: @brand-complement-bg-dark;
    border: 1px solid @brand-complement-xlight;
    color: @brand-complement-dark;
    .icon-users();
    &:before {
      margin-right: 2px;
    }
  }

  .tag-smart-ide {
    background-color: @dark-bg-color;
    color: @dark-text-color;
    border-color: @dark-border-color;
    &:not(.no-icon) {
      .icon-lightbulb-on();
      &:before {
        display: inline-block;
        color: @brand-primary;
        margin-right: 5px;
        transform: translateY(1px) scale(1.15);
      }
    }
  }

  .tag-label,
  .tag {
    color: @brand-dark;
    background-color: @brand-light-med;
    border: 1px solid @brand-light-med;
  }
  .language {
    color: @brand-dark;
    background-color: @brand-light-med;
    border: 1px solid @brand-light-med;
    padding: 6px 14px;
    &:before {
      display: inline-block;
      padding: 0 8px 0 4px;
      transform: scale(2);
    }
  }

  .tags-group-label {
    padding-left: 0;
    padding-right: 0;
  }

  .add,
  .more-tags {
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    opacity: 0.5;
    transition: all 0.2s ease-in-out;

    &:hover,
    &:focus {
      opacity: 1;
    }
  }

  .add {
    .icon-plus();
  }

  > li .qualified-label {
    font-size: inherit;
  }
}

ul.tags-group:not(.tags-group-linked) {
  > li {
    padding: 4px 10px;
    border-radius: 20px;

    &.tags-group-label {
      padding-left: 0;
      padding-right: 0;
      border-radius: 0;
    }

    &.add,
    &.more-tags {
      padding: 4px;
    }
  }
}

ul.tags-group-linked {
  &:empty {
    display: none;
  }

  > li.tag-group {
    > a:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left-width: 0;
    }
    > a:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  > li > a {
    cursor: default;
    display: inline-block;
    padding: 4px 10px;
    border-radius: 20px;
    .text-overflow();
    max-width: 300px;

    &.tag:before,
    &.topic:before {
      display: inline-block;
      padding: 0 6px 0 0;
      transform: scale(1.6);
      color: @text-muted;
    }

    &.add,
    &.more-tags {
      padding: 4px;
    }

    &[href] {
      cursor: pointer;
    }

    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
    }

    &.removable {
      padding-right: 21px;
      position: relative;
      > .remove {
        display: block;
        position: absolute;
        right: 8px;
        top: 50%;
        color: @text-color;
        opacity: 0.5;
        transform: translateY(-50%) scale(1.5);

        &:hover,
        &:focus,
        &:active {
          opacity: 1;
          color: @text-color;
          text-decoration: none;
        }
      }
    }

    &.qualified-label {
      &:hover,
      &:focus,
      &:active {
        color: @white;
      }
    }
  }

  .topic[href] {
    &:hover,
    &:focus,
    &:active {
      color: @brand-black;
      background-color: @brand-light;
    }
  }

  .label[href],
  .tag[href] {
    &:hover,
    &:focus,
    &:active {
      color: @brand-black;
      background-color: @brand-light-darker;
    }
  }
  .language[href] {
    &:hover,
    &:focus,
    &:active {
      color: @brand-black;
      background-color: @brand-light-darker;
    }
  }
}

ul.tags-group.tags-group-linked.tags-group-sm {
  > li {
    font-size: 10px;
    line-height: 14px;
    > a {
      font-size: inherit;
      line-height: inherit;
      padding: 3px 8px;
      border-radius: 16px;
      max-width: 240px;
    }
  }
}

.test-cases-group {
  li {
    font-size: 0.9em;
    padding: 3px 6px;
    border-radius: @border-radius-large;
    color: @brand-gray-dark;
    background-color: @brand-lighter;
    border: 1px solid @brand-gray-dark;

    strong {
      font-weight: normal;
    }
    em {
      font-style: normal;
      color: @text-color;
    }

    &.hidden-tests {
      .icon-hidden();
    }
    &.tagged-tests {
      .icon-tag();
    }
    &.success {
      color: darken(@brand-success, 10%);
      background-color: lighten(@brand-success, 45%);
      border-color: @brand-success;
    }
    &.danger {
      color: darken(@brand-danger, 10%);
      background-color: lighten(@brand-danger, 45%);
      border-color: @brand-danger;
    }
    &.warning {
      color: darken(@brand-warning, 20%);
      background-color: lighten(@brand-warning, 35%);
      border-color: darken(@brand-warning, 10%);
    }
  }
}

tags-input {
  &.label-editor,
  &.topic-editor {
    .tags .tag-list .tag-item {
      font-size: 0.8em;
      margin: 3px 0 2px 5px;
      padding: 4px 10px;
      border-radius: 20px;
      letter-spacing: 0.2px;
      white-space: nowrap;
      height: auto;
      line-height: 16px;
      border: none;
    }
  }
  &.label-editor .tags .tag-list .tag-item {
    color: @brand-dark;
    background-color: @brand-light-med;
    border: 1px solid @brand-light-med;
  }
  &.topic-editor .tags .tag-list .tag-item {
    color: @brand-dark;
    background-color: @brand-lighter;
    border: 1px solid @brand-light-dark;
  }
}
