.stretch-base() {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.stretch {
  .stretch-base();
  overflow: auto;
}

.ui-splitbar {
  display: flex;
  justify-content: center;
  right: auto;
  position: absolute;

  background-color: @subnavbar-bg;
  z-index: @zindex-ui-splitbar;
}

ui-layout.ui-layout-resizing .ui-layout-container {
  pointer-events: none !important;
}

.ui-layout-row > .ui-splitbar {
  height: 8px;
  width: 100%;
  cursor: row-resize;
  text-align: center;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;
}

.ui-layout-column > .ui-splitbar {
  width: 8px;
  height: 100%;
  cursor: col-resize;
  flex-direction: column;
  overflow-x: hidden;
}

@media print {
  .ui-splitbar {
    display: none;
  }

  .stretch {
    position: relative;
  }

  // The last item can take up any amount of space.
  .stretch.ui-layout-container:last-child {
    position: static;
    overflow: visible;
  }
}

// Make sure hidden elements are in fact not rendered.
.ui-layout-hidden {
  display: none;
}

.ui-layout-pane-separators > *,
.ui-layout-pane-separator {
  position: relative;
  padding-top: 10px;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 10px;
    background: linear-gradient(
      to bottom,
      darken(@subnavbar-bg, 4%) 0,
      @subnavbar-bg 50%
    );

    .dark-bg & {
      background: linear-gradient(
        to bottom,
        darken(@brand-darker, 4%) 0,
        @brand-darker 50%
      );
    }
  }
}
