body.landing-page-for-education {
  section#steps {
    h1 {
      text-align: left;
      max-width: none;
      font-size: @font-size-h2;
      margin-bottom: 20px;
    }
  }
}
