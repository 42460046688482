.upgrade-required-badge,
.library-access-badge,
.product-type-badge {
  cursor: default;
  position: relative;
  border-radius: @border-radius-base;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-size: 9px;
  font-weight: normal;
  padding: 2.5px 7.5px 2.5px 20px;

  &:hover,
  &:focus {
    text-decoration: none !important;
    box-shadow: 0 1px 0 darken(@brand-warning, 35%);
  }

  &:before {
    display: inline-block;
    position: absolute;
    font-size: 9px;
    top: 3px;
    left: 7.5px;
  }
}

.upgrade-required-badge {
  .icon-lock();
  color: @brand-black;
  background-color: @brand-warning;

  &:hover,
  &:focus {
    color: #000 !important;
    background-color: darken(@brand-warning, 5%);
  }
}

.library-access-badge {
  .icon-lock();
  color: @brand-black;
  background-color: @off-white;

  &:hover,
  &:focus {
    color: @brand-black !important;
    background-color: @brand-light-accent;
  }

  &.team-requires-upgrade {
    background-color: @brand-warning;
    &:hover,
    &:focus {
      color: #000 !important;
      background-color: darken(@brand-warning, 5%);
    }
  }
}

.product-type-badge {
  .icon-candidate();
  color: @brand-black;
  background-color: @off-white;

  &:before {
    transform: scale(1.5);
  }

  &:hover,
  &:focus {
    color: @brand-black !important;
    background-color: @brand-light-accent;
  }
  &.education {
    .icon-student();
  }
  &.both {
    .icon-users();
  }
}

a.upgrade-required-badge {
  cursor: pointer;
}

.state-upgrade-required {
  .icon-lock();
  &:before {
    color: @gray-base;
    background-color: @brand-warning;
    border-radius: 8px;
    padding: 2px 4px;
    line-height: 1;
    margin-left: -4px;
  }
}
