billing-plans-table {
  display: block;
  position: relative;
  .overflow-auto(overflow-x);

  > table {
    font-size: 18px;
    margin: 10px auto;
    border-collapse: separate;
    border-spacing: 16px 0;
    tr {
      > th,
      > td {
        text-align: center;
        padding: 2px 20px;
        border: 1px solid @border-color;
        border-bottom-width: 0;
      }
    }

    &.features-hidden {
      tr > th {
        display: none;
      }
    }

    // This section handles using CSS to cycle through the "selected" plan on
    // smaller screens.
    @media only screen and (max-width: @screen-xs-max) {
      border-collapse: collapse;
      width: 100%;
      tr {
        > th,
        > td {
          border-top-width: 1px !important;
        }
        > th {
          width: 35%;
          font-size: 14px;
          img {
            height: 10px !important;
          }
        }
        > td {
          width: 65%;
          display: none;
        }
      }

      &.selected-1 tr > td:nth-child(2),
      &.selected-2 tr > td:nth-child(3),
      &.selected-3 tr > td:nth-child(4),
      &.selected-4 tr > td:nth-child(5),
      &.selected-5 tr > td:nth-child(6) {
        // only show the column that's selected
        display: table-cell;
      }
    }

    // Style the plan title
    thead > tr {
      > th {
        border: none;
      }
      > td {
        padding: 10px 20px;
        color: @off-white;
        background-color: @brand-dark;
        border-color: @brand-dark;
        border-radius: @border-radius-large @border-radius-large 0 0;
        font-size: 16px;

        > div {
          display: flex;
          > a {
            flex: 0 1 auto;
            color: @brand-primary-lighter;
            padding: 0 4px;
            &:hover,
            &:focus,
            &:active {
              color: @link-hover-color;
              text-decoration: none;
            }
            &.next-plan {
              .icon-chevron-right();
            }
            &.prev-plan {
              .icon-chevron-left();
            }

            @media only screen and (min-width: @screen-sm-min) {
              display: none;
            }
            @media not screen {
              display: none;
            }
          }
          > span {
            flex: 1 1 auto;
          }
        }
      }
    }

    // General styling for pricing & features
    tbody > tr {
      > th {
        font-weight: @font-weight-light;
        text-align: right;
        color: @text-color;
        padding: 2px 10px 2px 5px;
        border-width: 0;
        @media only screen and (min-width: @screen-sm-min) {
          padding-left: 20px;
          white-space: nowrap;
        }
        > * {
          opacity: 0.8;
        }
        img {
          height: 13px;
          width: auto;
          margin-bottom: 0.3rem;
        }
      }

      &:not(.group-heading):hover {
        background-color: @brand-light-med;
        > th {
          background-color: @brand-light;
          > * {
            opacity: 1;
          }
        }
      }
    }

    // Pricing section
    tbody.prices {
      > tr:hover {
        &,
        > th {
          background-color: transparent;
        }
      }
      > tr.price {
        th,
        td {
          padding-top: 20px;
        }
        td {
          font-size: 24px;
        }
      }
      > tr.billing-note {
        th,
        td {
          font-size: 14px;
          padding-bottom: 20px;
          max-width: 340px;
        }
        td {
          border-top: 0;
          border-bottom: 1px solid @border-color;
          color: @text-muted;
          strong {
            text-transform: uppercase;
          }

          button {
            display: block;
            margin: 15px auto 0;
          }
        }

        &.landing {
          td {
            border-bottom: 0;
            padding-bottom: 10px;
          }
        }
      }
    }

    // Features
    tbody.features {
      font-size: 16px;
      tr {
        td {
          background-color: @brand-lighter;
          border: none;
        }
        th,
        td {
          vertical-align: top;
        }
      }
      th,
      td {
        tooltip-help-icon {
          display: none;
          @media only screen and (min-width: @screen-sm-min) {
            display: inline;
          }
          opacity: 0.25;
          &:hover {
            opacity: 1;
          }
        }
      }
      tr:not(.group-heading):hover {
        td {
          background-color: transparent;
        }
        th {
          tooltip-help-icon {
            opacity: 0.65;
          }
        }
      }

      td .icon-checkmark,
      td .icon-plus {
        color: @brand-primary-dark;
        @media only screen {
          > span {
            display: none;
          }
        }
        @media not screen {
          &:before {
            display: none;
          }
        }
      }

      // make the plus icon a bit smaller since it overpowers the checkmark in the pricing table
      td .icon-plus:before {
        font-size: 14px;
      }

      > tr > td div.enterprise {
        color: @text-accent;
      }

      > tr.credits {
        th,
        td {
          padding-top: 20px;
          padding-bottom: 5px;
        }
      }
      > tr.user-seats {
        th,
        td {
          padding-top: 5px;
          padding-bottom: 10px;
        }
      }

      tr.feature:not(:hover):nth-child(odd) td {
        background-color: @brand-light;
      }
    }

    tbody.group {
      tr.group-heading {
        th {
          font-weight: bold;
          padding: 20px 10px 5px;
          text-transform: uppercase;
          color: @gray-base;
          span {
            display: block;
            border-bottom: 1px solid @border-color;
            padding-bottom: 5px;
          }
          span:before {
            padding-right: 5px;
            position: relative;
          }
        }
        td {
          background-color: transparent;
          border: none;
        }
      }

      span.group-teamwork {
        .icon-team();
        &:before {
          font-size: 22px;
          top: 4px;
        }
      }
      span.group-content {
        .icon-rubiks();
        &:before {
          font-size: 18px;
        }
      }
      span.group-platform {
        .icon-quick-start();
        &:before {
          font-size: 19px;
        }
      }
      span.group-integrate {
        .icon-integrations();
        &:before {
          top: 1px;
          font-size: 18px;
        }
      }
      span.group-support {
        .icon-support();
        &:before {
          font-size: 13px;
        }
      }
    }
  }

  .features-toggle {
    text-align: center;
    padding: 10px;

    button {
      min-width: 15em;
      .icon-magnifying-glass();
    }
  }
}
