.bubble-dialog.popover {
  // move off screen by default
  top: -100vh;
  left: -100vw;
  max-width: 90vw;
  display: block;
  opacity: 0;
  font-size: @font-size-base !important;
  font-weight: normal !important;
  font-style: normal !important;
  text-transform: none !important;
  text-align: left !important;
  padding: 0;
  z-index: @zindex-root-popover;

  transition: opacity 0.1s ease-out;
  pointer-events: none;

  &.showing {
    pointer-events: auto;

    &.immediate {
      transition: none;
    }

    opacity: 1;
  }

  &.titled {
    &.bottom > .arrow:after {
      border-bottom-color: @popover-title-bg;
    }
  }

  .popover-inner {
    max-height: 90vh;
    overflow: auto;
  }

  &.bubble-dialog-closeable {
    // allow room when tooltip is at the side
    margin-left: -6px;

    .popover-close {
      .icon-x();

      position: absolute;
      z-index: 1;
      color: @tooltip-color;
      background-color: @tooltip-bg;
      top: -6px;
      right: -6px;
      font-size: 8px;
      padding: 1px 0;
      width: 12px;
      height: 12px;
      text-align: center;
      border-radius: 6px;
      transition: opacity 0.2s ease-in-out;
      opacity: 0;
    }
    &.showing:hover .popover-close {
      transition-delay: 0.5s;
      opacity: 1;
    }
  }

  .popover-content {
    margin-bottom: 0.2rem;
    text-align: center;

    .btn-container {
      white-space: nowrap;
      padding-top: 1rem;

      .btn {
        margin-right: 0.4rem;
      }
    }
  }

  &.bubble-dialog-menu {
    padding: 0;

    .dropdown-menu {
      display: block;
      position: static;
      box-shadow: none;
      border: none;
      float: none;
      border-radius: 0;

      + .dropdown-menu {
        padding-top: 9px;
        margin-top: 4px;
        border-top: 1px solid @dropdown-divider-bg;
      }
    }
  }

  &.bubble-dialog-simple {
    text-align: left;
    .popover-content {
      text-align: left;
      max-width: 35em;
    }
  }
}
