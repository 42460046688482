body.landing-page-about {
  @media (min-width: @screen-xl-min) {
    section.hero > div {
      .hero-actions {
        padding-top: 120px;
      }

      > figure img {
        position: relative;
        top: auto;
      }
    }
  }

  section.section-basic {
    text-align: center;
  }

  #qualified-story {
    .partnership,
    > div > p {
      margin-bottom: 20px;
      @media (min-width: @screen-sm-min) {
        margin-bottom: 30px;
      }
      @media (min-width: @screen-md-min) {
        margin-bottom: 40px;
      }
    }

    ul.founders {
      padding: 0;
      margin: 0 auto 40px;

      > li {
        list-style: none;
        margin: 30px 0 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        img,
        > div {
          box-shadow: 0 10px 15px fade(@brand-complement-dark, 13%);
        }

        img {
          width: 140px;
          height: 170px;
          border-radius: 3px;
        }

        > div {
          position: relative;
          background-color: @white;
          border-radius: 3px;
          width: 200px;
          margin: -20px auto 0;
          padding: 10px;
          text-align: left;

          .name {
            font-weight: @font-weight-heavy;
          }

          .title {
            color: @brand-complement-light;
            font-size: 0.777em;
            .text-overflow();
          }
          .title + .title {
            color: @brand-complement-mlight;
          }

          .title:empty {
            display: none;
          }

          .links {
            position: absolute;
            right: 8px;
            top: 4px;
            font-size: 1.5em;

            a {
              color: @text-muted;
              text-decoration: none;

              &:hover,
              &:focus {
                color: @link-hover-color;
                text-decoration: underline;
              }
            }

            .linkedin {
              .icon-vendor-linkedin();

              &:before {
                display: inline-block;
                transform: translateY(1px);
              }

              &:hover,
              &:focus {
                color: @vendor-linkedin-primary;
              }

              > span {
                font: 0/0 a;
                color: transparent;
                text-shadow: none;
                background-color: transparent;
                border: 0;
              }
            }
          }
        }
      }

      @media (min-width: @screen-sm-min) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: center;

        > li {
          margin: 20px 10px 10px;
          flex: 0 1 33.4%;

          > div {
            .title:empty {
              display: block;
              &:before {
                content: '\00a0';
              }
            }
            .links {
              bottom: 16px;
            }
          }
        }
      }

      @media (min-width: @screen-md-min) {
        > li {
          margin: 40px 20px 20px;
          flex: 0 1 auto;

          img,
          > div {
            box-shadow: 0 12px 24px fade(@brand-complement-dark, 13%);
          }

          img {
            width: 210px;
            height: 255px;
            border-radius: 4px;
          }
        }
      }

      @media (min-width: @screen-lg-min) {
        margin: 0 auto 60px;

        > li {
          margin: 40px 30px 30px;

          img,
          > div {
            box-shadow: 0 15px 30px fade(@brand-complement-dark, 13%);
          }
        }
      }
    }
  }

  #investors {
    margin-top: 40px;

    > div {
      max-width: none;
    }

    ul.investors-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-start;
      align-content: center;
      padding: 0;
      margin: 0;

      > li {
        list-style: none;
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
        margin: 20px 10px 0;
        flex: 0 0 80px;

        > img {
          border-radius: @border-radius-base;
          width: 60px;
          height: 60px;
        }

        > span {
          color: @brand-complement-light;
          text-align: center;
          display: block;
          margin-top: 16px;
        }
      }

      @media (min-width: @screen-md-min) {
        > li {
          flex-basis: 100px;
          margin: 20px 20px 0;

          > img {
            width: 80px;
            height: 80px;
          }

          > span {
            margin-top: 20px;
          }
        }
      }
    }
  }
}
