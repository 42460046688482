.bullets() {
  &,
  & ul {
    position: relative;
    list-style-type: none;
    margin: 0 0 (@line-height-computed / 2);
    padding: 0 0 0 15px;
    @media (min-width: @screen-sm-min) {
      padding-left: 20px;
    }
  }
  & ul {
    margin-bottom: 0;
  }
  > li,
  & ul > li {
    .icon-dot();

    &:before {
      color: @brand-primary;
      transform: scale(0.8);
      position: absolute;
      opacity: 0.4;
      left: 0;
      line-height: inherit !important;

      .alert & {
        color: inherit;
      }
    }
  }
}

ul.bullets {
  .bullets();
  .tooltip &:last-child {
    margin-bottom: 0;
  }
}

ul.action-links {
  position: relative;
  list-style-type: none;
  margin: 0;
  padding: 20px 10px;
  font-size: @font-size-med;

  > li {
    list-style: none;
    margin: 0 0 10px;

    &:last-child {
      margin-bottom: 0;
    }

    a {
      position: relative;
      display: block;
      padding: 5px 5px 5px (@font-size-med + 15px);
      cursor: pointer;
      border-radius: @border-radius-base;
      border: 1px solid transparent;
      transition: all 0.15s ease-in-out;
      background-color: @brand-complement-bg;

      .icon-status-submit();

      .help-block {
        margin-bottom: 0;
      }

      &:before {
        color: @brand-primary-light;
        font-size: (@font-size-med + 2);
        position: absolute;
        top: 5px;
        left: 6px;
        line-height: inherit !important;
        transition: all 0.15s ease-in-out;
      }

      &:focus,
      &:hover,
      &:active {
        background-color: @modal-content-bg;
        text-decoration: none;
        box-shadow: 0 3px 6px fade(@brand-complement-dark, 6%);
        border-color: @brand-complement-xlight;

        &:before {
          color: @brand-complement-medium;
        }
      }

      &:hover,
      &:active {
        box-shadow: 0 6px 12px fade(@brand-complement-dark, 13%);
      }

      &.disabled,
      &[disabled] {
        color: @text-muted;
        background-color: transparent;
        pointer-events: none;

        &:before {
          color: fade(@text-muted, 50%);
        }
      }

      &.action-link-danger:not(.disabled):not([disabled]) {
        background-color: @alert-danger-bg;
        color: @alert-danger-text;

        &:before {
          color: fade(@alert-danger-text, 50%);
        }

        &:focus,
        &:hover,
        &:active {
          background-color: @modal-content-bg;
          box-shadow: 0 3px 6px fade(@brand-danger, 6%);

          &:before {
            color: @alert-danger-text;
          }
        }

        &:hover,
        &:active {
          box-shadow: 0 6px 12px fade(@brand-danger, 13%);
        }
      }
    }

    &.secondary,
    &.cancel {
      margin-top: 16px;

      a {
        &:before {
          color: @text-muted;
        }

        &:not(:hover):not(:focus) {
          color: @brand-complement-light;
        }
      }
    }

    &.cancel {
      a {
        .icon-x();

        &:before {
          font-size: (@font-size-med - 4);
          top: 7px;
          left: 9px;
        }
      }
    }

    &.muted {
      a:not(:hover):not(:focus) {
        opacity: 0.5;
        background-color: transparent;
      }
    }
  }
}

.dot-list {
  > *:not(:last-child) {
    &:after {
      content: ' • ';
      margin: 0 0.5em;
      opacity: 0.5;
    }
  }
}

.comma-list {
  > *:not(:last-child) {
    &:after {
      content: ', ';
    }
  }
}

.option-list {
  .dot-list();

  > * {
    display: inline-block;
    &.enabled {
      .icon-checkmark();

      &:before {
        color: @brand-primary;
        margin-right: 0.35em;
        opacity: 0.5;
      }
    }

    &.disabled {
      .icon-x();

      &:before {
        margin-right: 0.35em;
        opacity: 0.5;
      }
    }
  }
}

.comma-and-list {
  > *:not(:last-child) {
    &:after {
      content: ', ';
    }
  }

  > *:nth-last-child(2) {
    &:after {
      content: ', and ';
    }
  }

  > *:first-child:nth-last-child(2) {
    &:after {
      content: ' and ';
    }
  }
}

.item-list {
  .no-focus-border();

  .as-sortable-placeholder {
    border: 2px dashed @border-color;

    & + .list-item {
      border-top-color: transparent;
    }
  }

  .list-item {
    padding: @list-v-spacing 0;
    clear: both;
    position: relative;
    display: flex;
    .clearfix();

    border-top: 1px solid @border-color;

    &:first-child {
      border-top: none;
    }

    .list-item-row {
      display: block;
      @media only screen and (max-width: @screen-xs-max) {
        > * {
          display: block;
          margin-bottom: 0.6rem;
        }
      }
      @media only screen and (min-width: @screen-sm-min) {
        display: flex;
        margin-bottom: 1rem;

        > * {
          flex: 1 1 auto;
        }
      }
      // adjust media width for sidebar presence
      .has-sidebar & {
        @media only screen and (max-width: (@screen-xs-max + @sidebar-width)) {
          display: block;
          margin-bottom: 0;
          > * {
            display: block;
            margin-bottom: 0.6rem;
          }
        }
        @media only screen and (min-width: (@screen-sm-min + @sidebar-width)) {
          display: flex;
          margin-bottom: 1rem;

          > * {
            flex: 1 1 auto;
          }
        }
      }
    }

    .list-item-content {
      padding: 1rem;
      flex-grow: 1;

      > .title,
      .list-item-row > .title {
        font-size: @font-size-h3;
        margin-bottom: 1rem;

        a {
          color: @text-color;

          &:hover {
            color: @brand-primary-darker;
            text-decoration: none;
          }
        }
      }

      > .labels {
        margin-top: 0.5rem;

        .label {
          display: inline-block;
          font-weight: normal;
          margin-right: 0.2rem;
        }
      }

      > .line-item {
        .line-label {
          font-weight: bold;
          letter-spacing: 1px;
          text-transform: uppercase;
          font-size: 1.2rem;
        }

        .line-value {
        }
      }
    }

    .list-item-actions {
      flex-shrink: 0;
      align-content: center;
      align-self: center;
      position: relative;
      min-width: 80px;
      @media (min-width: @screen-md-min) {
        min-width: 170px;
      }

      > .actions {
        width: 100%;
        text-align: center;
      }
    }

    .list-item-sort {
      float: left;
      min-width: 60px;
      border-right: 1px solid darken(@brand-light, 7%);
      position: relative;

      &:hover {
        background-color: lighten(@brand-light, 5%);
      }

      > .sort {
        .center();
        .icon-drag-sort();
        font-size: 1.25em;
      }
    }

    &.edit-mode {
    }

    &.sortable {
      user-select: none;
    }
  }
}
