// runtime: 5.5
.unit-tests.is-visible svg {
  #graph-o-1 {
    fill: @brand-primary;
    animation: unit-tests-is-visible-o-first 0.5s 0.5s 1 ease-out forwards;
  }
  #graph-o-2,
  #graph-o-3,
  #graph-o-4 {
    fill: @brand-gray-dark;
    animation: unit-tests-is-visible-o-good 0.5s 1.5s 1 ease-out forwards;
  }
  #graph-o-x {
    animation: unit-tests-is-visible-o-bad 0.8s 2.5s 1 ease-out forwards;
  }
  #graph-o-3 {
    animation-delay: 4s;
  }
  #graph-o-4 {
    animation-delay: 5s;
  }

  #graph-l-1,
  #graph-l-2,
  #graph-l-3 {
    fill: #cbcbcb;
    animation: unit-tests-is-visible-l-good 0.5s 1s 1 ease-out forwards;
  }
  #graph-l-x {
    animation: unit-tests-is-visible-l-bad 1s 2s 1 ease-out forwards;
  }
  #graph-l-2 {
    animation-delay: 3.5s;
  }
  #graph-l-3 {
    animation-delay: 4.5s;
  }

  #success-1,
  #success-2,
  #success-3,
  #success-4 {
    opacity: 0;
    animation: unit-tests-is-visible-success 0.4s 1.5s 1 ease-in-out forwards;
  }
  #success-2 {
    animation-delay: 2.5s;
  }
  #success-3 {
    animation-delay: 4s;
  }
  #success-4 {
    animation-delay: 5s;
  }
}

@keyframes unit-tests-is-visible-o-first {
  0% {
    fill: @brand-primary;
  }
  100% {
    fill: @brand-primary-darker;
  }
}

@keyframes unit-tests-is-visible-o-good {
  0% {
    fill: @brand-gray-dark;
  }
  50% {
    fill: @brand-primary;
  }
  100% {
    fill: @brand-primary-darker;
  }
}

@keyframes unit-tests-is-visible-o-bad {
  0% {
    fill: @brand-gray-dark;
  }
  50% {
    fill: @brand-danger;
  }
  100% {
    fill: desaturate(@brand-danger, 35%);
  }
}

@keyframes unit-tests-is-visible-l-good {
  0% {
    fill: #cbcbcb;
  }
  50% {
    fill: @brand-primary-light;
  }
  100% {
    fill: @brand-primary-lighter;
  }
}

@keyframes unit-tests-is-visible-l-bad {
  0% {
    fill: #cbcbcb;
  }
  25%,
  75% {
    fill: fade(@brand-danger, 50%);
  }
  100% {
    fill: fade(desaturate(@brand-danger, 35%), 35%);
  }
}

@keyframes unit-tests-is-visible-success {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
