pre {
  clear: both;
  word-break: normal;
  word-wrap: normal;
  tab-size: 4;
}

pre > code {
  .line {
    @pre-padding: ((@line-height-computed - 1) / 2);
    @pre-padding2: @pre-padding * 2;
    @pre-padding3: @pre-padding * 3;
    display: inline-block;
    position: relative;
    padding-left: calc(2ch + @pre-padding2);
    // fixes empty lines
    &:after {
      content: ' ';
    }

    &:before {
      box-sizing: content-box;
      display: inline-block;
      position: absolute;
      top: 0;
      bottom: 0;
      text-align: right;
      width: 2ch;
      content: attr(start);
      padding-right: @pre-padding;
      padding-left: @pre-padding;
      margin-left: calc(-2ch - @pre-padding3);
      margin-right: @pre-padding;
      color: fade(@pre-color, 50%);
      background-color: @brand-light;
      border-right: 1px solid @brand-light-dark;
    }

    &:first-child:before {
      padding-top: @pre-padding;
      margin-top: -@pre-padding;
      border-top-left-radius: @border-radius-base;
    }

    &:last-child:before {
      padding-bottom: @pre-padding;
      margin-bottom: -@pre-padding;
      border-bottom-left-radius: @border-radius-base;
    }

    &.width-3 {
      padding-left: calc(3ch + @pre-padding2);

      &:before {
        width: 3ch;
        margin-left: calc(-3ch - @pre-padding3);
      }
    }

    &.width-4 {
      padding-left: calc(4ch + @pre-padding2);

      &:before {
        width: 4ch;
        margin-left: calc(-4ch - @pre-padding3);
      }
    }

    &.width-5 {
      padding-left: calc(5ch + @pre-padding2);

      &:before {
        width: 5ch;
        margin-left: calc(-5ch - @pre-padding3);
      }
    }

    &.width-6 {
      padding-left: calc(6ch + @pre-padding2);

      &:before {
        width: 6ch;
        margin-left: calc(-6ch - @pre-padding3);
      }
    }
  }
}

.terminal-command {
  display: flex;
  margin-bottom: 10px;

  button {
    flex: 0 1 auto;

    &:empty:before {
      margin-right: 0;
    }
  }

  pre {
    flex: 1 1 auto;
    background-color: @dark-bg-color;
    color: @dark-text-color;
    margin-bottom: 0;

    > code {
      white-space: pre;

      &:before {
        content: attr(data-prompt) ' ';
        color: @text-muted;
      }
    }
  }
}
