.input-range {
  display: flex;

  > * {
    flex: 1 1 auto;
  }

  > .sep {
    &:extend(.form-control-static all);
    flex: 0 1 auto;
    padding-left: 1em;
    padding-right: 1em;
  }

  // input groups are rendered as tables, and webkit/blink doesn't like shrinking them
  // these settings trick the browser into rendering them at a natural width.
  > .input-group {
    width: 1%;

    .form-control {
      width: auto;
    }
  }

  @media only screen and (max-width: 500px) {
    display: block;
    > * {
      display: block;
    }

    > .sep {
      text-align: center;
    }

    > *:not(:last-child) {
      &,
      & .form-group {
        margin-bottom: 0;
      }
    }
  }
}
