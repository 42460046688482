.bubble-dialog.popover.tooltip {
  color: @tooltip-color;
  background-color: @tooltip-bg;
  border: none;
  font-size: @font-size-small !important;
  pointer-events: none;

  .popover-content {
    padding: 4px 8px;
    margin-bottom: 0;
    max-width: 35em;
    text-align: left;

    .tooltip-shortcut {
      display: inline-flex;
      align-items: flex-start;
      > * {
        flex: 0 1 auto;
      }
    }

    .shortcut-hint {
      margin-top: 1px;
      margin-left: 10px;
      flex: 0 0 auto;
    }
  }

  .arrow {
    border-width: @tooltip-arrow-width;

    &:after {
      display: none;
    }
  }

  &.top > .arrow {
    border-top-color: @tooltip-arrow-color;
    border-bottom-width: 0;
    margin-left: -@tooltip-arrow-width;
    bottom: -@tooltip-arrow-width;
  }

  &.right {
    .popover-content {
      text-align: left !important;
    }

    > .arrow {
      border-right-color: @tooltip-arrow-color;
      border-left-width: 0;
      margin-top: -@tooltip-arrow-width;
      left: -@tooltip-arrow-width;
    }
  }

  &.bottom > .arrow {
    border-bottom-color: @tooltip-arrow-color;
    border-top-width: 0;
    margin-left: -@tooltip-arrow-width;
    top: -@tooltip-arrow-width;
  }

  &.left {
    .popover-content {
      text-align: left !important;
    }

    > .arrow {
      border-left-color: @tooltip-arrow-color;
      border-right-width: 0;
      margin-top: -@tooltip-arrow-width;
      right: -@tooltip-arrow-width;
    }

    .shortcut-hint {
      order: 0;
      margin-left: 0;
      margin-right: 10px;
    }
  }

  &.top-left,
  &.bottom-left {
    > .arrow {
      left: 10px;
    }
  }

  &.top-right,
  &.bottom-right {
    > .arrow {
      left: auto;
      right: 5px;
    }
  }

  .dark-bg & {
    background-color: #000;

    &.top > .arrow {
      border-top-color: #000;
    }

    &.right > .arrow {
      border-right-color: #000;
    }

    &.bottom > .arrow {
      border-bottom-color: #000;
    }

    &.left > .arrow {
      border-left-color: #000;
    }
  }
}

tooltip-help-icon {
  cursor: help;
  display: inline-block;
  color: @text-muted;
  opacity: 0.8;
  .icon-help();
  margin-left: 4px;
  transform: translateY(1px);
  transition: all 0.15s ease-in-out;

  .bubble-showing &,
  &:hover,
  &:active,
  &:focus {
    color: inherit;
    opacity: 1;
    text-decoration: none;
  }

  .alert & {
    color: inherit;
    opacity: 0.6;
    &:hover,
    &:active,
    &:focus {
      opacity: 1;
    }
  }
}
