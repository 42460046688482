.qualified-input(@type, @label-icon, @icon-border-radius: @border-radius-base) {
  position: relative;

  label {
    font-weight: inherit;
    padding-left: 0;
  }

  input[type='@{type}'] {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    overflow: hidden;
    width: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    border: 0 !important;

    + label {
      cursor: pointer;
      position: relative;
      display: inline-block;
      padding-left: 24px;

      &:empty {
        min-height: 1em * @line-height-base;
      }

      .icon-common-icons(@label-icon);

      &:before {
        position: absolute;
        top: 1px;
        left: 0;
        font-size: 14px;
        display: inline-block;
        line-height: 14px;
        width: 18px;
        height: 18px;
        transition: all 0.15s ease-in-out;
        background-color: @white;
        border: 1px solid @input-border;
        border-radius: @icon-border-radius;
        color: fade(@brand-primary-dark, 0%);
        padding: 1px;
        text-align: center;
        transform: scale(1);

        .dark-bg & {
          background-color: @brand-black;
          border-color: @btn-default-dark-bg;
        }
      }
    }

    &:focus,
    &:active {
      outline: none;

      + label:before {
        @color: @input-border-focus;
        @color-rgba: rgba(red(@color), green(@color), blue(@color), 0.6);
        border-color: @color;
        outline: 0;
        box-shadow:
          inset 0 1px 1px rgba(0, 0, 0, 0.075),
          0 0 8px @color-rgba;
      }
    }
  }

  &.qualified-@{type}-sm {
    input[type='@{type}'] {
      + label {
        padding-left: 20px;
        font-size: @font-size-small;

        &:before {
          top: 3px;
          left: 1px;
          font-size: 10px;
          line-height: 10px;
          width: 14px;
          height: 14px;
        }

        &:empty {
          padding-left: 18px;
        }
      }
    }
  }

  input[type='@{type}']:checked + label:before {
    color: @brand-primary;
    .dark-bg & {
      color: @dark-bg-link-color;
    }
  }

  input[type='@{type}'][disabled] {
    + label {
      color: @text-muted !important;

      &:before {
        background-color: @brand-light;
        border-color: @border-color !important;
        box-shadow: none !important;

        .dark-bg & {
          background-color: @brand-darker;
          border-color: @btn-default-dark-bg !important;
        }
      }

      &:not(:hover) tooltip-help-icon {
        opacity: 0.5;
      }
    }

    &:checked + label:before {
      color: fade(@text-muted, 60%) !important;
    }
  }

  .has-success & input[type='@{type}'] + label:before {
    border-color: @state-success-text;
  }
  .has-success & input[type='@{type}']:checked + label:before {
    color: @state-success-text;
  }

  .has-warning & input[type='@{type}'] + label:before {
    border-color: @state-warning-text;
  }
  .has-warning & input[type='@{type}']:checked + label:before {
    color: @state-warning-text;
  }

  .has-error & input[type='@{type}'] + label:before {
    border-color: @state-danger-text;
  }
  .has-error & input[type='@{type}']:checked + label:before {
    color: @state-danger-text;
  }

  > .help-block {
    padding-left: 24px;
  }
}
