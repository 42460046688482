#intercom-container #intercom-launcher {
  display: none !important;
}

.intercom-button {
  cursor: pointer;
}

.navbar-nav > li > .intercom-button {
  .icon-support();
  &:before {
    display: inline-block;
    transform: scale(1.4) translateY(1px);
  }
}

.modal-content > .intercom-button,
.loading-panel > .intercom-button,
.corner-intercom-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.6rem 1rem;

  background-color: @btn-primary-bg;
  color: @btn-primary-color;
  border-bottom-left-radius: @border-radius-large;

  &:hover,
  &:focus {
    background-color: darken(@btn-primary-bg, 10%);
  }

  .icon-support();
  &:before {
    display: inline-block;
    transform: scale(1.4) translateY(1px);
  }
}

.modal:not(.no-close-button):not(.footer-intercom)
  .modal-content
  > .intercom-button {
  top: 0.8rem;
  right: 3rem;
  border-bottom-right-radius: @border-radius-large;
  color: @link-color;
  background-color: transparent;
  &:hover,
  &:focus {
    color: @link-hover-color;
    background-color: transparent;
  }
}

.modal.footer-intercom {
  .modal-content > .intercom-button {
    top: auto;
    right: auto;
    bottom: 1.5rem;
    left: 1.5rem;
    border-radius: @border-radius-base;
    padding: 0.5rem 1rem;
  }
  &:not(.no-intercom) .modal-footer {
    padding-left: 64px;
  }
}

.modal.no-intercom .modal-content > .intercom-button {
  display: none;
}
