@import '../../../../shared/styles/hljs-theme';

embedded-qualified-test {
  .options {
    text-align: left;
    background-color: @sidebar-bg;
    border-radius: @border-radius-large;
    padding: 6px;
    margin-bottom: 10px;

    .options-header {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 10px;
      align-items: center;
      > * {
        flex: 1 1 calc(100% - 130px);
        width: auto;
      }
      > a {
        flex: 1 1 auto;
        cursor: pointer;
        color: @text-color;

        &:hover,
        &:focus,
        &:active {
          text-decoration: none;
          color: @brand-primary-darker;
        }
      }

      > .options-toggle {
        .expandable-chevron();

        &.expanded {
          .expandable-chevron-expanded();
        }
      }

      > .options-remove {
        flex: 0 1 auto;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .add-new-embed {
      text-align: center;
      .slide-in(@max-height: 34px);
      button {
        min-width: 300px;
      }
    }
  }

  embedded-qualified-options {
    .ngif-slide-in(@max-height: 50vh);

    > div {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 10px;
      align-items: center;
      > * {
        flex: 1 1 calc(100% - 130px);
        width: auto;
      }
      > label {
        flex: 0 1 120px;
        margin-right: 10px;
        text-align: right;
      }
      > .help-block {
        flex: 1 1 100%;
        margin: 0 0 0 130px;
        [ng-bind='tab'] {
          cursor: pointer;
          &:hover,
          &:focus {
            .markdown-code-link-hover-styling();
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .demo-selector {
      display: flex;
      > * {
        flex: 1 1 auto;
      }
      > .form-control-select {
        flex-basis: 140px;
      }
    }

    .options-initial-layout {
      > label {
        align-self: flex-start;
      }
      > div {
        display: flex;
        flex-wrap: wrap;
        > * {
          flex: 1 1 50%;
          padding: 4px;
          display: flex;
          align-items: center;
          > * {
            flex: 1 1 auto;
          }
          label {
            margin-right: 4px;
            flex: 0 0 100px;
            text-align: right;
          }
        }
      }
    }

    .options-submit-button {
      display: block;
      text-align: center;
      button {
        min-width: 300px;
      }
    }
  }

  section.section-embedded-editor {
    > div {
      > h1 {
        &:before {
          display: inline-block;
          color: @brand-primary;
          margin-right: 0.8em;
          transform: translateY(2px) scale(1.5);
          transform-origin: 0 50%;
        }
      }
      > h1,
      > .embed-description,
      > .embed-description > p {
        max-width: none;
        margin-bottom: 10px;
        text-align: left;
      }
    }

    + section.section-embedded-editor > div {
      padding-top: 30px;
    }
  }

  iframe.qualified-embedded {
    width: 100%;
    height: 50vh;
    min-height: 200px;
    border: 1px solid @border-color;
    border-radius: @border-radius-large;
  }

  .embedded-qualified-controls {
    margin-top: 4px;

    .buttons {
      display: flex;
      max-width: 600px;
      margin: 0 auto;
      > button {
        flex: 1 1 auto;
      }
      .embed-start {
        .icon-substitute();
        margin-right: 10px;
      }
      .embed-runTests,
      .embed-attempt {
        .icon-transport-play();
      }
      .embed-reset {
        .icon-reset();
      }
      .embed-reload {
        margin-left: 10px;
        .icon-reload();
      }
      button[disabled] {
        pointer-events: auto;
      }
    }
    .embed-contents {
      margin-top: 6px;
      text-align: left;
      display: flex;
      overflow: hidden;
      > * {
        flex: 1 1 33%;
        max-width: 33%;
        max-height: 200px;
        display: flex;
        flex-direction: column;
        padding: 0 5px;
        h5 {
          flex: 0 1 auto;
          margin-bottom: 5px;
          display: flex;
          justify-content: space-between;
          code {
            font-weight: normal;
          }
        }
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }

        pre {
          flex: 1 1 auto;
          max-width: 100%;
          overflow: auto;
          code {
            white-space: pre;
          }
        }
      }
    }
  }
}
