.feature-carousel {
  display: block;
  max-width: @content-max-width;
  padding: 0 10px;

  .feature-carousel-header {
    display: none;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0 0 20px;
      padding: 0;
      min-height: 80px;
      text-align: left;

      + li {
        margin-top: 100px;
      }

      > figure {
        margin-bottom: 20px;
        text-align: center;
        > * {
          width: 100%;
          max-width: @text-max-width;
          max-height: 60vh;
          object-fit: contain;
          filter: drop-shadow(0 1.6vw 2vw fade(@brand-complement-dark, 13%));
        }
        > img {
          border: 1px solid @border-color;
          border-radius: @border-radius-large;
        }
      }
      > div {
        position: relative;
        padding-left: 80px;
        max-width: @text-max-width;
        margin: 40px auto 0;

        h3 {
          margin: 0 0 10px;
        }

        svg {
          position: absolute;
          height: 60px;
          width: 60px;
          top: 0;
          left: 0;
        }
      }
    }
  }

  @media (min-width: @screen-xl-min) {
    ul {
      li {
        + li {
          margin-top: 60px;
        }
        > figure > * {
          filter: drop-shadow(0 18px 24px fade(@brand-complement-dark, 13%));
        }
      }
    }
  }

  &.carousel-mode-enabled {
    @media (min-width: @screen-sm-min) {
      margin-bottom: 40px;

      .feature-carousel-header {
        position: relative;
        display: flex;
        justify-content: stretch;
        border-bottom: 1px solid @brand-complement-xlight;

        .feature-carousel-header-selector {
          position: absolute;
          bottom: -1px;
          height: 2px;
          background-color: @brand-primary-dark;
          transition: all 0.2s ease-in-out;
        }

        > h3 {
          flex: 1 1 25%;
          text-align: center;
          font-weight: @font-weight-light;
          font-size: @font-size-large;
          color: @brand-complement-light;
          transition: all 0.2s ease-in-out;
          cursor: pointer;
          margin: 0 20px 24px;

          svg {
            display: block;
            width: 100px;
            height: 100px;
            margin: 0 auto;
            transition: all 0.2s ease-in-out;
            opacity: 0.7;
          }

          &:hover,
          &.selected {
            color: @text-color;
            svg {
              opacity: 1;
            }
          }
        }
      }
      ul {
        display: flex;
        align-items: flex-start;
        overflow: hidden;
        padding: 24px 40px 60px;

        li {
          display: flex;
          flex: 0 0 100%;
          margin: 0 !important;
          align-items: flex-start;
          opacity: 0;
          transition:
            opacity 0.15s ease-in-out,
            transform 0.3s ease-in-out;

          > figure {
            flex: 1 1 50%;
            margin-bottom: 0;
          }
          > div {
            flex: 1 1 50%;
            margin-top: 10px;

            svg {
              display: none;
            }
          }

          &.selected {
            opacity: 1;
          }
        }
      }

      + h2 {
        margin-top: 50px !important;
      }
    }

    @media (min-width: @screen-lg-min) {
      ul {
        li {
          > figure {
            flex-basis: 60%;
          }
          > div {
            flex-basis: 40%;
          }
        }
      }
    }
  }
}
