.markdown.expandable {
  > *,
  pre {
    transition: all 0.2s ease-in-out;
  }

  &:not(.expanded) > *:not(.expand-link-wrapper):not(.always-show) {
    max-height: 6em;
    overflow: hidden;
  }

  &.expandable-inline {
    padding-left: 14px;
    .expandable-chevron();
    &:before {
      position: absolute;
      top: 3px;
      left: 0;
    }
    &.expanded,
    &.expanding {
      .expandable-chevron-expanded();
    }
  }

  .expand-link-wrapper {
    z-index: 1;
  }
  .expand-link {
    .icon-chevron-down-after();
    &:after {
      display: inline-block;
      margin-left: 0.3rem;
      font-size: 0.65em;
    }
  }

  &.collapsing,
  &.collapsed {
    > *:first-child,
    > *.always-show {
      // make the visible lines look more like a normal paragraph
      &:not(pre) {
        font-size: @font-size-base;
        padding: 0;
        color: inherit !important;
      }
      margin-top: 0;
      margin-bottom: (@line-height-computed / 2);

      img {
        max-height: 4em;

        @media (min-width: @screen-md-min) {
          max-height: 6em;
        }
        @media (min-width: @screen-lg-min) {
          max-height: 8em;
        }
      }
    }
    &.expandable-inline {
      pre {
        padding-top: 0;
        padding-bottom: 0;
        border: none;
      }
    }
    &:not(.has-max-lines) {
      > *:first-child,
      > *.always-show {
        max-height: (@font-size-base * @line-height-base);
        overflow: hidden;

        img {
          max-height: 1.2em;
        }
      }
    }
    > *:not(:first-child):not(.always-show):not(.expand-link-wrapper) {
      line-height: 0;
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;
      opacity: 0;
      z-index: -1;
      pointer-events: none;
      max-height: 0;
    }
  }
  &.collapsed:not(.has-max-lines) {
    > *:first-child {
      .text-overflow();
    }
  }
  &.expanded {
    .expand-link {
      .icon-chevron-up-after();
    }
  }
}
