.dropdown-menu {
  .box-shadow(@dropdown-box-shadow);

  a {
    cursor: pointer;

    &:before {
      margin-right: 0.5rem;
    }
  }

  .dropdown-header {
    text-transform: uppercase;
    font-size: 13px;

    &:not(:first-of-type) {
      margin-top: 1rem;
    }
  }

  > li > a:focus {
    background-color: @brand-primary-highlight;
  }

  > li > a.selected {
    position: relative;
    .icon-checkmark();

    &:before {
      display: block;
      color: @text-muted;
      position: absolute;
      font-size: @font-size-small;
      line-height: @font-size-small;
      left: 4px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  > li > a[disabled] {
    background-color: transparent;
    opacity: 0.5;
    cursor: default;
  }

  &.dropdown-in-body {
    top: -999px;
    left: -999px;
  }
}

.dropdown-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: @zindex-dropdown;
  display: none;

  > .dropdown-menu {
    display: block;
    position: static;
    float: none;
  }

  .open & {
    display: block;
  }
}

body > .dropdown-menu {
  z-index: @zindex-root-dropdown;
}
