embedded-calendly {
  .calendly-loading {
  }
  .calendly-widget {
    position: relative;
    min-height: 1000px;
    @media (min-width: 1060px) {
      min-height: 800px;
    }
    > iframe {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}
