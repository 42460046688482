[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
  display: none !important;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  > * {
    flex: 0 1 auto;
  }
  > section {
    flex: 1 1 auto;
  }

  // hide any injected body content
  > iframe,
  > img {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 0;
    width: 0;
    opacity: 0;
    pointer-events: none;
  }
}
