// runtime: 1.55
.compare-candidates.is-visible svg {
  > svg svg {
    overflow: visible;
  }
  #bad-can-1 > g,
  #bad-can-2 > g {
    transform: translateX(0);
    animation: compare-candidates-is-visible-bad-can 0.25s 0.5s 1 linear normal
      forwards;
  }
  #bad-can-2 > g {
    animation-delay: 0.65s;
  }
  #good-can-1 > g {
    transition:
      transform 0.25s 1s ease-in-out,
      opacity 0.2s 1.25s ease-in-out;
    transform: translateY(-59.6px);
  }
  #good-can-2 > g {
    transition:
      transform 0.25s 1.1s ease-in-out,
      opacity 0.2s 1.35s ease-in-out;
    transform: translateY(29.8px);
    opacity: 0.7;
  }
  #good-can-3 > g {
    transition:
      transform 0.25s 1.2s ease-in-out,
      opacity 0.2s 1.45s ease-in-out;
    transform: translateY(-29.8px);
    opacity: 0.6;
  }
  #good-can-4 > g {
    transition:
      transform 0.25s 1.3s ease-in-out,
      opacity 0.2s 1.55s ease-in-out;
    transform: translateY(-59.6px);
    opacity: 0.5;
  }
}

@keyframes compare-candidates-is-visible-bad-can {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-10%);
  }
}
