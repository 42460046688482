figure.testimonial {
  max-width: 30em;
  margin: 0 auto;

  blockquote {
    position: relative;
    border: none;
    margin: 0;
    background: transparent
      data-uri('../../landing/images/bg-boxes/testimonial.svg') no-repeat;
    background-size: 100% 100%;
    padding: 30px 40px;
    > p {
      color: @brand-complement-dark;
    }
  }

  figcaption {
    position: relative;
    font-size: @font-size-med;
    color: @brand-complement-dark;
    text-align: center;

    > div {
      &.avatar {
        width: 64px;
        height: 64px;
        border-radius: 50%;
        overflow: hidden;
        margin: 0 auto;
        > img {
          width: 64px;
          height: 64px;
        }
      }

      &.title {
        font-weight: @font-weight-light;
      }

      &.company {
        font-weight: @font-weight-heavy;
        color: @brand-complement-medium;
      }
      &.link {
        margin-top: 0.5em;
        font-size: @font-size-base;
      }
    }
  }

  &.testimonial-tall {
    > blockquote {
      background-image: data-uri(
        '../../landing/images/bg-boxes/testimonial-tall.svg'
      );
    }
  }

  &.testimonial-wide {
    @media (min-width: @screen-sm-min) {
      max-width: 58em;
      display: flex;
      align-items: flex-start;

      blockquote {
        flex: 1 1 auto;
        padding: 50px;
      }
      figcaption {
        flex: 0 1 50%;
        text-align: left;
        padding-left: 40px;
        align-self: center;

        > div {
          &.avatar {
            margin: 0;
          }
        }
      }
    }

    @media (min-width: @screen-md-min) {
      blockquote {
        padding: 60px;
      }
    }
  }
}
