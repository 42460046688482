// runtime: 4.6
.custom-challenges.is-visible svg {
  #javascript-tab {
    fill: #cbcbcb;
    transition: fill 0.1s 1.5s ease-in-out;
  }
  #ruby-tab,
  #python-tab {
    animation: custom-challenges-is-visible-tab-animation 1.6s 1.5s 1 linear
      forwards;
  }
  #python-tab {
    animation-delay: 3s;
  }
  #csharp-tab {
    fill: #4bb05d;
    transition: fill 0.1s 4.5s ease-in-out;
  }

  #javascript-code {
    opacity: 0;
    transition: opacity 0.1s 1.5s ease-in-out;
  }
  #ruby-code,
  #python-code {
    animation: custom-challenges-is-visible-code-animation 1.6s 1.5s 1 linear
      forwards;
  }
  #python-code {
    animation-delay: 3s;
  }
  #csharp-code {
    opacity: 1;
    transition: opacity 0.1s 4.5s ease-in-out;
  }
}

@keyframes custom-challenges-is-visible-tab-animation {
  0%,
  100% {
    fill: #cbcbcb;
  }
  4.8%,
  95.2% {
    fill: #4bb05d;
  }
}

@keyframes custom-challenges-is-visible-code-animation {
  0%,
  100% {
    opacity: 0;
  }
  4.8%,
  95.2% {
    opacity: 1;
  }
}
