// runtime: 5.3
.live-interview-start {
  svg {
    > svg {
      overflow: visible;
    }
    #live-interview-window {
      opacity: 0;
      transform: translateY(100%);
    }
    #connecting {
      opacity: 0;
    }
  }
  &.is-visible svg {
    #start-button {
      animation: live-interview-start-is-visible-button 0.45s 0.6s 1
        cubic-bezier(0.68, -0.55, 0.265, 1.55) normal forwards;
    }
    #live-interview-window {
      animation: live-interview-start-is-visible-window 0.45s 0.8s 1
        cubic-bezier(0.68, -0.55, 0.265, 1.55) normal forwards;
    }
    #connecting {
      opacity: 1;
      animation: live-interview-start-is-visible-connecting 0.25s 5s 1
        ease-in-out forwards;
    }
    #broadcast-1 {
      animation: live-interview-start-is-visible-broadcast-1 2s 1.25s infinite
        ease-out;
    }
    #broadcast-2 {
      animation: live-interview-start-is-visible-broadcast-2 2s 1.25s infinite
        ease-out;
    }
    #connected {
      opacity: 0;
      animation: live-interview-start-is-visible-connected 0.25s 5s 1
        ease-in-out forwards;
    }
  }
}

@keyframes live-interview-start-is-visible-button {
  0% {
    opacity: 0.6;
  }
  100% {
    opacity: 0.6;
    transform: translateY(-200px);
  }
}

@keyframes live-interview-start-is-visible-window {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes live-interview-start-is-visible-broadcast-1 {
  0%,
  10%,
  90%,
  100% {
    opacity: 0;
  }
  25%,
  80% {
    opacity: 1;
  }
}

@keyframes live-interview-start-is-visible-broadcast-2 {
  0%,
  35%,
  100% {
    opacity: 0;
  }
  50%,
  90% {
    opacity: 1;
  }
}
@keyframes live-interview-start-is-visible-connecting {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes live-interview-start-is-visible-connected {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
