.loading-panel {
  .fade-in(0.35s);
  background-color: @brand-light;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: @zindex-fullscreen;
  div {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    transition: font-size 0.25s ease-in-out;
    > .qualified-logo-black,
    > .adm-logo {
      font-size: 22px;
      color: #000;
      opacity: 0.08;
      @media only screen and (min-width: @screen-sm-min) {
        font-size: 33px;
      }
      @media only screen and (min-width: @screen-lg-min) {
        font-size: 44px;
      }
    }
  }

  noscript {
    display: block;
    margin-top: 10px;
    font-size: 16px;
    color: @brand-danger;
    > span {
      .icon-warning();
      &:before {
        margin-right: 10px;
      }
    }
  }
}
