.card-background(@background-position-y: top, @type: light) {
  background-color: @neutral-bg;
}

.object-card {
  border: none;
  background-color: @white;
  border-radius: @border-radius-card;
  box-shadow: 0 5px 8px fade(@brand-complement-dark, 6%);
  padding: 10px;
  margin: 10px 0;

  @media (min-width: @screen-sm-min) {
    padding: 20px;
    margin: 20px 0;
  }

  @media (min-width: @screen-md-min) {
    display: flex;
    > * {
      flex: 1 1 50%;
    }
  }

  &.moved {
    opacity: 0.5;

    &:hover {
      opacity: 0.8;
    }
  }

  .object-card-actions-spacer {
    flex: 1 1 auto;
  }

  &:not(:hover) .object-card-actions > *:not(:hover):not(:focus) {
    border-color: transparent;
    background-color: transparent;
  }

  &:hover {
    box-shadow: 0 6px 10px fade(@brand-complement-dark, 10%);
    .object-card-actions {
      > *:not(:disabled) {
        color: @brand-complement-medium;
      }
    }
  }

  .object-card-primary-data {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    padding: 0 0 10px 20px;
    @media (min-width: @screen-md-min) {
      padding: 0 20px;
    }
  }

  .object-card-basics {
    flex: 0 1 auto;
    display: flex;
    align-items: center;
    margin: 0 0 4px -20px;

    > * {
      flex: 0 1 auto;
    }

    .object-card-status {
      flex: 0 0 20px;
    }

    .object-card-title {
      color: @text-color;
      font-size: @font-size-med;
      flex-grow: 1;
      @media (min-width: @screen-sm-min) {
        .text-overflow();
      }

      &:hover,
      &:focus {
        color: @brand-primary-darker;
      }
    }
  }

  .object-card-data-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    cursor: default;
    padding: 4px 2px;
    &:hover {
      color: @text-color;
      text-decoration: none;
    }
    > *:first-child {
      font-size: @font-size-med;
      flex: 1 1 auto;
    }
    > *:not(:first-child) {
      flex: 0 1 auto;
      font-size: @font-size-xsmall;
      color: @text-muted;
      margin-top: 4px;
    }

    &.object-card-data-item-empty {
      opacity: 0.5;
    }

    &[href],
    &.object-card-data-item-link {
      color: @text-color;
      border-radius: @border-radius-base;
      cursor: pointer;
      &:hover {
        color: @link-hover-color;
        > * {
          color: @link-hover-color;
        }
        text-decoration: none;
        background-color: @brand-complement-bg;
      }
    }
  }

  .object-card-secondary-data {
    position: relative;
    margin: 0 -10px -10px -10px;
    padding: 10px;
    overflow: hidden;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: @brand-lighter
        linear-gradient(
          to bottom,
          fade(@brand-complement-dark, 4%),
          fade(@brand-complement-dark, 0) 10px
        );
      border-radius: 0 0 @border-radius-card @border-radius-card;
      z-index: 1;
    }

    @media (min-width: @screen-sm-min) {
      margin: 0 -20px -20px -20px;
      padding: 20px;
    }

    @media (min-width: @screen-md-min) {
      margin: -20px -20px -20px 0;
      padding: 20px;

      &:before {
        background: @brand-lighter
          linear-gradient(
            to right,
            fade(@brand-complement-dark, 4%),
            fade(@brand-complement-dark, 0) 10px
          );
        border-radius: 0 @border-radius-card @border-radius-card 0;
      }
    }

    > * {
      position: relative;
      z-index: 2;
    }
  }
}

.card(@hover: true, @spacing: true, @placeholder: true) {
  border: none;
  background-color: @white;
  border-radius: @border-radius-base;
  box-shadow: 0 10px 20px fade(@brand-complement-dark, 13%);
  & when (@spacing = true) {
    padding: 10px;
    margin: 10px;
  }

  @media (min-width: @screen-sm-min) {
    border-radius: @border-radius-large;
    box-shadow: 0 15px 30px fade(@brand-complement-dark, 13%);
    & when (@spacing = true) {
      padding: 20px;
      margin: 20px;
    }
  }

  & when (@placeholder = true) {
    &.placeholder {
      pointer-events: none;
      background-color: mix(@brand-complement-bg, @white, 25%);
      box-shadow: 0 3px 12px fade(@brand-complement-dark, 6%);
      animation: card-placeholder-animation-1 3s ease-in-out infinite;
      @media (min-width: @screen-sm-min) {
        box-shadow: 0 5px 20px fade(@brand-complement-dark, 6%);
      }
    }

    + .placeholder {
      opacity: 0.8;
      animation-delay: 0.5s;

      + .placeholder {
        opacity: 0.6;
        animation-delay: 1s;

        + .placeholder {
          opacity: 0.4;
          animation-delay: 1.5s;
        }
      }
    }
  }

  .card-icon {
    display: block;
    font-size: 3em;
    margin-bottom: 10px;
    color: @brand-complement-light;

    & when (@hover = true) {
      transition: all 0.15s ease-in-out;
    }
  }

  > h2 {
    text-transform: none;
    color: @text-color;

    & when (@hover = true) {
      transition: all 0.15s ease-in-out;
    }
  }

  & when (@hover = true) {
    .card-hover();
  }
}

.card-hover() {
  cursor: pointer;
  transition: all 0.15s ease-in-out;

  &:hover,
  &:focus,
  &:active {
    box-shadow: 0 15px 25px fade(@brand-complement-dark, 13%);
    text-decoration: none;
    transform: translateY(-2px);

    .card-icon {
      color: @brand-complement-dark;
    }

    > h2 {
      color: @link-hover-color;
    }

    @media (min-width: @screen-sm-min) {
      box-shadow: 0 22px 40px fade(@brand-complement-dark, 13%);
    }
  }
}

@keyframes card-placeholder-animation-1 {
  0%,
  100% {
    opacity: 1;
  }
  40%,
  60% {
    opacity: 0.4;
  }
}
