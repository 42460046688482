// NOTE: for dark-mode styling, please add to dark-bg.less > dark-markdown

.markdown-code-link-hover-styling() {
  color: @link-hover-color;
  border-color: mix(@border-color, @link-hover-color, 75%);
}

.markdown {
  position: relative;

  a {
    text-decoration: underline;
    > code {
      color: @link-color;
    }

    &:hover,
    &:focus {
      > code {
        .markdown-code-link-hover-styling();
      }
    }
  }

  table {
    &:extend(.table all);
  }

  blockquote {
    > h1,
    > h2,
    > h3,
    > h4,
    > h5,
    > h6 {
      &:first-child {
        margin-top: 0;
      }
    }
  }

  figure {
    &:before,
    &:after {
      content: '';
      display: block;
    }
    display: inline-block;
    width: auto;
    background: @brand-light;
    border-radius: @border-radius-base;
    padding: 5px;
    margin: 10px 0 20px;

    figcaption {
      font-style: italic;
      color: @text-muted;
      display: block;
      text-align: right;
      padding: 5px 2px 0;
    }
  }

  img.align-right,
  figure.align-right {
    float: right;
    margin: 0 0 2rem 2rem;
  }
  img.align-left,
  figure.align-left {
    float: left;
    margin: 0 2rem 2rem 0;
  }

  img {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  h1,
  h2,
  h3,
  h4 {
    clear: both;
  }

  .language-tabset {
    // when a language code block is followed by a header, give some extra breathing room
    & + h1,
    & + h2,
    & + h3,
    & + h4,
    & + h5 {
      margin-top: 2rem;
    }
  }

  p,
  ul,
  ol {
    // give paragraphs and lists some extra bottom margin. There are many edge cases, such as <code> blocks,
    // that cause them to look squished up to other content.
    margin-bottom: 1.5rem;
  }

  li {
    // give some extra room so that inline-codeblock styling doesn't get squished
    line-height: 22px;
  }

  .alert-external-link {
    padding: 0;
    > a {
      display: block;
      position: relative;
      padding: 15px 35px;
      text-decoration: none;
      .icon-help();
      .icon-new-window-after();
      &:before {
        position: absolute;
        left: 15px;
        top: 18px;
      }
      &:after {
        position: absolute;
        right: 15px;
        top: 18px;
      }

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }

  .block {
    background-color: @brand-light;
    padding: 1rem 1.25rem 1.25rem;
    margin-bottom: 1rem;

    *:first-child {
      margin-top: 0 !important;
    }

    *:last-child {
      margin-bottom: 0 !important;
    }

    &.block--info {
      border: 1px solid @brand-info;
      border-radius: @border-radius-base;
    }

    &.block--warn {
      border: 1px solid @brand-warning;
      border-radius: @border-radius-base;
    }

    &.block--danger {
      border: 1px solid @brand-danger;
      border-radius: @border-radius-base;
    }

    // %doc-block code block, used when you want %doc style formatting but don't want use a defifinition list
    &.block--doc-block {
      h1,
      h2,
      h3,
      h4 {
        font-size: @font-size-base;
      }
    }

    &.block--doc,
    &.block--doc-block {
      background-color: @brand-light;
      // if blocks are back-to-back, visually combine them. This is used for conditional block parts
      + .block--doc,
      + .block--doc-block {
        margin-top: -10px;
      }
    }

    // this is the block that wraps an entire documentation section
    &.block--docs {
      h3 {
        margin-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid @off-white;
        > code {
          border: 0;
          background: transparent;
          padding: 0;
        }
      }

      table {
        th,
        td {
          max-width: 100px;
          overflow-wrap: break-word;
        }
      }

      &.table-doc {
        h3 {
          border-bottom: 0;
          margin-top: 10px !important;
        }

        h3,
        p {
          margin-left: 10px;
        }

        p {
          margin-top: -10px;
        }

        th {
          text-transform: uppercase;
          font-size: @font-size-base - 1;
          border-bottom: 2px solid @brand-light-dark;
        }

        thead {
          background-color: @brand-light-med;
        }

        td,
        th {
          padding: 8px 12px;
        }
      }
    }
  }

  dfn {
    font-style: normal;
  }

  li,
  p,
  dd {
    > dfn {
      font-family: Courier, monospace;

      &.doc-type {
        font-weight: bold;
      }

      &.doc-name {
        border-bottom: 1px dashed;
        padding-bottom: 2px;
        font-style: normal;
      }
    }
  }

  dl {
    dt {
      font-weight: bold;
      padding-bottom: 0.5rem;
      letter-spacing: 0.2px;

      &:not(:first-of-type) {
        padding-top: 2rem;
      }
    }

    dd {
      padding-left: 3rem;
      padding-bottom: 2px;
      > pre {
        padding: 0;
        background-color: transparent;
        border: 0;

        > code {
          background-color: transparent;
        }
      }

      ul {
        padding-left: 2rem;
      }
    }

    dfn {
      &.doc-type {
        font-style: italic;
      }

      &.doc-name {
        font-weight: normal;
      }
    }

    p {
      margin: 0;
    }
  }
}
