.filter-list {
  .expandable,
  &.expandable {
    .expander {
      .expandable-chevron();

      &:before {
        margin-right: 0;
        margin-left: -1.1em;
      }
    }

    &.expanded {
      .expander {
        .expandable-chevron-expanded();
      }
    }
  }

  .parented {
    > label,
    .filter-menu .dropdown-toggle .filter-label {
      font-weight: normal;
    }

    .slide-in(@max-height: 36px, @time: 0.2s);
  }

  &.actions-group-filters .parented {
    .slide-in(@max-height: 20px, @time: 0.2s);
  }

  .filter-checkbox {
    display: flex;
    align-items: center;

    > label {
      font-weight: normal;
      margin-bottom: 0;
      cursor: pointer;
    }

    > label:first-child {
      flex: 1 1 auto;
    }

    > label:last-child {
      flex: 0 1 auto;
      padding: 0;

      &:before {
        position: static;
      }
    }
  }

  .has-status-icon a:before {
    font-size: 1.7rem !important;
  }

  &.bulk-actions {
    .actions-group + & {
      padding-top: 0;
    }
    .slide-in(@max-height: 100px, @time: 0.2s);

    .qualified-checkbox > label {
      margin: 0;
    }
  }

  .list-reload-button {
    display: block;
    position: relative;
    cursor: pointer;
    text-transform: none;
    .icon-reload();

    &:not(:hover):not(:focus) {
      background: transparent;
      border-color: transparent;
    }

    &:focus,
    &:hover {
      background-color: @brand-light;
    }
  }
}
