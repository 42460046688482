.form-control-select {
  position: relative;
  &:extend(.form-control all);
  padding: 0;
  .icon-chevron-down-after();

  &:after {
    // DO NOT CHANGE TO (.input-group-addon all)
    // This breaks Firefox, as it sees :after:first-child as an invalid selector!
    &:extend(.input-group-addon);
    pointer-events: none;
    font-size: 0.7em !important;
    position: absolute;
    top: -1px;
    bottom: -1px;
    right: -1px;
    width: 3em;
    padding: 1.2em 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }

  &[disabled][readonly]:after {
    display: none;
  }

  &:not(.btn-default):after {
    .has-success & {
      color: @state-success-text;
      border-color: @state-success-text;
      background-color: @state-success-bg;
    }

    .has-warning & {
      color: @state-warning-text;
      border-color: @state-warning-text;
      background-color: @state-warning-bg;
    }

    .has-error & {
      color: @state-danger-text;
      border-color: @state-danger-text;
      background-color: @state-danger-bg;
    }
  }

  &:not(.btn-default) {
    &.focused,
    &.focused:after {
      border-color: @input-border-focus;
    }

    &[disabled]:after {
      background-color: @input-bg-disabled;
    }

    &.ng-invalid-required:after {
      border-color: @input-border-required;
    }
  }

  select {
    &:extend(.form-control all);
    margin: 0;
    border: 0;
    height: 32px;
    background: inherit;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding-right: 35px; // make enough room to show the content + custom chevron
    //noinspection CssInvalidPseudoSelector
    &::-ms-expand {
      display: none;
    }
  }

  &.form-control-select-sm {
    height: 30px;

    select {
      height: 28px;
    }

    &:after {
      padding: 0;
      line-height: 28px !important;
    }
  }

  &.no-control {
    display: inline-block;
    width: auto;

    select {
      padding-top: 0;
      padding-bottom: 0;
      border: none;
    }
  }

  &.btn-default {
    text-transform: none;
    box-shadow: none;

    &.focused {
      background-color: darken(@btn-default-bg, 10%);
      border-color: darken(@btn-default-border, 12%);
    }

    select {
      box-shadow: none;
      padding-right: 22px;
      padding-left: @padding-small-horizontal;
      border-radius: 26px;
    }

    &:after {
      border-left: none;
      width: 2em;
      text-align: left;
      background-color: transparent;
      border-color: transparent;
    }
  }
}
