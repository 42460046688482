// runtime: 1.3
.detailed-results.is-visible svg {
  > svg svg {
    overflow: visible;
  }
  #summary-2,
  #summary-3 {
    opacity: 1;
    transform: translateY(-101px);
    animation: detailed-results-is-visible-summary 0.35s 0.55s 1 ease-in-out
      forwards;
  }
  #summary-2 {
    animation-delay: 0.6s;
  }
  #summary-1 #triangle {
    transform: rotate(-90deg);
    transform-origin: 4px 9px;
    animation: detailed-results-is-visible-triangle 0.15s 0.5s 1 ease-in-out
      forwards;
  }
  #details-1-contents {
    opacity: 0;
    transform: translateY(-100%);
    animation: detailed-results-is-visible-details 0.6s 0.7s 1
      cubic-bezier(0.68, -0.55, 0.265, 1.55) forwards;
  }
}

@keyframes detailed-results-is-visible-summary {
  0% {
    opacity: 1;
    transform: translateY(-101px);
  }
  100% {
    opacity: 0.5;
    transform: translateY(0);
  }
}

@keyframes detailed-results-is-visible-triangle {
  0% {
    transform: rotate(-90deg);
  }
  100% {
    transform: rotate(0);
  }
}

@keyframes detailed-results-is-visible-details {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
