body.error-route {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  > * {
    flex: 0 1 auto;
  }
  > section {
    flex: 1 1 auto;
  }
  section.section-basic > div {
    text-align: left;
  }

  .error-message {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
}
