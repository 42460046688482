ul.staggered-list {
  margin: 0;
  padding: 0 10px;
  text-align: center;
  overflow: hidden;

  li {
    list-style: none;
    padding: 0;
    margin: 30px auto 0;
    max-width: @content-max-width;

    + li {
      margin-top: 100px;
    }

    &:last-child {
      margin-bottom: 20px;
    }

    figure {
      position: relative;
      display: block;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      width: 90vw;
      margin: 6vw auto 6vw;

      > svg,
      > img {
        cursor: default;
        width: 100%;
        height: 100%;
        position: relative;
        filter: drop-shadow(0 1.6vw 2vw fade(@brand-complement-dark, 13%));

        text {
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }

        text::selection {
          background: none;
        }
      }

      &:before {
        content: '';
        position: absolute;
        background: no-repeat center center;
        background-size: contain;
        top: -6vw;
        left: -6vw;
        right: -6vw;
        bottom: -6vw;
        pointer-events: none;
      }
    }

    > div {
      display: block;
      position: relative;
      padding-top: 30px;
      z-index: 1;
    }

    h2 img {
      height: 1em;
      max-width: calc(100vw - 20px);
    }

    p {
      font-weight: @font-weight-light;
    }

    p.learn-more-button {
      > * {
        margin: 0 10px 20px 0;
        min-width: 180px;
      }
    }
  }

  &:not(.staggered-list2):not(.has-steps) > li:nth-child(5n) figure:before,
  &.staggered-list2 > li:nth-child(5n + 4) figure:before,
  &.has-steps > li figure:nth-child(5n + 4):before {
    background-image: data-uri('../../landing/images/bg-boxes/1.svg');
  }

  &:not(.staggered-list2):not(.has-steps) > li:nth-child(5n + 1) figure:before,
  &.staggered-list2 > li:nth-child(5n + 2) figure:before,
  &.has-steps > li figure:nth-child(5n + 2):before {
    background-image: data-uri('../../landing/images/bg-boxes/2.svg');
  }

  &:not(.staggered-list2):not(.has-steps) > li:nth-child(5n + 2) figure:before,
  &.staggered-list2 > li:nth-child(5n + 3) figure:before,
  &.has-steps > li figure:nth-child(5n + 3):before {
    background-image: data-uri('../../landing/images/bg-boxes/3.svg');
  }

  &:not(.staggered-list2):not(.has-steps) > li:nth-child(5n + 3) figure:before,
  &.staggered-list2 > li:nth-child(5n) figure:before,
  &.has-steps > li figure:nth-child(5n):before {
    background-image: data-uri('../../landing/images/bg-boxes/4.svg');
  }

  &:not(.staggered-list2):not(.has-steps) > li:nth-child(5n + 4) figure:before,
  &.staggered-list2 > li:nth-child(5n + 1) figure:before,
  &.has-steps > li figure:nth-child(5n + 1):before {
    background-image: data-uri('../../landing/images/bg-boxes/5.svg');
  }

  @media only screen and (min-width: @screen-sm-min) {
    padding: 0 20px;

    li {
      display: flex;
      margin-top: 0;
      text-align: left;

      + li {
        padding-top: 0;
        border-top: none;
        margin-top: 80px;
      }

      figure {
        flex: 0 0 auto;
        width: 40vw;
        height: 20vw;
        min-width: 18em;
        min-height: 9em;
        max-width: 36em;
        max-height: 18em;
      }

      > div {
        flex: 1 1 auto;
        align-self: center;
        padding: 15px;
        margin: 0 40px 0 0;
      }

      h2 {
        margin-top: 0;

        &:before {
          position: absolute;
          left: -5px;
        }
      }
    }

    &:not(.has-steps) li:nth-child(even) {
      flex-direction: row-reverse;

      > div {
        margin: 0 0 0 40px;
      }
    }

    &.staggered-list2 li:first-child {
      margin-top: 40px;
    }
  }
  @media only screen and (min-width: @screen-md-min) {
    li {
      + li {
        margin-top: 100px;
      }

      > div {
        min-height: 200px;
        margin: 0 60px 0 0;
      }

      figure {
        width: 42vw;
        height: 21vw;
        min-width: 20em;
        min-height: 10em;
        max-width: 40em;
        max-height: 20em;
      }
    }

    &:not(.has-steps) li:nth-child(even) > div {
      margin: 0 0 0 60px;
    }
  }

  @media only screen and (min-width: @screen-lg-min) {
    padding: 0 40px;
    li {
      h2 {
        font-size: 24px;
      }

      > div {
        min-height: 300px;
        margin: 0 80px 0 0;
      }
    }

    &:not(.has-steps) li:nth-child(even) > div {
      margin: 0 0 0 80px;
    }
  }

  @media only screen and (min-width: @screen-xl-min) {
    li {
      h2 {
        font-size: 26px;
      }

      figure {
        width: 46vw;
        height: 23vw;
        max-width: 44em;
        max-height: 22em;

        > svg,
        > img {
          filter: drop-shadow(0 22px 28px fade(@brand-complement-dark, 13%));
        }
      }
    }
  }

  &.has-steps {
    text-align: left;
    li {
      position: relative; // relative to ensure the hidden radios stay visible for Chrome
      counter-reset: steps-counter;
      margin-top: 10px;

      > div {
        padding-top: 0;
      }

      @media only screen and (min-width: @screen-sm-min) {
        > div {
          margin-left: 30px;
        }
      }

      label {
        counter-increment: steps-counter;
        margin-bottom: 0;

        + label {
          margin-top: 20px;
          @media only screen and (min-width: @screen-md-min) {
            margin-top: 60px;
          }
        }
      }

      h2 {
        font-size: 1em;
        text-align: left;

        &:before {
          display: inline-block;
          content: counter(steps-counter);
          font-weight: normal;
          width: 2em;
          height: 2em;
          line-height: 1.8em;
          text-align: center;
          border-radius: 50%;
          border: 2px solid @brand-primary-dark;
          color: @brand-primary-dark;
          margin-right: 1em;
          transition: all 0.15s ease-in-out;

          @media (min-width: @screen-sm-min) {
            position: absolute;
            left: -1.6em;
            transform: translateY(-10%);
          }
        }
      }

      input.steps-radio {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        overflow: hidden;
        width: 0 !important;
        margin: 0 !important;
        padding: 0 !important;
        border: 0 !important;
      }

      figure {
        display: none;
      }

      input#steps-radio-1:checked ~ div > label[for='steps-radio-1'],
      input#steps-radio-2:checked ~ div > label[for='steps-radio-2'],
      input#steps-radio-3:checked ~ div > label[for='steps-radio-3'] {
        h2:before {
          color: @white;
          background-color: @brand-primary-dark;
        }
      }

      input#steps-radio-1:checked ~ figure#steps-figure-1,
      input#steps-radio-2:checked ~ figure#steps-figure-2,
      input#steps-radio-3:checked ~ figure#steps-figure-3 {
        display: block;
      }
    }
  }
}
