.ng-animate-all(@time: 0.3s, @fn: ease-in-out) {
  &.ng-hide-add,
  &.ng-hide-remove {
    transition: all @time @fn;
  }
}

.ngif-animate-all(@time: 0.3s, @fn: ease-in-out) {
  &.ng-enter,
  &.ng-leave {
    transition: all @time @fn;
  }
}

.drop-in(@distance: 100%, @time: 0.3s, @fn: ease-in-out) {
  .ng-animate-all(@time, @fn);

  &.ng-hide-add,
  &.ng-hide-remove.ng-hide-remove-active {
    opacity: 1;
    transform: translateY(0);
  }
  &.ng-hide-remove,
  &.ng-hide-add.ng-hide-add-active {
    opacity: 0;
    transform: translateY(-@distance);
  }
}

.drop-in-no-fade(@distance: 100%, @time: 0.3s, @fn: ease-in-out) {
  .ng-animate-all(@time, @fn);

  &.ng-hide-add,
  &.ng-hide-remove.ng-hide-remove-active {
    transform: translateY(0);
  }
  &.ng-hide-remove,
  &.ng-hide-add.ng-hide-add-active {
    transform: translateY(-@distance);
  }
}

.slide-in(@max-height: 100vh, @time: 0.3s, @fn: ease-in-out, @display: block) {
  .ng-animate-all(@time, @fn);
  &.ng-hide-add,
  &.ng-hide-remove.ng-hide-remove-active {
    display: @display;
    overflow: hidden;
    max-height: @max-height;
    opacity: 1;
  }
  &.ng-hide-remove:not(.ng-hide-remove-active),
  &.ng-hide-add.ng-hide-add-active {
    display: @display;
    overflow: hidden;
    max-height: 0;
    margin-top: 0;
    margin-bottom: 0;
    opacity: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.slide-in-no-fade(@max-height: 100vh, @time: 0.3s, @fn: ease-in-out, @display: block) {
  .ng-animate-all(@time, @fn);
  &.ng-hide-add,
  &.ng-hide-remove.ng-hide-remove-active {
    display: @display;
    overflow: hidden;
    max-height: @max-height;
  }
  &.ng-hide-remove:not(.ng-hide-remove-active),
  &.ng-hide-add.ng-hide-add-active {
    display: @display;
    overflow: hidden;
    max-height: 0;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}

// NOTE: this mixin requires a single top level DIV within each cell to animate
// it's not possible to animate TR or TD element heights
.ngshow-row-slide-in(@max-height: 100vh, @time: 0.3s, @fn: ease-in-out) {
  > td {
    padding: 0;
    > div {
      padding: @table-cell-padding;
    }
  }
  &.ng-hide-add,
  &.ng-hide-remove {
    &,
    & > td > div {
      transition: all @time @fn;
    }
  }
  &.ng-hide-add,
  &.ng-hide-remove.ng-hide-remove-active {
    opacity: 1;
    > td > div {
      display: block;
      overflow: hidden;
      max-height: @max-height;
    }
  }
  &.ng-hide-remove,
  &.ng-hide-add.ng-hide-add-active {
    opacity: 0;
    > td > div {
      display: block;
      overflow: hidden;
      max-height: 0;
    }
  }
}

// NOTE: this mixin requires a single top level DIV within each cell to animate
// it's not possible to animate TR or TD element heights
.ngrepeat-row-slide-in(@max-height: 100vh, @time: 0.3s, @fn: ease-in-out, @hide-opacity: 0) {
  &.ng-leave,
  &.ng-enter {
    &,
    & > td,
    & > td > div {
      transition: all @time @fn;
    }
  }
  &.ng-leave,
  &.ng-enter.ng-enter-active {
    opacity: 1;
    > td > div {
      display: block;
      overflow: hidden;
      max-height: @max-height;
    }
  }
  &.ng-enter,
  &.ng-leave.ng-leave-active {
    opacity: @hide-opacity;
    > td {
      padding-top: 0;
      padding-bottom: 0;
    }
    > td > div {
      display: block;
      overflow: hidden;
      max-height: 0;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

.fade-in(@time: 0.2s, @fn: ease-in-out) {
  .ng-animate-all(@time, @fn);
  &.ng-hide-add,
  &.ng-hide-remove.ng-hide-remove-active {
    opacity: 1;
  }
  &.ng-hide-remove,
  &.ng-hide-add.ng-hide-add-active {
    opacity: 0;
  }
}

.ng-scale-in(@time: 0.2s, @fn: ease-in-out) {
  .ng-animate-all(@time, @fn);
  &.ng-hide-add,
  &.ng-hide-remove.ng-hide-remove-active {
    opacity: 1;
    transform: scale(1);
  }
  &.ng-hide-remove,
  &.ng-hide-add.ng-hide-add-active {
    opacity: 0;
    transform: scale(0.001);
  }
}

.ngif-drop-in(@distance: 100%, @time: 0.3s, @fn: ease-in-out, @fade-opacity: 0) {
  .ngif-animate-all(@time, @fn);

  &.ng-leave,
  &.ng-enter.ng-enter-active {
    opacity: 1;
    transform: translateY(0);
  }
  &.ng-enter:not(.ng-enter-active),
  &.ng-leave.ng-leave-active {
    opacity: @fade-opacity;
    transform: translateY(-@distance);
  }
}

.ngif-slide-in(@max-height: 100vh, @time: 0.3s, @fn: ease-in-out, @display: block) {
  .ngif-animate-all(@time, @fn);
  &.ng-leave,
  &.ng-enter.ng-enter-active {
    display: @display;
    overflow: hidden;
    max-height: @max-height;
    opacity: 1;
  }
  &.ng-enter:not(.ng-enter-active),
  &.ng-leave.ng-leave-active {
    display: @display;
    overflow: hidden;
    max-height: 0;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0;
  }
}

.ngif-slide-in-no-fade(@max-height: 100vh, @time: 0.3s, @fn: ease-in-out, @display: block) {
  .ngif-animate-all(@time, @fn);
  &.ng-leave,
  &.ng-enter.ng-enter-active {
    display: @display;
    overflow: hidden;
    max-height: @max-height;
  }
  &.ng-enter:not(.ng-enter-active),
  &.ng-leave.ng-leave-active {
    display: @display;
    overflow: hidden;
    max-height: 0;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.ngif-slide-horizontal(@max-width: 100vw, @time: 0.2s, @fn: ease-in-out) {
  .ngif-animate-all(@time, @fn);
  &.ng-leave,
  &.ng-enter.ng-enter-active {
    max-width: @max-width;
    overflow: hidden;
  }
  &.ng-enter,
  &.ng-leave.ng-leave-active {
    max-width: 0;
    overflow: hidden;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
    border-left-width: 0;
    border-right-width: 0;
  }
}

.ngif-fade-in(@time: 0.2s, @fn: ease-in-out) {
  .ngif-animate-all(@time, @fn);
  &.ng-leave,
  &.ng-enter.ng-enter-active {
    opacity: 1;
  }
  &.ng-enter,
  &.ng-leave.ng-leave-active {
    opacity: 0;
  }
}

.ng-repeat-fade-in(@time: 0.2s, @fn: ease-in-out) {
  &.ng-enter,
  &.ng-leave,
  &.ng-move {
    transition: all @time @fn;
  }
  &.ng-leave,
  &.ng-move.ng-move-active,
  &.ng-enter.ng-enter-active {
    opacity: 1;
  }
  &.ng-enter,
  &.ng-move,
  &.ng-leave.ng-leave-active {
    opacity: 0;
  }
}

.ng-enter-stagger(@time: 0.05s) {
  &.ng-enter-stagger {
    transition-delay: @time;
    transition-duration: 0s;
  }
}

.ng-repeat-fade-in-enter(@time: 0.2s, @fn: ease-in-out) {
  &.ng-enter {
    transition: all @time @fn;
    opacity: 0;
  }
  &.ng-enter.ng-enter-active {
    opacity: 1;
  }
}

.ng-repeat-stretch-vertical(@max-height: 100vh, @time: 0.2s, @fn: ease-in-out) {
  &.ng-enter,
  &.ng-leave,
  &.ng-move {
    transition: all @time @fn;
    overflow: hidden;
  }
  &.ng-leave,
  &.ng-move.ng-move-active,
  &.ng-enter.ng-enter-active {
    opacity: 1;
    max-height: @max-height;
  }
  &.ng-enter,
  &.ng-move,
  &.ng-leave.ng-leave-active {
    opacity: 0;
    max-height: 0;
  }
}

.ng-repeat-stretch-vertical-no-move(@max-height: 100vh, @time: 0.2s, @fn: ease-in-out) {
  &.ng-enter,
  &.ng-leave {
    transition: all @time @fn;
    overflow: hidden;
  }
  &.ng-leave,
  &.ng-enter.ng-enter-active {
    opacity: 1;
    max-height: @max-height;
  }
  &.ng-enter,
  &.ng-leave.ng-leave-active {
    opacity: 0;
    max-height: 0;
  }
}

.ng-repeat-stretch-horizontal(@max-width: 100vw, @time: 0.2s, @fn: ease-in-out) {
  &.ng-enter,
  &.ng-leave,
  &.ng-move {
    transition: all @time @fn;
    overflow: hidden;
  }
  &.ng-leave,
  &.ng-move.ng-move-active,
  &.ng-enter.ng-enter-active {
    opacity: 1;
    max-width: @max-width;
  }
  &.ng-enter,
  &.ng-move,
  &.ng-leave.ng-leave-active {
    opacity: 0;
    max-width: 0;
  }
}

.ng-repeat-stretch-all(@max-size: 100vw, @time: 0.2s, @fn: ease-in-out) {
  &.ng-enter,
  &.ng-leave,
  &.ng-move {
    transition: all @time @fn;
    overflow: hidden;
  }
  &.ng-leave,
  &.ng-move.ng-move-active,
  &.ng-enter.ng-enter-active {
    opacity: 1;
    max-width: @max-size;
    max-height: @max-size;
  }
  &.ng-enter,
  &.ng-move,
  &.ng-leave.ng-leave-active {
    opacity: 0;
    max-width: 0;
    max-height: 0;
  }
}

.ng-repeat-changes() {
  &.ng-enter:not(.no-highlight) {
    animation: 1.2s ng-repeat-changes-enter;
    // disable clicking during events
    pointer-events: none;
  }
  &.ng-leave:not(.no-highlight) {
    animation: 0.3s ng-repeat-changes-leave;
    // disable clicking during events
    pointer-events: none;
  }

  @keyframes ng-repeat-changes-enter {
    0% {
      opacity: 0;
      background: fade(@brand-highlight, 80%);
    }
    15% {
      opacity: 1;
      background: fade(@brand-highlight, 80%);
    }
    30% {
      background: fade(@brand-highlight, 40%);
    }
    45% {
      background: @brand-highlight;
    }
    60% {
      background: @brand-highlight;
    }
    100% {
      background: fade(@brand-highlight, 0);
    }
  }

  @keyframes ng-repeat-changes-leave {
    0% {
      background: fade(@brand-highlight, 0);
    }
    30% {
      background: fade(@brand-highlight, 50%);
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}
