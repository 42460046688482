embedded-type-form {
  display: block;
  width: 100%;

  .embedded-type-form-container {
    width: 100%;
    min-height: 400px;
    position: relative;
    transition: all 0.2s ease-in-out;

    > * {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: auto;
      width: auto;
      transition: all 0.2s ease-in-out;
    }
  }
}
