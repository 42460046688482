body.landing-page-signup {
  section.sales-lead {
    p.note {
      color: @text-muted;
      margin-bottom: 24px;
    }
  }

  .submission-error {
    max-width: 45em;
    background: @white;
    border-radius: @border-radius-large;
    box-shadow: 0 15px 30px fade(@brand-complement-dark, 13%);
    padding: 20px 15px;
    margin: 15px auto 40px;
  }

  .sales-lead-wrapper {
    margin: 15px auto 40px;
    display: flex;
    flex-wrap: wrap;
    @media (min-width: calc(60em + 10px)) {
      max-width: 60em;
    }

    > * {
      flex: 1 1 100%;
    }

    @media (min-width: @screen-sm-min) {
      flex-wrap: nowrap;
    }
  }

  .sales-lead-form {
    display: block;
    position: relative;
    max-width: 45em;
    background: @white
      linear-gradient(
        190deg,
        fade(@brand-complement-bg, 0%) 0 41%,
        fade(@brand-complement-bg, 40%) 41%,
        fade(@brand-complement-bg, 0%) 100%
      );
    border-radius: @border-radius-large;
    box-shadow: 0 15px 30px fade(@brand-complement-dark, 18%);
    padding: 20px 15px 60px;
    margin: 0 auto 40px;

    @media (min-width: @screen-sm-min) {
      flex-basis: 60%;
      align-self: flex-start;
    }

    label {
      > em {
        font-weight: @font-weight-light;
        font-style: normal;
      }
    }

    .saving-indicator {
      .fade-in();
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 8rem;

      &.ng-hide-remove {
        // don't show for a bit, so we don't show on fast updates
        transition-delay: 0.35s;
      }

      color: @text-muted;
    }

    form-group {
      &:not(.layout-two-columns) {
        margin: 0 10px;
      }
      text-align: left;

      ~ form-group {
        margin-top: 10px;
      }
    }
    @media (max-width: @screen-xs-max) {
      form-group.layout-two-columns > * + * {
        // evens out spacing on mobile
        margin-top: 20px;
      }
    }

    .email-error-warning,
    .email-personal-warning,
    .education-warning {
      .slide-in(@max-height: 300px);
      font-weight: @font-weight-light;
      max-width: 32em;
      margin: 10px auto 0;
      padding-bottom: 10px;
      text-align: left;

      .alert {
        margin: 0;
      }

      a {
        font-weight: @font-weight-heavy;
        text-decoration: underline;
      }
      .alert-danger a {
        color: @brand-danger;
      }
      .alert-warning {
        p,
        ul {
          color: @text-color;
        }
        a {
          color: @state-warning-text;
        }
      }

      ul {
        padding: 10px 0 0 1.5em;

        li {
          list-style: disc;
        }
      }
    }

    hr {
      margin: 3rem 0 0;
      border-top: none;
    }

    p {
      font-weight: @font-weight-light;

      strong {
        font-weight: normal;
      }
    }

    .help-block-error {
      color: @brand-danger;
    }

    h1 {
      .icon-status-submit();

      &:before {
        display: inline-block;
        color: @brand-success;
        margin-right: 2px;
        transform: translateY(2px);
      }
    }

    .buttons {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      transform: translateY(50%);

      .btn-cta {
        box-shadow: 0 10px 20px fade(@brand-complement-dark, 13%);
        padding: 16px 40px;

        .icon-status-submit-after();

        &:after {
          display: inline-block;
          transform: scale(1.4) translateY(1px);
          margin-left: 10px;
          z-index: 1;
          opacity: 0.7;
        }
      }
    }
  }

  .testimonials {
    margin: 0 auto;
    text-align: left;
    max-width: 35em;
    @media (min-width: @screen-sm-min) {
      margin-left: 40px;
      flex-basis: 40%;
    }
  }

  section#signup-success {
    > div {
      position: relative;
      overflow: visible;
      max-width: 70em;
      background: @white;
      border-radius: @border-radius-large;
      box-shadow: 0 15px 30px fade(@brand-complement-dark, 13%);
      padding: 0;
      transition: all 0.2s ease-in-out;
    }
  }
}

@keyframes landing-signup-success-slam {
  0% {
    opacity: 0;
    transform: scale(8) translateY(-6px);
  }
  60% {
    opacity: 1;
    transform: scale(1.4);
  }
  70% {
    transform: rotate(-20deg) scale(1.4);
  }
  80% {
    transform: rotate(16deg) scale(1.4);
  }
  88% {
    transform: rotate(-10deg) scale(1.4);
  }
  96% {
    transform: rotate(4deg) scale(1.4);
  }
  100% {
    transform: scale(1.4);
  }
}
