// runtime: 3.2
.live-interview-real-time {
  &.is-visible {
    #user-1-cursor,
    #user-2-cursor,
    #user-3-cursor {
      animation: live-interview-real-time-is-visible-cursor 1s 0s 8 forwards;
    }
    #user-2-cursor {
      animation-delay: 0.25s;
    }
    #user-3-cursor {
      animation-delay: 0.5s;
    }

    #user-2-profile,
    #user-3-profile {
      opacity: 0;
      transform: translateY(10px);
      animation: live-interview-real-time-is-visible-user-profile 0.2s 1s 1
        ease-in-out forwards;
    }

    #user-3-profile {
      animation-delay: 3s;
    }

    #user-2,
    #user-3 {
      opacity: 0;
      animation: live-interview-real-time-is-visible-user-cursor 0.2s 1s 1
        ease-in-out forwards;
    }

    #user-3 {
      animation-delay: 3s;
    }
  }
}

@keyframes live-interview-real-time-is-visible-cursor {
  0%,
  33%,
  100% {
    opacity: 1;
  }
  50%,
  66% {
    opacity: 0;
  }
}

@keyframes live-interview-real-time-is-visible-user-profile {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes live-interview-real-time-is-visible-user-cursor {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
