body.landing-page-features {
  .sub-section {
    margin-top: 24px;
    @media (min-width: @screen-sm-min) {
      padding: 30px;
    }

    @media (min-width: @screen-md-min) {
      padding: 30px 40px 40px;
    }
    @media (min-width: @screen-xl-min) {
      margin-top: 34px;
      padding: 30px 60px 50px;
    }

    figure.large-screenshot {
      > img {
        max-width: 100%;
        max-height: 80vh;
        object-fit: contain;
        margin: 0 auto;
        border: 1px solid @border-color;
        border-radius: @border-radius-large;
        filter: drop-shadow(0 1.6vw 2vw fade(@brand-complement-dark, 13%));
        @media (min-width: @screen-xl-min) {
          filter: drop-shadow(0 22px 28px fade(@brand-complement-dark, 13%));
        }
      }
    }
  }

  .sub-section.languages {
    > ul {
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;

      > li {
        position: relative;
        list-style: none;
        flex: 0 1 100%;
        padding: 5px 10px;
        display: flex;
        align-items: center;
        flex-basis: 50%;
        overflow: hidden;

        &:before {
          font-size: 1.7em;
          color: @brand-primary-dark;
          margin-right: 0.8em;
        }
      }
    }
    @media (min-width: 360px) {
      > ul {
        justify-content: space-around;
        > li {
          flex-basis: 40%;
        }
      }
    }

    @media (min-width: @screen-sm-min) {
      > ul {
        > li {
          flex-basis: 25.1%;
          padding: 10px 20px;

          &.contact-note {
            text-align: left;
          }
        }
      }
    }

    @media (min-width: @screen-md-min) {
      > ul {
        max-width: 940px;
        margin: 0 auto;
        > li {
          flex-basis: 20.1%;
        }
      }
    }
  }

  section#code-review-tools {
    h2 {
      margin-top: 30px;
      @media (min-width: @screen-lg-min) {
        margin-top: 40px;
      }
    }
  }

  section#integrations {
    .logos-list {
      @media (min-width: @screen-md-min) {
        margin-bottom: 30px;
      }
      @media (min-width: @screen-lg-min) {
        margin-top: 40px;
        margin-bottom: 40px;
      }
    }
  }

  section#developer-experience {
    margin-top: -3vw;
    @media (max-width: @screen-sm-max) {
      margin-top: 0;
    }
  }
}
