.btn-base(@vertical-padding) {
  display: inline-block;
  text-align: center;
  .btn-base-border-radius(@vertical-padding);
  cursor: pointer;
  white-space: nowrap;
  .user-select(none);
  margin-bottom: 0;
  vertical-align: middle;
  touch-action: manipulation;
  border: none;
}
.btn-base-border-radius(@vertical-padding) {
  border-radius: calc(1em + @vertical-padding + @vertical-padding);
}

.btn-cta {
  position: relative;
  overflow: hidden;
  .btn-base(15px);
  color: @landing-cta-fg;
  background-color: @landing-cta-bg;
  font-size: 16px;
  padding: 15px;
  width: 100%;
  max-width: 20em;

  transition: all 0.25s ease-in-out;

  > span {
    position: relative;
  }

  &:hover,
  &:active,
  &:focus {
    transition: all 0.15s ease-in-out;
    transition-delay: 0s;
    transform: scale(1.05);
    transform-origin: 50% 50%;
    text-decoration: none;
    color: @landing-cta-fg;
  }

  &:disabled {
    color: fade(@white, 25%);
  }

  @media (min-width: @screen-sm-min) {
    width: auto;
    min-width: 256px;
  }

  &.btn-cta-sm {
    padding: 12px;
    .btn-base-border-radius(12px);
    @media (min-width: @screen-sm-min) {
      min-width: 200px;
      max-width: none;
      width: auto;
    }
  }

  &.btn-cta-dark {
    background-image: none;
    color: @white;
    background-color: @brand-complement-dark;

    &:hover,
    &:active,
    &:focus {
      color: @white;
    }
  }
}

.btn-basic {
  .btn-base(0.6em);
  position: relative;
  display: inline-block;
  color: @white;
  background-color: transparent;
  padding: 0.6em 2em;
  line-height: 1;
  border: 1px solid @white;
  transition: all 0.15s ease-in-out;
  z-index: 1;

  &:hover,
  &:active,
  &:focus {
    border-color: transparent;
    color: @brand-primary-dark;
    background-color: @white;
    text-decoration: none;
  }

  &.light-bg {
    color: @landing-cta-fg;
    border-color: @landing-cta-fg;

    &:hover,
    &:active,
    &:focus {
      color: @landing-cta-fg;
      background: @landing-cta-bg;
      border-color: transparent;
    }
  }

  &.plain {
    border-color: transparent !important;
  }
}

.btn-video {
  .btn-base(12px);
  position: relative;
  padding: 12px;
  font-size: 16px;
  border: 1px solid transparent;
  overflow: hidden;
  width: 100%;
  max-width: 20em;
  transition: all 0.25s ease-in-out;

  @media (min-width: @screen-sm-min) {
    width: auto;
    max-width: none;
  }

  .icon-transport-play();

  &:before {
    position: relative;
    display: inline-block;
    margin-right: 6px;
    color: @brand-primary-dark;
    background: transparent;
    border-radius: 0.4em / 0.3em;
    width: 1.6em;
    line-height: 1.4em;
    padding-left: 2px;
    transform: translateY(1px);
  }

  &:hover,
  &:active {
    color: @brand-primary-dark;
    border-color: @btn-primary-bg;
    text-decoration: none;

    &:before {
      background-color: @white;
    }
  }
}
